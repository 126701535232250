import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditRow({ open, onClose, rowInfo, onDeviceRowUpdated, onNozzleRowUpdated, tabInd }) {
    const row = rowInfo;
    const tab = tabInd;
    const [tabIndex, setTabIndex] = useState();
    const [rowData, setRowData] = useState(row);
    const [snackbarOpen, setSnackbarOpen] = useState(false);


    useEffect(() => {
        setRowData(row);
        setTabIndex(tab);
    }, [row, tab]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRowData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFormSubmit = async () => {
        try {
            if (tabIndex === 0) {
                const endpoint = 'api/DeviceInfo/updateDevice'; 
                await axios.put(endpoint, rowData);
                onDeviceRowUpdated();
                setSnackbarOpen(true);
            } else {
                const endpoint = `api/NozzleProgramming/updateNozzle`;
                await axios.put(endpoint, rowData);
                onNozzleRowUpdated();
                setSnackbarOpen(true);
            }
            onClose();
        } catch (error) {
            console.error("Error updating selection: ", error);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Button variant="outlined" onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</Button>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Edit Device
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={6}>
                            <TextField label="Name" name="deviceName" fullWidth value={rowData.deviceName || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Type" name="deviceType" fullWidth value={rowData.deviceType || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="MAC Address" name="macAddress" fullWidth value={rowData.macAddress || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Internal IP Address" name="internalIpAddress" fullWidth value={rowData.internalIpAddress || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="HTTP Port (TCP)" name="portTcp" fullWidth value={rowData.portTcp || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="HTTP Port (UDP)" name="portUdp" fullWidth value={rowData.portUdp || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Port RTSP" name="internalRtsp" fullWidth value={rowData.internalRtsp || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External HTTP" name="externalHttp" fullWidth value={rowData.externalHttp || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External RTSP" name="externalRtsp" fullWidth value={rowData.externalRtsp || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Outbound Port" name="outboundPort" fullWidth value={rowData.outboundPort || ''} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Row Updated!
                </MuiAlert>
            </Snackbar>
        </>
    );
}



import React, { useEffect, useState } from "react";
import { Box, Button} from "@mui/material";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import TimeSheetDetails from "./TimeSheetDetails";

import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';

function createData(timesheetid, userid, year, username, firstname, lastname, email, role, department, date, weekstart, weekend, standardhours, overtime, pto, holidayhours, vacationhours, sickhours, otherhours,  totalhours, status) {
  return {
    timesheetid,
    userid, 
    year,
    username, 
    firstname, 
    lastname, 
    email, 
    role, 
    department, 
    date,
    weekstart,
    weekend,
    standardhours,
    overtime,
    pto,
    holidayhours,
    vacationhours,
    sickhours,
    otherhours, 
    totalhours,
    status
  }
}

function CollapsibleRow(props) {
  return(
    <>
      <Box sx={{ margin: 1, maxWidth: '100vw' }}>
        <TimeSheetDetails
          employeeId={props.row.userid}
          weekStart={props.row.weekstart}
          weekEnd={props.row.weekend}
          listener={props.listener}
          updateSheet={props.updateSheet}
          denyChanges={props.denyChanges}
        />
      </Box>
    </>
    
  )
}

export default function TimeTable(sheet) {

  const [currentSheetID, setCurrentSheetID] = useState(0);
  const [currentWeekNumber, setCurrentWeekNumber] = useState(sheet.currentWeekNumber);
  const [employeeId, setEmployeeId] = useState(sheet.employeeId);
  const [year, setYear] = useState(sheet.year);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentWeekNumber(sheet.currentWeekNumber);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.currentWeekNumber])

  useEffect(() => {
    setYear(sheet.year);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.year])

  useEffect(() => {
    setEmployeeId(sheet.employeeId);
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.employeeId])

  useEffect(() => {
    handleSheetSelect(sheet.currentWeekNumber, sheet.employeeId, sheet.year);
    // eslint-disable-next-line
  }, [sheet.listener])

  const handleSheetSelect = (newWeekNumber, newEmployeeId, year) => {
    setCurrentWeekNumber(newWeekNumber);
    setEmployeeId(newEmployeeId);
    setYear(year);
    sheet.setSheet(newWeekNumber, newEmployeeId, year);
    fetchWeeklyData();
  }

  const fetchWeeklyData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/timeSheetWeekly';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchWeeklyData();
    // eslint-disable-next-line
  }, [sheet.submitted, sheet.approved, sheet.listener]);

  const loadRows = (timeSheets) => {

    let newRows = [];
    if (timeSheets.length > 0) {
      timeSheets.forEach(ts => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        if (sheet.currentUserId === ts.empUserId || sheet.subordinateIdsList.includes(ts.empUserId)) {
          newRows.push(createData(
            ts.weekNumber,
            ts.empUserId,
            ts.tsYear,
            ts.userName,
            ts.firstName,
            ts.lastName,
            ts.email,
            ts.role,
            ts.department,
            ts.weekStartDate ? `${offsetDate(new Date(ts.weekStartDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })} - ${offsetDate(new Date(ts.weekEndDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}` : "-",
            ts.weekStartDate ? offsetDate(new Date(ts.weekStartDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            ts.weekEndDate ? offsetDate(new Date(ts.weekEndDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            ts.regularHours,
            ts.overtimeHours,
            ts.ptoHours,
            ts.holidayHours,
            ts.vacationHours,
            ts.sickHours,
            ts.otherHours,
            ts.totalHoursWorked,
            ts.tsStatus
          ))
        }
      })
    }
    setRows(newRows);
  }

  const columns =
    [
      {
        accessorKey: 'timesheetid',
        header: 'Time Sheet',
        size: 50,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <>
            {row.original.timesheetid === currentWeekNumber && row.original.userid === employeeId 
            ? (
              <Button
                variant="contained"
                disabled
                sx={{ paddingX: "3px", paddingY: "0px" }}
              >
                Displaying
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleSheetSelect(row.original.timesheetid, row.original.userid, row.original.year)}
                sx={{ padding: "0px" }}
              >
                Select
              </Button>
            )}
          </>
        ),
      },
      {
        accessorKey: 'firstname',
        header: 'First Name',
        size: 50,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'lastname',
        header: 'Last Name',
        enableColumnOrdering: false,
        size: 50,
      },
      {
        accessorKey: 'date',
        header: 'Week Of',
        size: 100,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 60,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId] === "Not Submitted" && a.original[columnId] === "Approved")
            return -1;
          else if (b.original[columnId] === "Approved" && a.original[columnId] === "Not Submitted")
            return 1;
          else if (b.original[columnId] === "Pending Approval" && a.original[columnId] === "Not Submitted")
            return -1;
          else if (b.original[columnId] === "Not Submitted" && a.original[columnId] === "Pending Approval")
            return 1;
          else if (b.original[columnId] === "Pending Approval" && a.original[columnId] === "Approved")
            return -1;
          else if (b.original[columnId] === "Approved" && a.original[columnId] === "Pending Approval")
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'standardhours',
        header: 'Standard Hours',
        size: 40,
      },
      {
        accessorKey: 'overtime',
        header: 'Overtime',
        size: 40,
      },
      {
        accessorKey: 'pto',
        header: 'PTO',
        size: 40,
      },
      {
        accessorKey: 'holidayhours',
        header: 'Holiday',
        size: 40,
      },
      {
        accessorKey: 'vacationhours',
        header: 'Vacation',
        size: 40,
      },
      {
        accessorKey: 'sickhours',
        header: 'Sick',
        size: 40,
      },
      {
        accessorKey: 'otherhours',
        header: 'Other Hours',
        size: 40,
      },
      {
        accessorKey: 'totalhours',
        header: 'Total Hours',
        size: 40,
      },
      
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableExpanding: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    state: {
      showSkeletons: loading,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        setCurrentSheetID(row.id);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },

    }),
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'date',
          desc: true,
        },
        {
          id: 'status',
          desc: true,
        }
      ],
      columnPinning: { left: ['mrt-row-expand', 'timesheetid', 'firstname', 'lastname'] },
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.timesheetid} row={row.original} listener={sheet.listener} updateSheet={sheet.updateSheet} denyChanges={sheet.denyChanges}/>
  });

  return <MaterialReactTable table={table} />;
}
  
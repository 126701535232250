/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Grid, Stack, Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import RefreshIcon from '@mui/icons-material/Refresh';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleGlobalFilterButton } from 'material-react-table';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import ProgressCell from "../ProgressCell";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

function loadData(row) {
  let daysOpen = "-";

  if (row.projectStart) {
    let today = new Date();
    let opened = new Date(row.projectStart);
    daysOpen = Math.abs(Math.round((today.getTime() - opened.getTime()) / (1000 * 60 * 60 * 24)));
  }

  let spPercentage = 20;
  let spProgress = Math.floor(
    (row.siteKickoffCallCompleted ? spPercentage : 0) +
    (row.padVerifiedDate ? spPercentage : 0) +
    (row.powerInstalledDate ? spPercentage : 0) +
    (row.internetVerifiedDate ? spPercentage : 0) +
    (row.depositReceivedDate ? spPercentage : 0)
  );

  let scPercentage = 16.66;
  let scProgress = Math.floor(
    (row.productsShipped ? scPercentage : 0) +
    (row.craneArrivalConfirmation ? scPercentage : 0) +
    (row.dateOutConfirmation ? scPercentage : 0) +
    (row.confirmationNumber ? scPercentage : 0) +
    (row.customerHandoffCallCompleted ? scPercentage : 0) +
    (row.readyToBillCompleted ? scPercentage : 0)
  );

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  return {
    id: "" + row.projectId,
    masterCustomer: row.cmName ?? "",
    site: row.csName ?? "",
    state: row.csState ?? "",
    units: row.units ?? "",
    pm: row.projectManager ?? "",
    daysOpen: daysOpen,
    rmrValue: formatter.format(row.rmrValue) ?? "0",
    shipDate: row.shippedDate ? offsetDate(new Date(row.shippedDate)).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }) : "-",
    shipDateConfirm: row.shipDateConfirmed,
    installDate: row.installDate ? offsetDate(new Date(row.installDate)).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }) : "-",
    installValue: formatter.format(row.installPrice) ?? "0",
    sitePrepCompletion: <ProgressCell params={spProgress} />,
    coordinationCompletion: <ProgressCell params={scProgress} />,
    totalBuildStatusPercentage: <ProgressCell params={row.totalBuildStatusPercentage} />,
    installComplete: row.installComplete ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
    installedDate: row.installCompleteDate ? offsetDate(new Date(row.installCompleteDate)).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) : "-",
    activationComplete: row.activationComplete ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
    activatedDate: row.activationCompleteDate ? offsetDate(new Date(row.activationCompleteDate)).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) : "-",
    readyToBill: row.readyToBill ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
    readyToBillCompleted: row.readyToBillCompleted ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
    centralStation: row.centralStation ?? "",
    unitCount: (Number(row.bsuCount) + Number(row.onWatchCount) + Number(row.cfCount) + Number(row.edCount)) ?? "0",
    riserCount: "" + row.totalRiserCount ?? "0",
    thermals: "" + row.totalThermalCount ?? "0",
    threeIRhd: "" + row.totalIrCount ?? "0",
    cogs: row.cogs ? formatter.format(row.cogs) : "0",
    margin: row.marginPercent ?? "0",
    taskGroupings: [
      {
        subtask: 'Kickoff Call',
        status: row.siteKickoffCallCompleted ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Deposit',
        status: row.depositReceivedDate ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Pad',
        status: row.padVerifiedDate ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Power',
        status: row.powerInstalledDate ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Internet',
        status: row.internetVerifiedDate ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Shipping Scheduled',
        status: row.productsShipped ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Crane Ordered',
        status: row.craneArrivalConfirmation ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Lifts Scheduled',
        status: row.dateOutConfirmation ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Nitro Ordered',
        status: row.confirmationNumber ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Handoff Call',
        status: row.customerHandoffCallCompleted ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      },
      {
        subtask: 'Invoicing Submitted',
        status: row.readyToBill ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      }
    ],
  }
}

function CollapsibleRow(props) {
  const { row } = props;
  const [products, setProducts] = useState([]);
  const [stats, setStats] = useState([]);
  const [siteInfo, setSiteInfo] = useState([]);
  //eslint-disable-next-line
  const [currentUnit, setCurrentUnit] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productEndpoint = `api/Projects/products/${row.id}`;
        const productResponse = await axios.get(productEndpoint);
        const productData = productResponse.data;
        setProducts(productData);

        if (productData.length > 0) {
          const statsEndpoint = `api/WIP/AllStatsForProject?projectId=${row.id}`;
          const statsResponse = await axios.get(statsEndpoint);
          const statsData = statsResponse.data;
          setStats(statsData);
        }

        const siteInfoEndpoint = `/api/Onboarding/siteInfo/${row.id}`;
        const siteInfoResponse = await axios.get(siteInfoEndpoint);
        const siteInfoData = siteInfoResponse.data;
        setSiteInfo(siteInfoData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [row.id, currentUnit]);

  return (
    <Box sx={{ margin: 1, minWidth: '80vw' }}>
      <Grid
        container
        rowSpacing={0}
      >
        <Grid item container style={{ justifyContent: "center" }} xs={5}>
          <Stack>
            <Typography variant="h5" align="center" gutterBottom style={{ paddingTop: "25px", paddingBottom: "20px" }}>
              Site Info
            </Typography>
            <Typography style={{ paddingBottom: "20px" }}>
              <b>Site Address:</b> {`${siteInfo.csAddress1 || ''} ${siteInfo.csCity || ''} ${siteInfo.csState || ''} ${siteInfo.csZip || ''} ${siteInfo.csCountry || ''}`}
            </Typography>
            <Typography style={{ paddingBottom: "20px" }}>
              <b>Account Manager:</b> {siteInfo.csAccountManager ?? ''}
            </Typography>
            <Typography>
              <b>Sales Rep:</b> {siteInfo.salesRep ?? ''}
            </Typography>
          </Stack>
        </Grid>
        {products && products.length > 0
          ?
          <Grid item container xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <Paper sx={{ width: "auto", height: "85%", paddingBottom: "30px", ml: 3, mt: 2 }}>
              <Table size="small" aria-label="task-groupings">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Unit</TableCell>
                    <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Build Status</TableCell>
                    {stats.length > 7 ? (
                      <>
                        <TableCell style={{ fontWeight: "bold" }}>Unit</TableCell>
                        <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Build Status</TableCell>
                      </>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length > 7 &&
                    products.map((product, index) => (
                      index % 2 === 0 ? (
                        <TableRow key={index} onClick={(e) => e.stopPropagation()}>
                          <TableCell style={{ padding: "16px" }}>{product.productName ?? "Unit " + (index + 1)}</TableCell>
                          <TableCell style={{ padding: "16px", textAlign: "center" }}>
                            <ProgressCell params={(() => {
                              const foundStat = stats.find(stat => stat.productId === product.productId);
                              return foundStat ? foundStat.unitBuildStatus : 0;
                            })()} />
                          </TableCell>
                          {products[index + 1] && (
                            <>
                              <TableCell style={{ padding: "16px" }}>{products[index + 1].productName ?? "Unit " + (index + 2)}</TableCell>
                              <TableCell style={{ padding: "16px", textAlign: "center" }}>
                                <ProgressCell params={(() => {
                                  const foundStat = stats.find(stat => stat.productId === products[index + 1].productId);
                                  return foundStat ? foundStat.unitBuildStatus : 0;
                                })()} />
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ) : null
                    ))}
                  {products.length <= 7 &&
                    products.map((product, index) => (
                      <TableRow key={index} onClick={(e) => e.stopPropagation()}>
                        <TableCell style={{ padding: "16px", width: "50%" }}>{product.productName ?? "Unit " + (index + 1)}</TableCell>
                        <TableCell style={{ padding: "16px", textAlign: "center" }}>
                          <ProgressCell params={(() => {
                            const foundStat = stats.find(stat => stat.productId === product.productId);
                            return foundStat ? foundStat.unitBuildStatus : 0;
                          })()} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          :
          <Grid item container style={{ justifyContent: "center" }} xs={9}>
            <Stack direction="row" alignItems="center">
              <Typography>This project has no documented units.</Typography>
            </Stack>
          </Grid>
        }
      </Grid>
    </Box>
  );
}

export default function WIPTable(props) {
  const [projects, setProjects] = useState(null);
  const [allProjects, setAllProjects] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [monthlyStartDate, setMonthlyStartDate] = useState(null);
  const [monthlyEndDate, setMonthlyEndDate] = useState(null);

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  useEffect(() => {
    const endpoint = 'api/WIP/Summary';
    axios.get(endpoint)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const endpoint = 'api/WIP/AllWip';
    axios.get(endpoint)
      .then((response) => {
        setAllProjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (projects) {
      const filterConditions = {
        All: project => !project.activationComplete && project.projectId !== 761,
        Pending: project => !project.installComplete && !project.activationComplete && project.projectId !== 761,
        Installed: project => project.installComplete && !project.activationComplete && project.completionStatus !== "Completed",
        Activated: project => project.activationComplete,
        Monthly: project => project.activationComplete,
      };

      const filterType = filterConditions[props.type] || (project => true);
      if (props.type === "Monthly") {
        if (allProjects !== null) {
          const filtered = allProjects.filter(filterType);
          const newRows = filtered.map(row => loadData(row));
          setRows(newRows);
        }
      }
      else {
        const filtered = projects.filter(filterType);
        const newRows = filtered.map(row => loadData(row));
        setRows(newRows);
      }

    }
  }, [props.type, projects, allProjects]);

  useEffect(() => {
    if (allProjects && props.type === "Monthly") {
      const distantPast = new Date(-8640000000000000);
      const today = new Date();
      const start = monthlyStartDate ? new Date(monthlyStartDate) : distantPast;
      const end = monthlyEndDate ? new Date(monthlyEndDate) : today;

      const filtered = allProjects.filter(p => {
        if (p.activationCompleteDate) {
          var currentDate = new Date(p.activationCompleteDate);
          // Adjust for Time Zone
          currentDate = offsetDate(currentDate);

          // Range from midnight at the start date, to 11:59:59 PM at the end date
          return (currentDate.getTime() >= start.getTime() && currentDate.getTime() <= end.setHours(23, 59, 59));
        }
        return false;
      });

      const newRows = filtered.map(row => loadData(row));
      setRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyStartDate, monthlyEndDate, allProjects]);

  useEffect(() => {
    let projIds = []; // eslint-disable-next-line
    table.getRowModel().flatRows.map((row) => {
      projIds.push(Number(row.original.id));
    });

    props.onFilter(projIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, rows]);

  const handleExportData = () => {
    const rowData = rows.map((row) => row);
    rowData.forEach(row => {
      if (row["taskGroupings"]) {
        delete row["taskGroupings"];
      }
      if (row["sitePrepCompletion"] && typeof row["sitePrepCompletion"] !== "number") {
        let com = row["sitePrepCompletion"].props.params;
        row["sitePrepCompletion"] = com;
      }
      if (row["coordinationCompletion"] && typeof row["coordinationCompletion"] !== "number") {
        let com = row["coordinationCompletion"].props.params;
        row["coordinationCompletion"] = com;
      }
      if (row["totalBuildStatusPercentage"] && typeof row["totalBuildStatusPercentage"] !== "number") {
        let com = row["totalBuildStatusPercentage"].props.params;
        row["totalBuildStatusPercentage"] = com;
      }

      if (row["activationComplete"] && typeof row["activationComplete"] !== "boolean") {
        let com = row["activationComplete"].props.style.color;
        row["activationComplete"] = com === "green";
      }
      if (row["installComplete"] && typeof row["installComplete"] !== "boolean") {
        let com = row["installComplete"].props.style.color;
        row["installComplete"] = com === "green";
      }
      if (row["readyToBill"] && typeof row["readyToBill"] !== "boolean") {
        let com = row["readyToBill"].props.style.color;
        row["readyToBill"] = com === "green";
      }
      if (row["readyToBillCompleted"] && typeof row["readyToBillCompleted"] !== "boolean") {
        let com = row["readyToBillCompleted"].props.style.color;
        row["readyToBillCompleted"] = com === "green";
      }
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleRedirect = (id) => {
    navigate(`/projectManagement/project?id=${id}`);
  }

  const handleSearch = () => {
    let projIds = []; // eslint-disable-next-line
    table.getRowModel().flatRows.map((row) => {
      projIds.push(Number(row.original.id));
    });

    props.onFilter(projIds);
  }

  const handleClearFilter = () => {
    setMonthlyStartDate(null)
    setMonthlyEndDate(null)
  }

  const columns =
    [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 80,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Button
            variant="contained"
            onClick={() => handleRedirect(row.original.id)}
            sx={{ padding: "0px" }}>
            {row.original.id}
          </Button>
        ),
      },
      {
        accessorKey: 'masterCustomer',
        header: 'Master Customer',
        enableColumnOrdering: false,
        minSize: 200,
      },
      {
        accessorKey: 'site',
        header: 'Site',
        minSize: 250,
      },
      {
        accessorKey: 'units',
        header: 'Units',
        minSize: 100,
        size: 150
      },
      {
        accessorKey: 'state',
        header: 'State',
        minSize: 30,
        maxSize: 60,
      },
      {
        accessorKey: 'pm',
        header: 'PM',
        size: 130,
      },
      {
        accessorKey: 'daysOpen',
        header: 'Days Open',
        size: 95,
      },
      {
        accessorKey: 'rmrValue',
        header: 'RMR',
        size: 100,
      },
      {
        accessorKey: 'installValue',
        header: 'Install Value',
        size: 100,
      },
      {
        accessorKey: 'shipDateConfirm',
        header: '',
        size: 15,
        enableResizing: false,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
        Cell: ({ row }) => (
          <>
            {row.original.shipDateConfirm && <LockIcon style={{ color: 'red', marginRight: '4px' }} />}
          </>
        ),
      },
      {
        accessorKey: 'shipDate',
        header: 'Ship Date',
        size: 200,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'installDate',
        header: 'Install Date',
        size: 200,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'sitePrepCompletion',
        header: 'Site Prep',
        size: 150,
        enableResizing: false,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.params > a.original[columnId].props.params)
            return -1;
          else if (b.original[columnId].props.params < a.original[columnId].props.params)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'coordinationCompletion',
        header: 'Coordination',
        size: 150,
        enableResizing: false,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.params > a.original[columnId].props.params)
            return -1;
          else if (b.original[columnId].props.params < a.original[columnId].props.params)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'totalBuildStatusPercentage',
        header: 'Total Build',
        size: 150,
        enableResizing: false,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.params > a.original[columnId].props.params)
            return -1;
          else if (b.original[columnId].props.params < a.original[columnId].props.params)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'installComplete',
        header: 'Install Complete',
        size: 130,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.style.color < a.original[columnId].props.style.color)
            return -1;
          else if (b.original[columnId].props.style.color > a.original[columnId].props.style.color)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'installedDate',
        header: 'Installed Date',
        size: 130,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'activationComplete',
        header: 'Activation',
        size: 90,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.style.color < a.original[columnId].props.style.color)
            return -1;
          else if (b.original[columnId].props.style.color > a.original[columnId].props.style.color)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'activatedDate',
        header: 'Activated Date',
        size: 130,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'readyToBill',
        header: 'Ready To Bill',
        size: 110,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.style.color < a.original[columnId].props.style.color)
            return -1;
          else if (b.original[columnId].props.style.color > a.original[columnId].props.style.color)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'readyToBillCompleted',
        header: 'Billing Complete',
        size: 110,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId].props.style.color < a.original[columnId].props.style.color)
            return -1;
          else if (b.original[columnId].props.style.color > a.original[columnId].props.style.color)
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'centralStation',
        header: 'Central Station',
        size: 100,
      },
      {
        accessorKey: 'unitCount',
        header: 'Unit Count',
        size: 100,
      },
      {
        accessorKey: 'riserCount',
        header: 'Riser Count',
        size: 100,
      },
      {
        accessorKey: 'thermals',
        header: 'Thermals',
        size: 100,
      },
      {
        accessorKey: 'threeIRhd',
        header: 'IR Count',
        size: 100,
      },
      {
        accessorKey: 'cogs',
        header: 'Cogs',
        size: 100,
        sortingFn: (a, b, columnId) => {
          return a.original[columnId].replace(/(^\$|,)/g, '').localeCompare(b.original[columnId].replace(/(^\$|,)/g, ''), undefined, { numeric: true, sensitivity: 'base' });
        }
      },
      {
        accessorKey: 'margin',
        header: 'Margin%',
        size: 100,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableColumnResizing: true,
    paginationDisplayMode: 'pages',
    onGlobalFilterChange: setGlobalFilter,
    layoutMode: 'grid',
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'installDate',
          desc: true,
        },
        {
          id: 'sitePrepCompletion',
          desc: true,
        },
      ],
      columnPinning: { left: ['mrt-row-expand', 'id', 'masterCustomer'] },
      columnVisibility: {
        'cogs': props.type === "Activated" || props.type === "Monthly",
        'margin': props.type === "Activated" || props.type === "Monthly",
        'installedDate': props.type === "Activated" || props.type === "Installed" || props.type === "All" || props.type === "Monthly",
        'activatedDate': props.type === "Activated" || props.type === "All" || props.type === "Monthly",
        'daysOpen': props.type !== "Activated" && props.type !== "Installed" && props.type !== "Monthly",
        'shipDateConfirm': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        'shipDate': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        'installDate': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        'sitePrepCompletion': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        'coordinationCompletion': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        'totalBuildStatusPercentage': props.type !== "Installed" && props.type !== "Activated" && props.type !== "Monthly",
        // short term fix to hide installComplete and activationComplete columns.  Can be fully removed later
        'installComplete': props.type !== "Installed" && props.type !== "Activated" && props.type !== "All" && props.type !== "Pending" && props.type !== "Monthly",
        'activationComplete': props.type !== "Installed" && props.type !== "Activated" && props.type !== "All" && props.type !== "Pending" && props.type !== "Monthly",
        'readyToBill': props.type === "Activated" || props.type === "All" || props.type === "Monthly",
        'readyToBillCompleted': props.type === "Activated" || props.type === "All" || props.type === "Monthly",
      },
      pagination: { pageSize: 100, pageIndex: 0 },
    },
    state: { globalFilter, },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {props.type === "Monthly"
          ?
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container direction="row" alignItems="center">
              <Box paddingRight={1}>
                <DatePicker onChange={setMonthlyStartDate} value={monthlyStartDate} label={"Start Date"} table={table} />
              </Box>
              —
              <Box paddingLeft={1}>
                <DatePicker onChange={setMonthlyEndDate} value={monthlyEndDate} label={"End Date"} table={table} paddingLeft={10} />
              </Box>
              <Box paddingLeft={1}>
                <Button onClick={handleClearFilter}>Clear Dates</Button>
              </Box>
              <Box>
                <Button
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  startIcon={<FileDownloadIcon />}
                >
                  Export to csv
                </Button>
              </Box>
            </Grid>
          </LocalizationProvider>
          :
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
            >
              Export to csv
            </Button>
            {/* <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
          >
            Export All Rows
          </Button> */}
          </Box>}
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {/* built-in buttons (must pass in table prop for them to work!) */}
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
        {/* add your own custom print button or something */}
        <IconButton onClick={() => handleSearch()}>
          <RefreshIcon />
        </IconButton>
      </>
    ),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.id} row={row.original} type={props.type} />
  });

  return <MaterialReactTable table={table} />;
}

/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from '@mui/material';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import PropTypes from 'prop-types';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleGlobalFilterButton } from 'material-react-table';
import ProgressCell from "../ProgressCell";
import { StockModal } from "./StockModal";
import { StockAssign } from "./StockAssign";

function createData(projid, productid, devicename, bayNo, cmName, csName, devicetype, installdate, shipDateConfirmed, shipdate, buildProgress, zonecount, detectonly, riser, cold, mast, vs, tc, fc, sc, intl, stats) {
  return {
    projid,
    productid,
    devicename,
    bayNo,
    cmName,
    csName,
    devicetype,
    installdate,
    shipDateConfirmed,
    shipdate,
    buildProgress,
    zonecount,
    detectonly,
    riser,
    cold,
    mast,
    vs,
    tc,
    fc,
    sc,
    intl,
    stats,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '10px', border: 1, borderRadius: '5px', borderColor: 'divider', minHeight: '25vh' }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function StockUnits() {
  const [rows, setRows] = useState([]);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    const endpoint = 'api/Manufacturing/products';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadRows = (products) => {
    let newRows = [];
    let projects = [];

    if (products.length > 0) {
      products.forEach(product => {
        if (projects.indexOf(product.projectId) === -1) {
          projects.push(product.projectId);
        }

        if (product.projectId !== 761) {    //Master Stock Customer ID
          return;
        }

        const offsetDate = (date) => {
          return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
        }

        newRows.push(createData(
          product.projectId,
          product.productId,
          product.productName,
          product.bayNo,
          product.cmName,
          product.csName,
          product.productType,
          product.installDate ? offsetDate(new Date(product.installDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          product.shipDateConfirmed,
          product.shippedDate ? offsetDate(new Date(product.shippedDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          <ProgressCell params={product.unitBuildStatus} />,
          product.zones.filter(zone => zone.detectionOnlyZone === false).length,
          product.zones.filter(zone => zone.detectionOnlyZone === true).length,
          product.productConfigurations[0].riserCount,
          product.productConfigurations[0].coldWeatherPackage ? "Yes" : "No",
          product.productConfigurations[0].mastOption ? "Yes" : "No",
          product.productConfigurations[0].viewingStation ? "Yes" : "No",
          product.productConfigurations[0].thermalCameraCount,
          product.productConfigurations[0].flameDetectorCount,
          product.productConfigurations[0].smokeDetectorCount,
          product.productConfigurations[0].international ? "Yes" : "No",
          product.buildStatusSummaries[0],
        ))
      });
    }
    setRows(newRows);
  }

  const columns =
    [
      {
        accessorKey: 'devicename',
        header: 'Device Name',
        size: 150,
      },
      {
        accessorKey: 'bayNo',
        header: 'Bay No.',
        size: 110,
        Cell: ({ row }) => (
          <TextField
            label="Bay Number"
            name="bayNo"
            value={row.original.bayNo ?? 'None'}
            disabled
            style={{ minWidth: "26%" }}
          />
        ),
      },
      {
        accessorKey: 'id',
        header: 'Project',
        size: 110,
        Cell: ({ row }) => (
          <StockAssign refresh={fetchData} stockName={row.original.devicename} />
        ),
      },
      {
        accessorKey: 'buildProgress',
        header: 'Build Progress',
        size: 175,
      },
      {
        accessorKey: 'riser',
        header: 'Risers',
        size: 150,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    state: {
      showSkeletons: isLoading,
    },
    muiSkeletonProps: {
      animation: 'wave',
    },
    initialState: {
      density: 'compact',
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderTopToolbar: ({ table }) => {

      return (
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: '0.5rem',
            p: '8px 8px 0px 0px',
            justifyContent: 'space-between',
          })}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>

            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <StockModal refresh={fetchData} />
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>

        </Box>
      );
    },
  });

  return (
    <MaterialReactTable table={table} />
  );
}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Box, Typography, Grid } from '@mui/material';
import SwitchMapData from './SwitchMapData.json';


export default function SwitchMapTable({ productType, thermal, flame, smoke }) {
  const [prodType, setProdType] = useState(productType);
  const tCount = thermal;
  const fCount = flame;
  const sCount = smoke;
  const allSwitchData = SwitchMapData;
  const [zoneCount, setZoneCount] = useState(0);
  const [edData, setEdData] = useState();
  const [owData1, setOwData1] = useState();
  const [owData2, setOwData2] = useState();
  const [currentTemplate, setCurrentTemplate] = useState();
  const [thermalCount, setThermalCount] = useState(0);
  const [flameCount, setFlameCount] = useState(0);
  const [smokeCount, setSmokeCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (tCount) {
      setThermalCount(tCount);
    }

    if (fCount) {
      setFlameCount(fCount);
    }

    if (sCount) {
      setSmokeCount(sCount);
    }
  }, [tCount, fCount, sCount]);

  useEffect(() => {
    if (smokeCount || flameCount || thermalCount) {
      setZoneCount(smokeCount + flameCount + thermalCount);
    }
  }, [smokeCount, flameCount, thermalCount]);

  useEffect(() => {
    setEdData(allSwitchData.switchMapTemplates[0]);
    setOwData1(allSwitchData.switchMapTemplates[1]);
    setOwData2(allSwitchData.switchMapTemplates[2]);
  }, [allSwitchData]);

  useEffect(() => {
    setProdType(productType);
    if (prodType === 'ED') {
      setCurrentTemplate(edData);
    } else if (prodType === 'OW') {
      if (zoneCount <= 2) {
        setCurrentTemplate(owData1);
      } else {
        setCurrentTemplate(owData2);
      }
    }
  }, [edData, owData1, owData2, zoneCount, prodType]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="left">
              {currentTemplate ? currentTemplate.mapName : 'Loading...'}
            </TableCell>
          </TableRow>
        </TableHead>
        <Box align="center" sx={{ maxHeight: 650, minHeight: 650}}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
                  {currentTemplate && currentTemplate.subTemplates.map((subTemplate, index) => (
                    <Tab label={subTemplate.name} key={index} />
                  ))}
                </Tabs>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Grid container spacing={2}>
                  
                  <Grid item xs={9}>
                    {currentTemplate && currentTemplate.subTemplates.map((subTemplate, index) => (
                      <Box key={index} hidden={selectedTab !== index} p={3}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableBody>
                              {Object.entries(subTemplate.properties).map(([key, value]) => {
                                const isPoePowered = subTemplate.poePowered.includes(key);
                                return (
                                  <TableRow key={key}>
                                    <TableCell align="center">{key}</TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: isPoePowered ? '#FBFF97' : 'inherit',
                                        color: isPoePowered ? 'black' : 'inherit'
                                      }}
                                    >
                                      {value}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={3}>
                    <Box display="flex" alignItems="top" justifyContent="center" marginLeft={0} mt={2} sx={{ height: '100%' }}>
                      <Box
                        sx={{ width: 20, height: 20, backgroundColor: '#FBFF97', border: '1px solid black', marginRight: 1 }}
                      />
                      <Typography variant="body2">POE Powered</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Box>
      </Table>
    </TableContainer>
  );
}

SwitchMapTable.propTypes = {
  productType: PropTypes.string.isRequired,
  thermal: PropTypes.number.isRequired,
  flame: PropTypes.number.isRequired,
  smoke: PropTypes.number.isRequired,
};



import React, { useState, useEffect } from "react";
import { Grid, Box, Stack, Typography, Button, Modal, TextField, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import moment from "moment";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { TimePicker } from "@mui/x-date-pickers-pro";
import MuiAlert from '@mui/material/Alert';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function createTimeLogData(timesheetid, userid, date, clockin, clockout, hourtype, hoursworked, day, description, weekof) {
    return {
        timesheetid,
        userid,
        date,
        clockin,
        clockout,
        hourtype,
        hoursworked,
        day,
        description,
        weekof
    }
}

export function TimeCell(cell) {

    const [open, setOpen] = React.useState(false);
    const [time, setTime] = useState(0);
    const [clockInTime, setClockInTime] = useState(null);
    const [clockOutTime, setClockOutTime] = useState(null);
    const [description, setDescription] = useState("");
    const [timeLogsList, setTimeLogsList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const handleSubmitHours = (event) => {
        setLoading(true);

        const handleCleanUp = () => {
            if (cell.type === "Regular Work") {
                let timeAdjustment = 0;
                if (clockOutTime !== null) {
                    if (((clockOutTime - clockInTime) / 3600000) < 0) {
                        timeAdjustment = 24;
                    }
                    setTime(time + +((clockOutTime - clockInTime) / 3600000).toFixed(2) + timeAdjustment);
                    setClockInTime(null);
                    setClockOutTime(null);
                    setDescription("");
                }
            }
        }

        const handleCalcHours = () => {
            let timeAdjustment = 0;
            if (clockInTime && clockOutTime) {
                if (((clockOutTime - clockInTime) / 3600000) < 0) {
                    timeAdjustment = 24;
                }
                return (((clockOutTime - clockInTime) / 3600000 + timeAdjustment).toFixed(2));
            }
            return (0);
        }

        const getDateFromDay = () => {
            const i = weekdays.indexOf(cell.day);
            var weekStart = new Date(cell.weekOf);
            return moment(weekStart).add(i, 'days')
        }

        const newTimeLog = {
            empUserId: cell.employeeId,
            timesheetDate: getDateFromDay().toISOString().substring(0, 10),
            clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
            clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
            hourType: cell.type,
            hoursWorked: handleCalcHours(),
            tsDescription: description ? description : "-",
            tsDay: cell.day,
            weekOf: new Date(cell.weekOf).toISOString().substring(0, 10)
        };

        axios
            .post("/api/TimeSheet/createTimeLog", newTimeLog)
            .then(() => {
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error(error);
                alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
            })
            .finally(() => {
                setLoading(false);
                handleCleanUp();
                handleClose();
                cell.updateRow();
            });
    }

    const handleSetTime = (val) => {
        setTime(val);
        cell.setTime(time, cell.day, cell.type);
    }

    useEffect(() => {
        setTime(cell.time);
    }, [cell])

    useEffect(() => {
        cell.setTime(time, cell.day, cell.type);
        // eslint-disable-next-line
    }, [time]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    useEffect(() => {
        setLoading(true);
        const endpoint = 'api/Timesheet/timeSheet';
        axios.get(endpoint)
            .then((response) => {
                loadTimeLogList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const loadTimeLogList = (timeLogs) => {

        let newRows = [];
        if (timeLogs.length > 0) {
            timeLogs.forEach(log => {
                const offsetDate = (date) => {
                    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
                }
                newRows.push(createTimeLogData(
                    log.timesheetId,
                    log.empUserId,
                    log.timesheetDate ? offsetDate(new Date(log.timesheetDate)) : null,
                    log.clockIn,
                    log.clockOut,
                    log.hourType,
                    log.hoursWorked,
                    log.tsDay,
                    log.tsDescription,
                    log.weekOf,
                ))
            })
        }
        setTimeLogsList(newRows);
    }

    return (
        <>
            <Stack direction="row" minHeight="54px">
                <Box height={"10px"}>
                    <TextField
                        value={Number(time.toFixed(2))}
                        disabled={cell.type === "Regular Work"}
                        onChange={(e) => handleSetTime((e.target.value) * 1)}
                        type="number"
                        InputProps={{
                            sx: {
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none'
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield'
                                },
                            },
                            maxLength: 7,
                            inputProps: { min: 0 }
                        }}
                    />
                </Box>

                {cell.type === "Regular Work"
                    ?
                    <Box position="absolute" marginLeft={"4.5%"} marginTop={"0.5%"} >
                        <Button onClick={handleOpen} disabled={cell.denyChanges}>
                            <AddCircleOutlineOutlinedIcon />
                        </Button>
                    </Box>
                    :
                    null
                }

                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                        >
                            X
                        </Button>

                        <Typography
                            variant="h6"
                            component="h2"
                            style={{ paddingBottom: "20px" }}
                        >
                            Input {cell.type} for {cell.day}
                        </Typography>
                        {cell.type === "Regular Work"
                            ?
                            <>
                                <Stack width={"100%"} marginBottom={"10px"} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Clock In Time"
                                            value={clockInTime}
                                            onChange={setClockInTime}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack width={"100%"} marginBottom={"10px"}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Clock Out Time"
                                            value={clockOutTime}
                                            onChange={setClockOutTime}
                                            disabled={!clockInTime}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack width={"100%"} marginBottom={"15px"}>

                                    <TextField
                                        label="Description"
                                        value={description}
                                        onChange={(val) => setDescription(val.target.value)}
                                    />

                                </Stack>
                            </>
                            :
                            <Stack width={"100%"} marginBottom={"15px"} marginX={"10px"}>
                                <TextField
                                    label="Number of Hours"
                                    placeholder={"0"}
                                    value={time}
                                    onChange={(e) => setTime((e.target.value) * 1)}
                                    type="number"
                                    InputProps={{
                                        maxLength: 7,
                                    }}
                                />
                            </Stack>
                        }

                        <Grid
                            container
                            justifyContent="flex-end"
                        >
                            <Button
                                style={{ color: "green" }}
                                onClick={handleSubmitHours}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Box>

                </Modal>
            </Stack>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Time sheet saved!
                </MuiAlert>
            </Snackbar>
        </>
    )
}
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TextField, TableRow, IconButton, Grid, Stack, Typography, FormControl, InputLabel, Select, MenuItem, ToggleButtonGroup, Button, ToggleButton } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import CancelIcon from '@mui/icons-material/Cancel';
import { visuallyHidden } from '@mui/utils';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import ManLiftDetails from "./ManLiftDetails";
import { useNavigate } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMsal } from "@azure/msal-react";

function createData(projid, mastercust, sitename, units, siteaddress, sitecity, sitestate, sitezip, installdate, shipdateconfirm, shipdate, installlength, shipped, installcompleted, bsucount, owcount, edcount, cfcount, products) {
  return {
    projid,
    mastercust,
    sitename,
    units,
    siteaddress,
    sitecity,
    sitestate,
    sitezip,
    installdate,
    shipdateconfirm,
    shipdate,
    installlength,
    shipped,
    installcompleted,
    bsucount,
    owcount,
    edcount,
    cfcount,
    products,
  };
}

const headCells = [
  {
    id: 'check',
    label: '',
    align: 'center'
  },
  {
    id: 'projid',
    align: 'left',
    colSpan: 1,
    label: 'Project ID',
  },
  {
    id: 'mastercust',
    align: 'right',
    colSpan: 1,
    label: 'Customer Name',
  },
  {
    id: 'sitename',
    align: 'right',
    colSpan: 1,
    label: 'Site Name',
  },
  {
    id: 'units',
    align: 'right',
    colSpan: 1,
    label: 'Units',
  },
  {
    id: 'siteaddress',
    align: 'right',
    colSpan: 1,
    label: 'Address',
  },
  {
    id: 'sitecity',
    align: 'right',
    colSpan: 1,
    label: 'City',
  },
  {
    id: 'sitestate',
    align: 'right',
    colSpan: 1,
    label: 'State',
  },
  {
    id: 'sitezip',
    align: 'right',
    colSpan: 1,
    label: 'Zip',
  },
  {
    id: 'installdate',
    align: 'right',
    colSpan: 1,
    label: 'Install Date',
  },
  {
    id: 'shipdateconfirm',
    align: 'right',
    colSpan: 1,
    label: '',
  },
  {
    id: 'shipdate',
    align: 'left',
    colSpan: 1,
    label: 'Ship Date',
  },
  {
    id: 'installlength',
    align: 'right',
    colSpan: 1,
    label: 'Install Length (Days)',
  },
  {
    id: 'shipped',
    align: 'right',
    colSpan: 1,
    label: 'Products Shipped',
  },
  {
    id: 'installcompleted',
    align: 'right',
    colSpan: 1,
    label: 'Install Completed',
  },
  {
    id: 'bsucount',
    align: 'right',
    colSpan: 1,
    label: 'BSUs',
  },
  {
    id: 'owcount',
    align: 'right',
    colSpan: 1,
    label: 'On Watch',
  },
  {
    id: 'edcount',
    align: 'right',
    colSpan: 1,
    label: 'Early Detection',
  },
  {
    id: 'cfcount',
    align: 'right',
    colSpan: 1,
    label: 'Continuous Flow',
  },

];

const defaultConfig = [
  {
    stat1: 'Risers',
    status1: 0,
    stat2: 'Cold Weather Package',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'Viewing Stations',
    status1: 0,
    stat2: 'Mast Option',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'Thermal Cameras',
    status1: 0,
    stat2: 'Conveyor Stop',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'Flame Detectors',
    status1: 0,
    stat2: 'Point to Point',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'Smoke Detectors',
    status1: 0,
    stat2: 'FD Connects',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'E-Buttons',
    status1: 0,
    stat2: 'Interconnect',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat1: 'F500',
    status1: 0,
    stat2: 'International',
    status2: <CancelIcon style={{ color: 'red' }} />
  },
  {
    stat2: '4G Primary',
    status2: <CancelIcon style={{ color: 'red' }} />
  }
]

function CollapsibleRow(props) {
  const { row } = props;
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  // eslint-disable-next-line
  const [products, setProducts] = useState(props.row.products);
  const [config, setConfig] = useState(defaultConfig);
  const [currentUnit, setCurrentUnit] = useState(0);
  const [installDate, setInstallDate] = useState(row.installdate === "-" ? null : row.installdate);
  const [installDays, setInstallDays] = useState(row.installlength);
  const [riggersNeeded, setRiggersNeeded] = useState(false);
  const [shipped, setShipped] = useState(row.shipped === "Yes" ? true : false);
  const [installed, setInstalled] = useState(row.installcompleted === "Yes" ? true : false);
  const [installCompleteDate, setInstallCompleteDate] = useState(null);
  const [installCompleteMessageStatus, setInstallCompleteMessageStatus] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (products && products[currentUnit]) {
      const endpoint = 'api/Manufacturing/config/' + products[currentUnit].productId;
      axios.get(endpoint)
        .then((response) => {
          loadConfig(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [row.id, currentUnit]);

  useEffect(() => {
    const endpoint = `api/Coordination/craneDetails/${row.projid}`;

    axios
      .get(endpoint)
      .then((response) => {
        setRiggersNeeded(response.data.riggersNeeded);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching crane details.");
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (products && products[currentUnit]) {
      const currentProductId = products[currentUnit].productId;
      const currentProductType = products[currentUnit].productType;

      const endpoint = `api/Manufacturing/buildStatusProgress?ProductId=${currentProductId}&ProductType=${currentProductType}`;
      axios.get(endpoint)
        .then((response) => {
          setProgress(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [currentUnit]);

  const loadConfig = (stat) => {
    const newConfig = [...config];

    newConfig[0].status1 = stat.riserCount ?? 0;
    newConfig[0].status2 = stat.coldWeatherPackage ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[1].status1 = stat.viewingStation ?? 0;
    newConfig[1].status2 = stat.mastOption ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[2].status1 = stat.thermalCameraCount ?? 0;
    newConfig[2].status2 = stat.conveyorStop ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[3].status1 = stat.flameDetectorCount ?? 0;
    newConfig[3].status2 = stat.pointToPoint ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[4].status1 = stat.smokeDetectorCount ?? 0;
    newConfig[4].status2 = stat.fdConnects ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[5].status1 = stat.ebuttonCount ?? 0;
    newConfig[5].status2 = stat.interconnect ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[6].status1 = stat.f500Count ?? 0;
    newConfig[6].status2 = stat.international ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    newConfig[7].status2 = stat._4gPrimary ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;

    setConfig(newConfig);
  }

  function ProgressCell({ params }) {
    const progress = params ?? 0;
    const barWidth = 180;
    const barHeight = 20;
    const filledWidth = (progress / 100) * barWidth;
    const emptyWidth = barWidth - filledWidth;

    return (
      <div style={{ width: barWidth, height: barHeight, border: "1px solid #e0e0e0", position: "relative" }}>
        <div style={{ width: filledWidth, height: barHeight, backgroundColor: "#35CB82", position: "absolute", top: 0, left: 0 }} />
        <div style={{ width: emptyWidth, height: barHeight, position: "absolute", top: 0, right: 0 }} />
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontWeight: "bold" }}>
          {`${progress}%`}
        </div>
      </div>
    );
  }

  const handleCurrentUnit = (event) => {
    setCurrentUnit(event.target.value);
  }

  const handleNextUnit = () => {
    if (currentUnit + 1 < products.length) {
      setCurrentUnit(currentUnit + 1)
    }
    else {
      setCurrentUnit(0)
    }
  }

  const handlePrevUnit = () => {
    if (currentUnit - 1 >= 0) {
      setCurrentUnit(currentUnit - 1)
    }
    else {
      setCurrentUnit(products.length - 1)
    }
  }

  const handleShipped = (event, val) => {
    if (val !== null) {
      setShipped(val);
    }
  }

  const handleInstalled = (event, insComplete) => {
    if (insComplete && !installed) {
      setInstallCompleteDate(new Date());
      setInstallCompleteMessageStatus(true);
    }
    setInstalled(insComplete);
  }

  const handleRiggersNeeded = (event, val) => {
    if (val !== null) {
      setRiggersNeeded(val);
    }
  }

  const handleSubmit = async () => {

    let updatedProject = {
      "projectId": row.projid,
      "installComplete": installed,
      "installCompleteDate": installCompleteDate,
      "productsShipped": shipped,
      "installLength": installDays,
      "installDate": dayjs(installDate)
    };

    try {
      await axios.post("/api/Manufacturing/updateProject", updatedProject);

      let updatedRiggersNeeded = {
        "projectId": row.projid,
        "riggersNeeded": riggersNeeded,
      };

      if (installCompleteMessageStatus) {
        const installNote = {
          UserName: activeAccount.name.split(' ')[0],
          Notes: "Install Completed.",
          DateTime: new Date(new Date().getTime()),
          ProjectId: row.projid
        };

        await axios.post("/api/ActivityFeed/saveNote", installNote)
          .then(() => {
            setInstallCompleteMessageStatus(false);
          })
      }

      await axios.post("/api/Coordination/updateRiggersNeeded", updatedRiggersNeeded);

      let dataForShipDateCalculation = {
        "projectId": row.projid,
        "installDate": dayjs(installDate),
        "csState": row.sitestate
      };

      if (installDate !== "-" && installDate !== null && (row.shipdate === "-" || row.shipdate === null)) {
        await axios.put("/api/Manufacturing/calculateShipDate", dataForShipDateCalculation);
      }

      window.location.reload();
      alert("Saved!");
    } catch (error) {
      alert("Error " + error);
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{ margin: 1, maxWidth: '100vw' }}>
        <Grid container rowSpacing={0}>
          <Grid
            item
            container
            style={{ justifyContent: "center", padding: "1%" }}
            xs={4}
          >
            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ minWidth: "35%" }}>
                  Install Date
                </Typography>

                <Box style={{ flexGrow: "1", minWidth: "60%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={"Install Date"}
                      onChange={setInstallDate}
                      value={dayjs(installDate)}
                      slotProps={{
                        field: { clearable: true },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ minWidth: "35%" }}>
                  Install Length
                </Typography>

                <TextField
                  label="Install Length"
                  name="installlength"
                  value={installDays}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  onChange={(e) => {
                    setInstallDays(e.target.value);
                  }}
                  style={{ minWidth: "26%" }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <ManLiftDetails
                projectId={row.projid}
                isCoordinationPage={false}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ minWidth: "35%" }}>
                  Riggers Needed
                </Typography>

                <ToggleButtonGroup
                  color="primary"
                  value={riggersNeeded}
                  exclusive
                  onChange={handleRiggersNeeded}
                  aria-label="Riggers"
                  style={{ flexGrow: "1", minWidth: "60%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ minWidth: "35%" }}>
                  Products Shipped
                </Typography>

                <ToggleButtonGroup
                  color="primary"
                  value={shipped}
                  exclusive
                  onChange={handleShipped}
                  aria-label="Shipped"
                  style={{ flexGrow: "1", minWidth: "60%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ minWidth: "35%" }}>
                  Install Completed
                </Typography>

                <ToggleButtonGroup
                  color="primary"
                  value={installed}
                  exclusive
                  onChange={handleInstalled}
                  aria-label="Installed"
                  style={{ flexGrow: "1", minWidth: "60%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "1%" }}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
          {products && products.length > 0 ? (
            <Grid item container style={{ padding: "1%" }} xs={8}>
              <Paper elevation={2} sx={{ width: "80%", height: "100%" }}>
                <Stack
                  direction="row"
                  sx={{ width: "100%", minWidth: "100%", ml: 1, mt: 1 }}
                >
                  <FormControl sx={{ width: "15%" }}>
                    <InputLabel id="unit-details-label">Unit</InputLabel>
                    <Select
                      labelId="unit-details-label"
                      id="unit-details-select"
                      value={currentUnit}
                      label="Unit"
                      onChange={handleCurrentUnit}
                    >
                      {products.map((product, index) => (
                        <MenuItem
                          key={product.productId + "" + index}
                          value={index}
                        >
                          {product.productName ?? "Unit " + (index + 1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <IconButton
                    onClick={handlePrevUnit}
                    aria-label="Previous Unit"
                  >
                    <ArrowLeftIcon />
                  </IconButton>

                  <IconButton onClick={handleNextUnit} aria-label="Next Unit">
                    <ArrowRightIcon />
                  </IconButton>

                  <div style={{ paddingLeft: "50%" }}>
                    <Typography>Build Status Progress</Typography>
                    <ProgressCell params={progress} />
                  </div>
                </Stack>
                {config ? (
                  <Paper
                    elevation={3}
                    sx={{
                      width: "95%",
                      height: "80%",
                      paddingx: "5%",
                      paddingTop: "1%",
                      paddingBottom: "5%",
                      ml: 3,
                      mt: 2,
                    }}
                  >
                    <Table size="small" aria-label="task-groupings">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Config Counts
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}></TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Config Options
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {config.map((stat, index) => (
                          <TableRow
                            key={index}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <TableCell>{stat.stat1}</TableCell>
                            <TableCell>{stat.status1}</TableCell>
                            <TableCell>{stat.stat2}</TableCell>
                            <TableCell>{stat.status2}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <></>
                )}
              </Paper>
            </Grid>
          ) : (
            <Grid item container style={{ padding: "1%" }} xs={8}>
              <Paper
                elevation={2}
                sx={{
                  width: "80%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>This project has no documented units.</Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            colSpan={headCell.colSpan}
          >
            {headCell.id !== 'check' ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              :
              <></>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const distantPast = new Date(-8640000000000000);

const sortingFn = (a, b, columnId) => {
    let dateA = a.original[columnId] && a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
    let dateB = b.original[columnId] && b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

    if (dateA.getTime() < dateB.getTime()) return -1;
    if (dateA.getTime() > dateB.getTime()) return 1;

    if (a.original.shipDateConfirmed && !b.original.shipDateConfirmed) return -1;
    if (!a.original.shipDateConfirmed && b.original.shipDateConfirmed) return 1;

    return 0;
};

export default function InventoryCoordination() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  //eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const endpoint = 'api/Manufacturing/projects';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const loadRows = (projects) => {

    let newRows = [];
    if (projects.length > 0) {
      projects.forEach(project => {
        if (project.projectId === 761) {    //Master Stock Customer ID
          return;
        }
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
        }

        newRows.push(createData(
          project.projectId,
          project.obNewDTOs[0].cmName,
          project.obNewDTOs[0].csName,
          project.productDTOs
            .map((p) => p.productName ? p.productName : null)
            .filter((name) => name !== null)
            .join(', '),
          project.obNewDTOs[0].csAddress1,
          project.obNewDTOs[0].csCity,
          project.obNewDTOs[0].csState,
          project.obNewDTOs[0].csZip,
          project.installDate ? offsetDate(new Date(project.installDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          project.shipDateConfirmed,
          project.shippedDate ? offsetDate(new Date(project.shippedDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          project.installLength ?? "0",
          project.productsShipped ? "Yes" : "No",
          project.installComplete ? "Yes" : "No",
          project.productDTOs.filter(p => p.productType === "BSU").length,
          project.productDTOs.filter(p => p.productType === "OW").length,
          project.productDTOs.filter(p => p.productType === "ED").length,
          project.productDTOs.filter(p => p.productType === "CF").length,
          project.productDTOs))
      });
    }
    setRows(newRows);
  }

  const handleRedirect = (id) => {
    navigate(`/projectManagement/project?id=${id}`);
  }

  const columns =
    [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Button
            variant="contained"
            onClick={() => handleRedirect(row.original.projid)}
            sx={{ padding: "0px" }}>
            {row.original.projid}
          </Button>
        ),
      },
      {
        accessorKey: 'mastercust',
        header: 'Customer Name',
        enableColumnOrdering: false,
        size: 200,
      },
      {
        accessorKey: 'sitename',
        header: 'Site Name',
        size: 250,
      },
      {
        accessorKey: 'units',
        header: 'Units',
        size: 150,
      },
      {
        accessorKey: 'siteaddress',
        header: 'Address',
        size: 250,
      },
      {
        accessorKey: 'sitecity',
        header: 'City',
        size: 150,
      },
      {
        accessorKey: 'sitestate',
        header: 'State',
        size: 100,
      },
      {
        accessorKey: 'sitezip',
        header: 'Zip',
        size: 150,
      },
      {
        accessorKey: 'installdate',
        header: 'Install Date',
        size: 150,
        sortingFn: sortingFn,
      },
      {
        accessorKey: 'shipdateconfirm',
        header: '',
        size: 50,
        sortingFn: sortingFn,
        Cell: ({ row }) => (
          <>
            {row.original.shipdateconfirm && <LockIcon style={{ color: 'red', marginRight: '4px' }} />}
          </>
        ),
      },
      {
        accessorKey: 'shipdate',
        header: 'Ship Date',
        size: 200,
        sortingFn: sortingFn,
      },
      {
        accessorKey: 'installlength',
        header: 'Install Length (Days)',
        size: 150,
      },
      {
        accessorKey: 'shipped',
        header: 'Products Shipped',
        size: 150,
      },
      {
        accessorKey: 'installcompleted',
        header: 'Install Completed',
        size: 150,
      },
      {
        accessorKey: 'bsucount',
        header: 'BSUs',
        size: 150,
      },
      {
        accessorKey: 'owcount',
        header: 'On Watch',
        size: 150,
      },
      {
        accessorKey: 'edcount',
        header: 'Early Detection',
        size: 150,
      },
      {
        accessorKey: 'cfcount',
        header: 'Continuous Flow',
        size: 150,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableExpanding: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'installdate',
          desc: true,
        },
      ],
      columnPinning: { left: ['mrt-row-expand', 'id', 'mastercust'] },
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.projid} row={row.original} />
  });

  return <MaterialReactTable table={table} />;
}

import React, { useEffect, useState } from 'react';
import { Button, Grid, Tab, Tabs, Typography, IconButton } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import AddDevice from './AddDevice';
import EditRow from './EditRow';
import AddNozzleMaster from './AddNozzleMaster';


export default function MidDeviceSection({ id, prodConfigId, prodType }) {
    const [deviceId, setDeviceId] = useState(id);
    const [allDevices, setAllDevices] = useState([]);
    const [productConfigId, setProductConfigId] = useState(prodConfigId);
    const [productType, setProductType] = useState(prodType);
    const [nozzleProgrammingDataList, setNozzleProgrammingDataList] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [editRowData, setEditRowData] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [nozzleIds, setNozzleIds] = useState([]);
    const handleModalOpen = () => setEditModalOpen(true);
    const handleModalClose = () => setEditModalOpen(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const handleEditModalClose = () => { setEditModalOpen(false) };


    useEffect(() => {
        setDeviceId(id);
        setProductType(prodType);
        setProductConfigId(prodConfigId);
    }, [id, prodConfigId, prodType]);

    const fetchDeviceInfo = async () => {
        if (productConfigId) {
            try {
                const response = await axios.get(`api/DeviceInfo/getDeviceInfoFromPcId/${productConfigId}`);
                setAllDevices(Array.isArray(response.data) ? response.data : [response.data]);
            } catch (error) {
                console.error("Error retrieving all devices: ", error);
            }
        }
    };

    const checkTypeAndFetchNozzleInfo = async () => {
        if (prodType && productConfigId) {
            try {
                if (prodType === 'BSU' || prodType === 'CF') {
                    const response = await axios.get(`api/NozzleMaster/nozzleMasterFromProductConfigId/${productConfigId}`);
                    const nozzleData = response.data;
                    const ids = nozzleData.map(item => item.nozzleId);
                    setNozzleIds(ids);
                    const nozzleProgrammingData = await Promise.all(nozzleData.map(async (nozzle) => {
                        const programmingResponse = await axios.get(`api/NozzleProgramming/getAllNpFromNozzleId/${nozzle.nozzleId}`);
                        return {
                            name: nozzle.nozzleArrangementName,
                            nozzleProgrammings: programmingResponse.data
                        };
                    }));
                    setNozzleProgrammingDataList(nozzleProgrammingData);
                }
            } catch (error) {
                console.error("Error handling the nozzle info: ", error);
            }
        }
    };

    useEffect(() => {
        fetchDeviceInfo();
    }, [productConfigId]);

    useEffect(() => {
        checkTypeAndFetchNozzleInfo();
    }, [prodType, productConfigId]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        setSelectedRow(null);
        setEditRowData(null);
    };

    const handleAddTab = () => {
        const newTabName = `Nozzle ${nozzleProgrammingDataList.length + 1}`;
        setNozzleProgrammingDataList([...nozzleProgrammingDataList, { name: newTabName, nozzleProgrammings: [] }]);
        setTabIndex(nozzleProgrammingDataList.length + 1); // Set to the new tab
    };

    const commonColumns = [
        { accessorKey: 'deviceName', header: 'Name' },
        { accessorKey: 'deviceType', header: 'Type' },
        { accessorKey: 'macAddress', header: 'MAC Address' },
        { accessorKey: 'internalIpAddress', header: 'Internal IP Address' },
        { accessorKey: 'portTcp', header: 'HTTP Port (TCP)' },
        { accessorKey: 'portUdp', header: 'HTTP Port (UDP)' },
        { accessorKey: 'internalRtsp', header: 'Port RTSP' },
        { accessorKey: 'externalHttp', header: 'External HTTP' },
        { accessorKey: 'externalRtsp', header: 'External RTSP' },
        { accessorKey: 'outboundPort', header: 'Outbound Port' }
    ];

    const getNozzleDataForTab = () => {
        if (tabIndex === 0) {
            return allDevices;
        }
        const selectedNozzle = nozzleProgrammingDataList[tabIndex - 1];
        return selectedNozzle.nozzleProgrammings;
    };

    const handleRowClick = (row) => {
        if (selectedRow === row.original) {
            setSelectedRow(null);
            setEditRowData(null);
        } else {
            setSelectedRow(row.original);
            setEditRowData(row.original);
        }
    };

    const handleEditButtonClick = () => {
        setEditModalOpen(true);
    };

    const renderTemplate = () => {
        switch (productType) {
          case 'BSU':
          case 'CF':
            return (
              <>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
                  <Tab label="General Devices" />
                  {nozzleProgrammingDataList.map((item, index) => (
                    <Tab key={index} label={item.name} />
                  ))}
                  {/* <AddNozzleMaster pcId={prodConfigId} onAddNozzle={handleAddTab} onSave={checkTypeAndFetchNozzleInfo} /> */}
                </Tabs>
                <MaterialReactTable
                  columns={commonColumns}
                  data={getNozzleDataForTab() ?? []}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => handleRowClick(row),
                    sx: {
                      cursor: 'pointer',
                      backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                    },
                  })}
                  muiTableContainerProps={{
                    sx: { maxHeight: '40vh', overflow: 'auto' },
                  }}
                />
              </>
            );
          default:
            return <Typography>Unknown Device Type</Typography>;
        }
      };

      return (
        <Grid
          container
          minWidth={'100%'}
          sx={{ marginBottom: '1%', marginTop: '-3%', padding: '5% 0', width: '100%', height: '100%', overflow: 'auto' }}
        >
          <Grid container spacing={2} justifyContent="flex-end" alignItems='center'>
            {selectedRow !== null && (
              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalOpen}
                >
                  Edit Selected {tabIndex === 0 ? 'Device' : 'Nozzle'}
                </Button>
              </Grid>
            )}
            <Grid item >
              <AddDevice
                productConfigId={prodConfigId}
                nozIds={nozzleIds}
                tabInd={tabIndex}
                allDevice={allDevices}
                onDeviceAdded={fetchDeviceInfo}
                onNozzleAdded={checkTypeAndFetchNozzleInfo}
              />
            </Grid>
          </Grid>
          {editRowData && (
            <EditRow
              open={editModalOpen}
              onClose={handleEditModalClose}
              rowInfo={editRowData}
              onDeviceRowUpdated={fetchDeviceInfo}
              onNozzleRowUpdated={checkTypeAndFetchNozzleInfo}
              tabInd={tabIndex}
            />
          )}
          <Grid item xs={12} flexGrow={1}>
            {renderTemplate()}
          </Grid>
        </Grid>
      );
    }


import { useEffect, useState } from "react";

// Msal imports
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { ErrorComponent } from "../components/ErrorComponent";

// Material-ui imports
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import { projectManageAxios as axios } from '../utils/AxiosUtility';

const EmployeesContent = () => {
    const [employeeList, setEmployeeList] = useState(null);
    const { result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...loginRequest,
        // redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
        redirectUri: '/',
    });

    useEffect(() => {
        if (!!employeeList) {
            // We already have the data, no need to call the API
            return;
        }

        if (!!error) {
            // Error occurred attempting to acquire a token, either handle the error or do nothing
            return;
        }

        if (result) {
            axios
                .get("api/Employees")
                .then((response) => {
                    setEmployeeList(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status !== 200) {
                        alert("Your access is not allowed.");
                    }
                });
        }
    }, [error, result, employeeList]);

    if (error) {
        return <ErrorComponent error={error} />;
    }

    return (
        <>
            {employeeList ? employeeList.map(employee =>
                <Paper>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Name" secondary={employee.firstName + " " + employee.lastName} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Email" secondary={employee.email} />
                        </ListItem>
                    </List>
                </Paper>
            ) : null}
        </>
    );
};

export function Employees() {
    return <EmployeesContent />
};
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import NetworkInfoTable from './NetworkInfo';
import InternalInfoTable from './InternalInfo';
import SiteInfoTable from './SiteInfo';
import ServiceLogTable from './ServiceLog';
import AirgasTable from './Airgas';

const gridItemProps = {
    xs: 4,
    container: true,
    justifyContent: "center",
};

export default function TopDeviceSection({id, prodConfigId, prodType}) {
    const [deviceId, setDeviceId] = useState(id);
    const [productType, setProductType] = useState(prodType);
    const [productConfigId, setProductConfigId] = useState(prodConfigId);

    useEffect(() => {
        setDeviceId(id);
        setProductType(prodType);
        setProductConfigId(prodConfigId);
    }, [id, prodConfigId, prodType]);

    function RenderCF() {
        return (
            <>
                <Grid item {...gridItemProps} sx={{marginTop: '1%', paddingRight: 2}}>
                    <Box sx={{ padding: 2, minHeight: '150px', width: '100%' }}>
                        <NetworkInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{marginTop: '1%', paddingRight: 2}}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <ServiceLogTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{marginTop: '1%', paddingRight: 2}}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <SiteInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', paddingRight: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <InternalInfoTable id={prodConfigId} deviceId={deviceId} />
                    </Box>
                </Grid>
            </>
        );
    };

    function RenderBSU() {
        return (
            <>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingRight: 2 }}>
                    <Box sx={{ padding: 2, minHeight: '150px', width: '100%' }}>
                        <NetworkInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2, paddingRight: 2 }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        border: '1px solid grey'
                    }}>
                        <ServiceLogTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2 }}>
                    <Box sx={{ 
                        padding: 2, 
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <SiteInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingRight: 2, marginLeft: '15%', paddingBottom: '1%' }}>
                    <Box sx={{ padding: 2, minHeight: '150px', marginTop: '2%', width: '100%' }}>
                        <InternalInfoTable id={prodConfigId} deviceId={deviceId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <AirgasTable pcId={prodConfigId} />
                    </Box>
                </Grid>
            </>
        );
    };

    function RenderOW() {
        return (
            <>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <NetworkInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <ServiceLogTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginTop: '1%', paddingLeft: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <SiteInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', paddingRight: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <InternalInfoTable id={prodConfigId} deviceId={deviceId} />
                    </Box>
                </Grid>
            </>
        );
    };

    function RenderED() {
        return (
            <>
                <Grid item {...gridItemProps} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', paddingLeft: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center'
                    }}>
                        <NetworkInfoTable id={prodConfigId} />
                    </Box>
                </Grid>
                <Grid item {...gridItemProps} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', paddingRight: 2, paddingBottom: '1%' }}>
                    <Box sx={{ 
                        padding: 2,
                        minHeight: '150px', 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <InternalInfoTable id={prodConfigId} deviceId={deviceId} />
                    </Box>
                </Grid>
            </>
        );
    };

    const renderTemplate = () => {
        switch (productType) {
            case 'CF':
                return RenderCF();
            case 'OW':
                return RenderOW();
            case 'BSU':
                return RenderBSU();
            case 'ED':
                return RenderED();
            default:
                return <Typography>Unknown Device Type</Typography>
        }
    };

    return renderTemplate();
};


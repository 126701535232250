import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, Select, MenuItem, Fade } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { DataGrid } from "@mui/x-data-grid";
import EditManLiftDetails from "./EditManLiftDetails";

export default function ManLiftDetails({projectId, isCoordinationPage}) {
    const [open, setOpen] = useState(false);
    const [liftTypeDetails, setLiftTypeDetails] = useState();
    const [liftType, setLiftType] = useState();
    const [liftTypeName, setLiftTypeName] = useState("Boom Lift");
    const [liftLength, setLiftLength] = useState(0);
    const [ liftQuantity, setLiftQuantity] = useState(0);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
      fetchData();
      // eslint-disable-next-line
    }, []);
  
    const fetchData = () => {
      const endpoint = `api/Manufacturing/listOfLifts/${projectId}`;
  
      axios
        .get(endpoint)
        .then((response) => {
          setLiftTypeDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          if (error.message) {
            alert(error.message);
          } else {
            alert("Error fetching lift type details.");
          }
        });
    };
  
    const saveLiftType = () => {
      const newLiftType = {
        projectId: projectId,
        liftTypeName: liftTypeName,
        liftQty: liftQuantity,
        liftLength: liftLength
      };

      if (searchParams.get("id")) {
            newLiftType.pmEdited = true;
        }
  
      setLoading(true);
  
      axios
        .post("/api/Manufacturing/addLiftType", newLiftType)
        .then(() => {
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error(error);
          alert(error.response?.data || "Error saving lift type. Please try again.");
        })
        .finally(() => {
          setLoading(false);
          fetchData();
        });
    };

    useEffect(() => {
      if (liftTypeDetails) {
        setLiftTypeName(liftTypeDetails.liftTypeName || "Boom Lift");
        setLiftQuantity(liftTypeDetails.liftQty || 0);
        setLiftLength(liftTypeDetails.liftLength || 0);
        // May need to add more here depending on what's being passed over
      }
    }, [liftTypeDetails]);

    const columns = [
      { field: "liftTypeId", headerName: "Lift Type Id", width: 150 },
      { field: "liftDetailId", headerName: "Lift Detail Id", width: 150 },
      { field: "liftTypeName", headerName: "Lift Type Name", width: 150 },
      { field: "liftQuantity", headerName: "Lift Quantity", width: 150 },
      { field: "liftLength", headerName: "Lift Length", width: 150 },
      { field: "pmEdited", headerName: "PM Edited", width: 150 },
  ];

  const rows = liftTypeDetails
      ? liftTypeDetails.map((item, index) => ({
          id: index,
          liftTypeId: item.liftTypeId || "",
          liftDetailId: item.liftDetailId || "",
          liftTypeName: item.liftTypeName || "",
          liftQuantity: item.liftQty || "",
          liftLength: item.liftLength || "",
          pmEdited: item.pmEdited || "",
      }))
      : [];

    const availableLifts = [
      {
        id: 0,
        type: "Boom Lift",
      },
      {
        id: 1,
        type: "Articulating Lift",
      },
    ]

    const handleRowDoubleClick = (liftType) => {
      setLiftType(liftType);
      setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
      setIsEditModalOpen(false);
      fetchData();
    }

    const openSnackbar = () => {
      setSnackbarOpen(true);
    }

    const handleLiftTypeName = (event) => {
      setLiftTypeName(event.target.value);
    };
  
    return (
      <>
        <Button onClick={handleOpen}>Lift Details</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 970,
              overflowY: "auto",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ position: "absolute", top: "10px", right: "10px" }}
              disabled={loading}
            >
              X
            </Button>

            <Typography
              variant="h6"
              component="h2"
              style={{ paddingBottom: "20px" }}
            >
              Lift Details
            </Typography>

            <Box>       
            <Select
              labelId="lift-label"
              id="lift-select"
              value={liftTypeName}
              label="Lift Type"
              onChange={handleLiftTypeName}
              sx={{ marginBottom: "15px", maxHeight: "45px" }}
            >
              {availableLifts.map((lift) => (
                <MenuItem key={lift.id} value={lift.type}>
                  {lift.type}
                </MenuItem>
              ))}
            </Select>
            </Box>

            <TextField
              label="Length"
              value={liftLength}
              onChange={(e) => setLiftLength(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />

            <TextField
              label="Quantity"
              value={liftQuantity}
              onChange={(e) => setLiftQuantity(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />

            <div style={{paddingBottom: "30px"}}>
            <DataGrid onRowDoubleClick={(params) => handleRowDoubleClick(params.row)} rows={rows} columns={columns} 
            initialState={{ pagination: { paginationModel: { pageSize: 100 } }, columns: {columnVisibilityModel: {liftTypeId: false, liftDetailId: false}} }}
            pageSizeOptions={[50, 100]}
            disableRowSelectionOnClick
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer"
              }
            }}
            />
            </div>

            <div
              style={{ position: "absolute", right: "10px" }}
            >
              {loading && <CircularProgress />}

              <Button
                style={{ color: "green" }}
                onClick={saveLiftType}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                closeAfterTransition
            >
                <Fade in={isEditModalOpen}>
                    <div>
                      <EditManLiftDetails liftType={liftType} onUpdateLiftType={handleCloseEditModal} openSnackbar={openSnackbar} isCoordinationPage={isCoordinationPage}  />
                    </div>
                </Fade>
            </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Lift Type saved!
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
  
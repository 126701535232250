import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { Grid, Tabs, Tab, Box, Stack } from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';
import ActivityFeed from "../../components/Notes/ActivityFeed";
import Statistics from "../../components/Statistics";
import Events from "../../components/Events/Events";
import PMTable from "../../components/ProjectManagement/PMTable";
import { Typography } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '10px', border: 1, borderRadius: '5px', borderColor: 'divider', minHeight: '70vh' }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ProjectManageTaskSection() {

  const [tabValue, setTabValue] = new useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const gridItemProps = {
    xs: 4,
    container: true,
    justifyContent: "center",
  };

  const borderStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: "1px",
    backgroundColor: 'black',
    margin: "0",
    height: "70%",
    transform: "translateY(25%)",
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Grid
          container
          style={{ paddingRight: "1%", paddingLeft: "1%", height: "100%" }}
        >
          <Grid item xs={12} style={{ height: "45%", paddingTop: "0.5%" }}>
            <Stack direction="row" alignItems="center">
              <Grid item {...gridItemProps} style={{ position: "relative" }}>
                <div style={{ ...borderStyle }}></div>
                <Statistics />
              </Grid>

              <Grid item {...gridItemProps} style={{ position: "relative" }}>
                <div style={{ ...borderStyle }}></div>
                <ActivityFeed />
              </Grid>

              <Grid item {...gridItemProps}>
                <Events />
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} style={{ minHeight: "55%", paddingTop: "0.5%", paddingBottom: "0.5%" }}>
            <Box sx={{ width: "100%", border: 1, borderRadius: '5px', borderColor: 'divider'  }}>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Onboarding" {...tabProps(0)} />
                    <Tab label="Site Prep" {...tabProps(1)} />
                    <Tab label="Coordination" {...tabProps(2)} />
                  </Tabs>
                </div>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                <PMTable
                  step="Onboarding"
                  tab={0}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <PMTable
                  step="SitePrep"
                  tab={1}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <PMTable
                  step="Coordination"
                  tab={2}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6">
          <center>Please sign-in to see your profile information.</center>
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}

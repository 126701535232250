import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, Select, MenuItem, Fade, InputAdornment } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import { DataGrid } from "@mui/x-data-grid";
import EditInvoice from "./EditInvoice";
import NumericInput from "../../../components/NumericInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';

export default function InvoicingDetails() {
    const [open, setOpen] = useState(false);
    const [invoiceType, setInvoiceType] = useState();
    const [invoiceClass, setInvoiceClass] = useState();
    // eslint-disable-next-line
    const [invoiceCompleted, setInvoiceCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const [invoiceDetails, setInvoiceDetails] = useState("");
    const [markup, setMarkup] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    const [invoice, setInvoice] = useState();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
      fetchData();
      // eslint-disable-next-line
    }, []);
  
    const fetchData = () => {
      const endpoint = `api/Coordination/listOfInvoices/${searchParams.get("id")}`;
  
      axios
        .get(endpoint)
        .then((response) => {
          setInvoiceDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          if (error.message) {
            alert(error.message);
          } else {
            alert("Error fetching invoice details.");
          }
        });
    };
  
    const saveInvoice = () => {
      const newInvoice = {
        projectId: searchParams.get("id"),
        invoiceType: invoiceType,
        class: invoiceClass,
        invoiceDescription: description,
        amount: amount,
        invoiceReference: referenceNumber
      };

      if (!amount) {
        alert("Error creating invoice: Enter an amount to create invoice.");
        return;
      }

      if (markup !== "") {
        newInvoice.markup = markup;
        
        const billableAmount = parseFloat(amount) + (parseFloat(markup) / 100) * parseFloat(amount);
        newInvoice.billableAmount = billableAmount.toFixed(2);
      }
  
      setLoading(true);
  
      axios
        .post("/api/Coordination/createInvoice", newInvoice)
        .then(() => {
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error(error);
          alert(error.response?.data || "Error saving invoice. Please try again.");
        })
        .finally(() => {
          setLoading(false);
          fetchData();
        });
    };

    useEffect(() => {
      if (invoiceDetails) {
        setInvoiceType(invoiceDetails.invoiceType || "Billable Expense");
        setInvoiceCompleted(invoiceDetails.invoiceCompleted || false);
        setInvoiceClass(invoiceDetails.class || "Other");
        setMarkup(invoiceDetails.markup || "");
        setDescription(invoiceDetails.invoiceDescription || "");
        setAmount(invoiceDetails.amount || "");
        setReferenceNumber(invoiceDetails.invoiceReference || "");
      }
    }, [invoiceDetails]);

    function CheckCell(params) {
      return (
        <Box sx={{ justifyContent: "center" }}>
          {params.value ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
        </Box>
      );
    }    

    const columns = [
      { field: "invoiceDetailId", headerName: "Invoice Detail Id", width: 150, hide: true },
      { field: "invoiceCompleted", headerName: "Item Complete", width: 150, renderCell: CheckCell },
      { field: "invoiceType", headerName: "Expense Type", width: 150 },
      { field: "invoiceClass", headerName: "Invoice Class", width: 150 },
      { field: "markup", headerName: "Markup %", width: 150 },
      { field: "description", headerName: "Description", width: 150 },
      {
        field: "amount",
        headerName: "Amount",
        width: 150,
        renderCell: (params) => (
          <div>
            {isNaN(parseFloat(params.value)) ? "" : parseFloat(params.value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        ),
      },
      {
        field: "billableAmount",
        headerName: "Billable Amount",
        width: 150,
        renderCell: (params) => (
          <div>
            {isNaN(parseFloat(params.value)) ? "" : parseFloat(params.value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        ),
      },
      { field: "invoiceReference", headerName: "Reference Number", width: 150 },
  ];

  const rows = invoiceDetails
    ? invoiceDetails.map((item, index) => {
        const row = {
          id: index,
          invoiceDetailId: item.invoiceDetailId || "",
          invoiceCompleted: item.invoiceCompleted ? true : false,
          invoiceType: item.invoiceType || "",
          invoiceClass: item.class || "",
          markup: item.markup || "",
          description: item.invoiceDescription || "",
          amount: item.amount || "",
          invoiceReference: item.invoiceReference || "",
        };

        if (item.invoiceType === "Billable Expense") {
          row.billableAmount = item.billableAmount || "";
        }

        return row;
      })
    : [];

    const availableExpenses = [
      {
        id: 0,
        type: "Billable Expense",
      },
      {
        id: 1,
        type: "Non Billable Expense",
      },
    ]

    const availableInvoiceClasses = [
      {
        id: 0,
        class: "Lift",
      },
      {
        id: 1,
        class: "Crane",
      },
      {
        id: 2,
        class: "Trucking",
      },
      {
        id: 3,
        class: "Shipping",
      },
      {
        id: 4,
        class: "Nitro",
      },
      {
        id: 5,
        class: "Permit Charges",
      },
      {
        id: 6,
        class: "Special Install",
      },
      {
        id: 7,
        class: "Remobilization",
      },
      {
        id: 8,
        class: "Change Order",
      },
      {
        id: 9,
        class: "Other",
      },
    ]

    const handleRowDoubleClick = (invoice) => {
      setInvoice(invoice);
      setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
      setIsEditModalOpen(false);
      fetchData();
    }

    const openSnackbar = () => {
      setSnackbarOpen(true);
    }

    const handleInvoiceType = (event) => {
      setInvoiceType(event.target.value);
    };

    const handleInvoiceClass = (event) => {
      setInvoiceClass(event.target.value);
    };
  
    return (
      <>
        <Button onClick={handleOpen}>Invoicing Details</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 970,
              height: "80vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ position: "absolute", top: "10px", right: "10px" }}
              disabled={loading}
            >
              X
            </Button>

            <Typography
              variant="h6"
              component="h2"
              style={{ paddingBottom: "20px" }}
            >
              Invoicing Details
            </Typography>

            <Box>       
            <Select
              labelId="billable-label"
              id="billable-select"
              value={invoiceType}
              label="Billable Type"
              onChange={handleInvoiceType}
              sx={{ marginBottom: "15px", maxHeight: "45px" }}
            >
              {availableExpenses.map((expense) => (
                <MenuItem key={expense.id} value={expense.type}>
                  {expense.type}
                </MenuItem>
              ))}
            </Select>
            </Box>

            <Box>
            {invoiceType === "Billable Expense" ? 
            <TextField
            label="Markup"
            style={{ marginBottom: "15px" }}
            value={markup}
            onChange={(e) => setMarkup(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          /> : null}
          </Box>
            
            <Box>
            <Select
              labelId="invoiceClass-label"
              id="invoiceClass-select"
              value={invoiceClass}
              label="Invoice Class"
              onChange={handleInvoiceClass}
              sx={{ marginBottom: "15px", maxHeight: "45px" }}
            >
              {availableInvoiceClasses.map((invoice) => (
                <MenuItem key={invoice.id} value={invoice.class}>
                  {invoice.class}
                </MenuItem>
              ))}
            </Select>
            </Box>

            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />

            <TextField
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              name="amount"
              id="amount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
                inputComponent: NumericInput,
              }}
              variant="outlined"
              sx={{ marginBottom: "20px" }}
            />

            <TextField
              type="tel"
              label="Invoice Reference Number"
              value={referenceNumber}
              onChange={(e) => setReferenceNumber(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />

            <div style={{paddingBottom: "30px"}}>
            <DataGrid onRowDoubleClick={(params) => handleRowDoubleClick(params.row)} rows={rows} columns={columns} 
            initialState={{ pagination: { paginationModel: { pageSize: 25 } }, columns: {columnVisibilityModel: {invoiceDetailId: false}} } }
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
              },
              "& .MuiDataGrid-row:hover": {
                  cursor: "pointer"
              }
          }}
            />
            </div>

            <div
              style={{ position: "absolute", right: "10px" }}
            >
              {loading && <CircularProgress />}

              <Button
                style={{ color: "green" }}
                onClick={saveInvoice}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          closeAfterTransition
        >
          <Fade in={isEditModalOpen}>
              <div>
                <EditInvoice invoice={invoice} onUpdateInvoice={handleCloseEditModal} openSnackbar={openSnackbar}  />
              </div>
          </Fade>
        </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Invoice saved!
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
  
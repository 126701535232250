import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, ToggleButtonGroup, ToggleButton, Select, MenuItem, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ManLiftDetails from "../../Manufacturing/ManLiftDetails";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

export default function LiftDetails({onSave}) {
  const [open, setOpen] = useState(false);
  const [vendorPrimaryContact, setVendorPrimaryContact] = useState("");
  const [vendorPrimaryContactPhone, setVendorPrimaryContactPhone] = useState("");
  const [vendorPrimaryContactEmail, setVendorPrimaryContactEmail] = useState("");
  const [vendorSecondaryContact, setVendorSecondaryContact] = useState("");
  const [vendorSecondaryContactPhone, setVendorSecondaryContactPhone] = useState("");
  const [vendorSecondaryContactEmail, setVendorSecondaryContactEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [liftsRequested, setLiftsRequested] = useState(false);
  const [liftsConfirmed, setLiftsConfirmed] = useState(false);
  const [liftCallOff, setLiftCallOff] = useState(false);
  const [dateOut, setDateOut] = useState(dayjs());
  const [dateOutConfirmation, setDateOutConfirmation] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [liftDetails, setLiftDetails] = useState("");

  useEffect(() => {
    fetchData();
    fetchVendors();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const endpoint = `api/Coordination/liftDetails/${searchParams.get("id")}`;

    axios
      .get(endpoint)
      .then((response) => {
        setLiftDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching lift details.");
        }
      });
  };

  const fetchVendors = () => {
    axios
      .get("api/Coordination/listOfVendors", {
        params: {
          vendorType: "lift",
        },
      })
      .then((response) => {
        setAvailableVendors(response.data);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendors.");
      });
  };

  const handleVendorChange = (event) => {
    const selectedVendorName = event.target.value;
  
    setSelectedVendor(selectedVendorName);
  
    let vendorContactsData = null;
  
    fetchVendorContacts(selectedVendorName)
      .then((response) => {
        vendorContactsData = response.data;
  
        setVendorPrimaryContact(vendorContactsData.vmPrimaryContact);
        setVendorPrimaryContactPhone(vendorContactsData.vmPrimaryContactPhone);
        setVendorPrimaryContactEmail(vendorContactsData.vmPrimaryEmail);
        setVendorSecondaryContact(vendorContactsData.vmSecondaryContact);
        setVendorSecondaryContactPhone(vendorContactsData.vmSecondaryContactPhone);
        setVendorSecondaryContactEmail(vendorContactsData.vmSecondaryEmail);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendor contacts.");
      });
  };
  
  const fetchVendorContacts = (vendorName) => {
    return axios.get("api/Coordination/vendorContacts", {
      params: {
        vendorName,
      },
    });
  };

  const handleLiftsRequested = (event, liftRequest) => {
    if (liftRequest !== null) {
      setLiftsRequested(liftRequest);
    }
  };

  const handleLiftsConfirmed = (event, liftConfirm) => {
    if (liftConfirm !== null) {
      setLiftsConfirmed(liftConfirm);
    }
  };

  const handleLiftCallOff = (event, liftCallOff) => {
    if (liftCallOff !== null) {
      setLiftCallOff(liftCallOff);
    }
  };

  const handleDateOut = (date) => {
    setDateOut(date);
  };

  const handleDateOutConfirmation = (event, dateOutConfirmation) => {
    if (dateOutConfirmation !== null) {
      setDateOutConfirmation(dateOutConfirmation);
    }
  };

  const saveLiftDetails = () => {
    const newLiftDetails = {
        ProjectId: searchParams.get("id"),
        VmName: selectedVendor,
        LiftRequested: liftsRequested,
        LiftConfirmed: liftsConfirmed,
        LiftCallOff: liftCallOff,
        DateOut: dayjs(dateOut),
        DateOutConfirmation: dateOutConfirmation,
        ConfirmationNumber: confirmationNumber,
      };
      
      if (dateOut !== null) {
        newLiftDetails.dateOut = dateOut;
      }

    setLoading(true);

    axios
      .post("/api/Coordination/createliftDetails", newLiftDetails)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving lift details. Please try again.");
      })
      .finally(() => {
        fetchData();
        setLoading(false);
        onSave();
        handleClose();
      });
  }

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  useEffect(() => {
    if (liftDetails) {
      setSelectedVendor(liftDetails.vmName);
      setVendorPrimaryContact(liftDetails.vmPrimaryContact || "");
      setVendorPrimaryContactPhone(liftDetails.vmPrimaryContactPhone || "");
      setVendorPrimaryContactEmail(liftDetails.vmPrimaryEmail || "");
      setVendorSecondaryContact(liftDetails.vmSecondaryContact || "");
      setVendorSecondaryContactPhone(liftDetails.vmSecondaryContactPhone || "");
      setVendorSecondaryContactEmail(liftDetails.vmSecondaryEmail || "");
      setLiftsRequested(liftDetails.liftRequested || false);
      setLiftsConfirmed(liftDetails.liftConfirmed || false);
      setLiftCallOff(liftDetails.liftCallOff || false);
      setDateOut(liftDetails.dateOut ? offsetDate(new Date(liftDetails.dateOut)) : null);
      setDateOutConfirmation(liftDetails.dateOutConfirmation || false);
      setConfirmationNumber(liftDetails.confirmationNumber || "");
    }
  }, [liftDetails]);

  return (
    <>
      <Button onClick={handleOpen}>Lift Details</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Lift Details
          </Typography>

          <Select
            labelId="vendor-label"
            id="vendor-select"
            value={selectedVendor || ""}
            onChange={handleVendorChange}
            label="Unit"
            sx={{ padding: '0px', maxHeight: "45px" }}
          >
            {availableVendors.map((vm) => (
              <MenuItem key={vm.vmId} value={vm.vmName}>
                {vm.vmName}
              </MenuItem>
            ))}
          </Select>

          <div style={{ paddingTop: "20px" }}>
            <div>
              <Typography><b>Primary Contact</b>: {vendorPrimaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorPrimaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorPrimaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <div>
              <Typography><b>Secondary Contact</b>: {vendorSecondaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorSecondaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorSecondaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" style={{ minWidth: "40%" }}>
            Lifts Requested
          </Typography>

          <ToggleButtonGroup
            color="primary"
            value={liftsRequested}
            exclusive
            onChange={handleLiftsRequested}
            aria-label="Lifts Requested"
            sx={{ paddingRight: "10%" }}
          >
            <ToggleButton color="success" value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <ManLiftDetails projectId={searchParams.get("id")} isCoordinationPage={true} />

          <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" style={{ minWidth: "40%" }}>
            Lifts Confirmed
          </Typography>

          <ToggleButtonGroup
            color="primary"
            value={liftsConfirmed}
            exclusive
            onChange={handleLiftsConfirmed}
            aria-label="Lifts Confirmed"
            sx={{ paddingRight: "10%" }}
          >
            <ToggleButton color="success" value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={handleDateOut} value={dayjs(dateOut)} label={"Date Out"} />
            </LocalizationProvider>
          </div>

          <div style={{ display: "flex", alignItems: "center", paddingBottom: "10px" }}>
          <Typography variant="h6" style={{ minWidth: "40%" }}>
            Date Out Confirmation
          </Typography>

          <ToggleButtonGroup
            color="primary"
            value={dateOutConfirmation}
            exclusive
            onChange={handleDateOutConfirmation}
            aria-label="Date Out Confirmation"
            sx={{ paddingRight: "10%" }}
          >
            <ToggleButton color="success" value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <div style={{ display: "flex", alignItems: "center", paddingBottom: "10px" }}>
          <Typography variant="h6" style={{ minWidth: "40%" }}>
            Lift Call Off
          </Typography>

          <ToggleButtonGroup
            color="primary"
            value={liftCallOff}
            exclusive
            onChange={handleLiftCallOff}
            aria-label="Lifts Call Off"
            sx={{ paddingRight: "10%" }}
          >
            <ToggleButton color="success" value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <TextField
            label="Confirmation Number"
            value={confirmationNumber}
            onChange={(e) => setConfirmationNumber(e.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveLiftDetails}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Lift details saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

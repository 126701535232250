import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';

export default function NitroDetails({onSave}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [poNumber, setPoNumber] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [cylinderQuantity, setCylinderQuantity] = useState();
  const [branchPhone, setBranchPhone] = useState();
  const [branchName, setBranchName] = useState();
  const [branchAddress, setBranchAddress] = useState();
  const [branchCity, setBranchCity] = useState();
  const [branchState, setBranchState] = useState();
  const [branchZip, setBranchZip] = useState();
  const [nitroDetails, setNitroDetails] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const endpoint = `api/Coordination/nitroDetails/${searchParams.get("id")}`;

    axios
      .get(endpoint)
      .then((response) => {
        setNitroDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching nitro details.");
        }
      });
  };

  const saveNitroDetails = () => {
    const newNitroDetails = {
        ProjectId: searchParams.get("id"),
        PoNumber: poNumber,
        ConfirmationNumber: confirmationNumber,
        AccountNumber: accountNumber,
        CylinderQuantity: cylinderQuantity,
        BranchPhone: branchPhone,
        BranchName: branchName,
        BranchAddress: branchAddress,
        BranchCity: branchCity,
        BranchState: branchState,
        BranchZip: branchZip
      };

    setLoading(true);

    axios
      .post("/api/Coordination/createnitroDetails", newNitroDetails)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving nitro details. Please try again.");
      })
      .finally(() => {
        fetchData();
        setLoading(false);
        onSave();
        handleClose();
      });
  }

  useEffect(() => {
    if (nitroDetails) {
      setPoNumber(nitroDetails.poNumber || "");
      setConfirmationNumber(nitroDetails.confirmationNumber || "");
      setAccountNumber(nitroDetails.accountNumber || "");
      setCylinderQuantity(nitroDetails.cylinderQuantity || "");
      setBranchPhone(nitroDetails.branchPhone || "");
      setBranchName(nitroDetails.branchName || "");
      setBranchAddress(nitroDetails.branchAddress || "");
      setBranchCity(nitroDetails.branchCity || "");
      setBranchState(nitroDetails.branchState || "");
      setBranchZip(nitroDetails.branchZip || "");
    }
  }, [nitroDetails]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button onClick={handleOpen}>Nitro Details</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Nitro Details
          </Typography>

          <div style={{ paddingTop: "20px" }}>
            <div>
              <Typography><b>Vendor</b>: Airgas</Typography>
              <Typography><b>Primary Contact</b>: Payton Hamlin</Typography>
              <Typography><b>Phone</b>: (855) 625-5285 ext 3679</Typography>
              <Typography><b>Email</b>: payton.hamlin@airgas.com</Typography>
            </div>
          </div>

          <Grid
              container
              rowSpacing={0}
              style={{ padding: "30px" }}
            >

            <Grid item {...gridItemProps}>
            <TextField
              label="PO Number"
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>
            
            <Grid item {...gridItemProps}>
            <TextField
              label="Confirmation Number"
              value={confirmationNumber}
              onChange={(e) => setConfirmationNumber(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Cylinder Quantity"
              value={cylinderQuantity}
              onChange={(e) => setCylinderQuantity(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Branch Phone"
              value={branchPhone}
              onChange={(e) => setBranchPhone(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Branch Name"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Branch Address"
              value={branchAddress}
              onChange={(e) => setBranchAddress(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Branch City"
              value={branchCity}
              onChange={(e) => setBranchCity(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Branch State"
              value={branchState}
              onChange={(e) => setBranchState(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>


            <Grid item {...gridItemProps}>
            <TextField
              label="Branch Zip"
              value={branchZip}
              onChange={(e) => setBranchZip(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveNitroDetails}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Nitro details saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

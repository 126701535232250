import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

function createData(id, name, type, quantityAvailable, description) {
  return {
    id,
    name,
    type,
    quantityAvailable,
    description,
    itemDetails: [
      {
        itemId: 'Item-123',
        vendor: 'Vendor X',
        something: 'Something 1',
      },
      {
        itemId: 'Item-456',
        vendor: 'Vendor Y',
        something: 'Something 2',
      },
    ],
  };
}

function CollapsibleRow(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const hoverColor = theme.palette.mode === 'dark' ? '#292929' : '#f0f0f0';

  const handleRowClick = () => {
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: hover ? hoverColor : 'inherit',
        }}
        onClick={handleRowClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <TableCell>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">{row.quantityAvailable}</TableCell>
        <TableCell align="right">{row.description}</TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Item Details
                </Typography>
                <Typography align='right'>
                </Typography>
                <Table size="small" aria-label="item-details">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item ID</TableCell>
                      <TableCell>Vendor</TableCell>
                      <TableCell>Something</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.itemDetails.map((item, index) => (
                      <TableRow key={index} onClick={(e) => e.stopPropagation()}>
                        <TableCell>{item.itemId}</TableCell>
                        <TableCell>{item.vendor}</TableCell>
                        <TableCell>{item.something}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

CollapsibleRow.propTypes = {
  row: PropTypes.shape({
    itemDetails: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        vendor: PropTypes.string.isRequired,
        something: PropTypes.string.isRequired,
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    quantityAvailable: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};


export default function CollapsibleTable() {
  // eslint-disable-next-line
  const [rows, setRows] = useState([
    createData('1', 'Item 1', 'Type A', 100, 'Description 1'),
    createData('2', 'Item 2', 'Type B', 200, 'Description 2'),
    createData('3', 'Item 3', 'Type C', 150, 'Description 3'),
    createData('4', 'Item 4', 'Type D', 50, 'Description 4'),
    createData('5', 'Item 5', 'Type E', 300, 'Description 5'),
  ]);

  const [selectedType, setSelectedType] = useState('');
  const [selectedSubType, setSelectedSubType] = useState('');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSubTypeChange = (event) => {
    setSelectedSubType(event.target.value);
  };

  const typeOptions = ['valve', 'pipe', 'bolts'];
  const subTypeOptions = ['mechanical valve', 'electronic valve'];

  return (
    <TableContainer component={Paper} style={{ paddingTop: "20px" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: 2, pr: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Search Part"
            defaultValue=""
            sx={{ mr: 1 }}
            style={{ width: '275px' }}
          />
          <TextField
            select
            label="Type"
            value={selectedType}
            onChange={handleTypeChange}
            sx={{ mr: 1 }}
            style={{ width: '275px' }}
          >
            {typeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Sub Type"
            value={selectedSubType}
            onChange={handleSubTypeChange}
            style={{ width: '275px' }}
          >
            {subTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box>
          <Button variant='outlined' style={{marginRight: "10px"}}>Add</Button>
          <Button variant='outlined'>Create</Button>
        </Box>
      </Box>

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Quantity Available</TableCell>
            <TableCell align="right">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <CollapsibleRow key={row.id} row={row}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


function generateRandomData() {
  const data = [];
  for (let i = 0; i < 7; i++) {
    data.push({
      bundleNumber: `${i + 1}`,
      workItemId: `${i + 1}`,
      deliveryDate: new Date().toLocaleDateString(),
      status: <CheckCircleIcon style={{ color: "green" }} />,
    });
  }
  return data;
}

const data = generateRandomData();

export default function WorkBundle() {
    return (
      <div>
        <Typography variant="h5" align="center" gutterBottom>
          Work Queue Bundles
        </Typography>
  
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Bundle Number</TableCell>
                <TableCell align="center">Work Item Id</TableCell>
                <TableCell align="center">Delivery Date</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
  
        <div style={{ overflowY: 'scroll', maxHeight: '200px' }}>
          <TableContainer>
            <Table>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" style={{width: "50px"}}>{row.bundleNumber}</TableCell>
                    <TableCell align="right" style={{width: "50px"}}>{row.workItemId}</TableCell>
                    <TableCell align="right" style={{width: "50px"}}>{row.deliveryDate}</TableCell>
                    <TableCell align="center" style={{width: "50px"}}>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
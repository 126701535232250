import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditInternalInfo from './EditInternalInfo';



export default function InternalInfoTable({id, deviceId}) {
  const borderStyle = { border: '1px solid black' };
  const [rowId, setRowId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [internalInfo, setInternalInfo] = useState({});
  const [programmer, setProgrammer] = useState('');
  const theme = useTheme();

  const fetchData = async () => {
    try {
      const internalInfoEndpoint = `api/InternalInfo/getDeviceLevelInternalInfo/${id}`;
      const response = await axios.get(internalInfoEndpoint);
      setInternalInfo(response.data);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error); 
    }
  };

  const fetchProgrammer = async () => {
    try {
      const programmerFromPcEndpoint = `api/ProductConfiguration/productConfig/${id}`;
      const response = await axios.get(programmerFromPcEndpoint);
      setProgrammer(response.data.programmer);

    }
    catch (error) {
      console.error("Error fetching programmer.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchProgrammer();
  }, [rowId]);
  
  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse' }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem', 
            backgroundColor: theme.palette.mode === 'light' ? '#D0D0D0' : 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'inherit',
          },
          '& .MuiTableRow-root': {
            height: '24px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap',
            textAlign: 'center', 
          },
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                <Typography variant='h6' gutterBottom>
                  Internal Information
                </Typography>
              {!isLoading && internalInfo && (
                <Box ml={2}>
                  <EditInternalInfo internInfo={internalInfo} onSaveProgrammer={fetchProgrammer} onSaveData={fetchData} prog={programmer} />
                </Box>
              )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>Programmer:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{programmer}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Build:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.siteBuild}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Ship Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.shipDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Install Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.installDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Monitoring Start Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.activationStartDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Central Station:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.centralStation}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Time Zone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{internalInfo.timeZone}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};




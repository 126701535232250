import { Grid, Paper, Stack, Typography } from "@mui/material";


export default function EventContent(props) {

  const eventStyle = {
    p: "12px",
    borderRadius: "20px",
    width: "100%"
  };


  return (
    <Paper variant="outlined" sx={{ ...eventStyle }}>
      <Typography
        variant="h6"
        align="center"
        component="div"
        style={{ padding: "2%", paddingTop: "2.5%" }}
      >
        <Grid container spacing={1} style={{ paddingTop: "0%" }}>
          <Grid item xs={5} style={{ display:"flex", justifyContent:"start", paddingTop: "0px" }}>
            <Typography variant="caption">
              <Stack>
                Project ID: {props.projectId}
              </Stack>
              <Stack>
                {props.date}
              </Stack>
              <Stack>
                {props.time}
              </Stack>
            </Typography>
          </Grid>

          <Grid item xs={1.5}></Grid>

          <Grid item xs={5.5} style={{ paddingTop: "0px", display:"flex", justifyContent:"end" }}>
            <Typography variant="caption" alignSelf="center">
              PM: {props.projectManager}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography variant="subtitle1" fontWeight="bold" alignSelf="flex-start">
              {props.eventType}: {props.projectName}
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ paddingTop: "5px", paddingLeft: "20px" }}>
            <Typography variant="caption">
              <Stack>
                {props.address} {props.city}
              </Stack>
              <Stack>
                {props.state} {props.zip}
              </Stack>
            </Typography>
          </Grid>

          <Grid item xs={4}></Grid>

          <Grid item xs={4}>
            <Typography variant="caption">
              <Stack>
                {props.siteContact}
              </Stack>
              <Stack>
                {props.contactPhone}
              </Stack>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Paper>
  );
}

import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { Grid, Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';
import WIPStats from "../../components/WIP/WIPStats";
import WIPMainTable from "../../components/WIP/WIPMainTable";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function WIPMainPage() {
    const [tabValue, setTabValue] = new useState(0);
    const [filterIds, setfilterIds] = new useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <AuthenticatedTemplate>
                <Grid container style={{ padding: "0.5%" }}>
                    <Grid item xs={12} style={{ padding: "1% 1% 0px 1%" }}>
                        <WIPStats filterIds={filterIds}/>
                    </Grid>

                    <Grid item xs={12} style={{ padding: "1%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="sorting tabs">
                                <Tab label="All" {...tabProps(0)} />
                                <Tab label="Pending Install" {...tabProps(1)} />
                                <Tab label="Installed" {...tabProps(2)} />
                                <Tab label="Activated" {...tabProps(3)} />
                                <Tab label="Monthly Activated" {...tabProps(4)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            <WIPMainTable
                                type="All"
                                onFilter={setfilterIds}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <WIPMainTable
                                type="Pending"
                                onFilter={setfilterIds}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <WIPMainTable
                                type="Installed"
                                onFilter={setfilterIds}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            <WIPMainTable
                                type="Activated"
                                onFilter={setfilterIds}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={4}>
                            <WIPMainTable
                                type="Monthly"
                                onFilter={setfilterIds}
                            />
                        </CustomTabPanel>
                    </Grid>
                </Grid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}

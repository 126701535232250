/* eslint-disable react/jsx-pascal-case */
import React, { useState, useMemo, useEffect } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Grid, Stack, FormControl, InputLabel, Select, MenuItem, Typography, Button, TextField, TableContainer } from '@mui/material';
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleGlobalFilterButton } from 'material-react-table';
// import CancelIcon from '@mui/icons-material/Cancel';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import LockIcon from '@mui/icons-material/Lock';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const placeholderData = [ 
    {
        id: 16,
        siteName: 'Site A',
        installDate: new Date(),
        address1: '123 Main St',
        address2: 'Suite 107',
        city: 'Atlanta',
        state: 'Georgia',
        zip: '37281',
        country: 'USA',
        centralStation: 'COPS',
        revenue: '100000',
        rmr: '1000',
        accountManager: 'Stan Goodman',
    },
    {
        id: 19,
        siteName: 'Site A',
        installDate: new Date(),
        address1: '123 Main St',
        address2: 'Suite 107',
        city: 'Atlanta',
        state: 'Georgia',
        zip: '37281',
        country: 'USA',
        centralStation: 'COPS',
        revenue: '100000',
        rmr: '1000',
        accountManager: 'Stan Goodman',
    },
    {
        id: 20,
        siteName: 'Site A',
        installDate: new Date(),
        address1: '123 Main St',
        address2: 'Suite 107',
        city: 'Atlanta',
        state: 'Georgia',
        zip: '37281',
        country: 'USA',
        centralStation: 'COPS',
        revenue: '100000',
        rmr: '1000',
        accountManager: 'Stan Goodman',
    },
    {
        id: 35,
        siteName: 'Site A',
        installDate: new Date(),
        address1: '123 Main St',
        address2: 'Suite 107',
        city: 'Atlanta',
        state: 'Georgia',
        zip: '37281',
        country: 'USA',
        centralStation: 'COPS',
        revenue: '100000',
        rmr: '1000',
        accountManager: 'Stan Goodman',
    },
    {
        id: 48,
        siteName: 'Site A',
        installDate: new Date(),
        address1: '123 Main St',
        address2: 'Suite 107',
        city: 'Atlanta',
        state: 'Georgia',
        zip: '37281',
        country: 'USA',
        centralStation: 'COPS',
        revenue: '100000',
        rmr: '1000',
        accountManager: 'Stan Goodman',
    },
];


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};
  

export default function SiteInfoMainTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [siteInfoData, setSiteInfoData] = useState({});
    const [sortConfig, setSortConfig] = useState({key: 'siteName', direction: 'asc'});

    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                Cell: ({ cell, row }) => (
                    <Button onClick={() => {
                        const siteData = encodeURIComponent(JSON.stringify(row.original));
                        navigate(`/Site/Overview?site=${siteData}`);
                    }}>
                        {cell.getValue()}
                    </Button>
                ),
            },
            { accessorKey: 'siteName', header: 'Site Name' },
            { accessorKey: 'address1', header: 'Address 1' },
            { accessorKey: 'address2', header: 'Address 2' },
            { accessorKey: 'city', header: 'City' },
            { accessorKey: 'state', header: 'State' },
            { accessorKey: 'zip', header: 'Zip' },
            { accessorKey: 'country', header: 'Country' },
            { accessorKey: 'centralStation', header: 'Central Station' },
            {
                accessorKey: 'installDate',
                header: 'Install Date',
                Cell: ({ cell }) => formatDate(cell.getValue()),
            },
            {
                accessorKey: 'revenue',
                header: 'Revenue',
                Cell: ({ cell }) => formatter.format(cell.getValue()),
            },
            { accessorKey: 'rmr', header: 'RMR' },
            { accessorKey: 'accountManager', header: 'Account Manager' },
        ],
        [navigate]
    );

    const data = useMemo(() => {
        if (!searchQuery) return placeholderData;
        return placeholderData.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableStickyHeader: true,
        enableColumnResizing: true,
        paginationDisplayMode: 'pages',
        onGlobalFilterChange: setGlobalFilter,
        layoutMode: 'grid',
        initialState: {
            density: 'compact',
            sorting: [{ id: 'installDate', desc: true }],
            pagination: { pageSize: 100, pageIndex: 0 },
        },
        state: { globalFilter },
        muiPaginationProps: {
            shape: 'rounded',
            rowsPerPageOptions: [50, 100, 200],
            showFirstButton: true,
            showLastButton: true,
        },
        muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    });

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '-8%', padding: '0 16px'}}>
            <TableContainer style={{maxHeight: '70vh', overflow: 'auto'}}>
                <MaterialReactTable table={table} />
            </TableContainer>
        </Box>
    );
};

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, ToggleButtonGroup, ToggleButton, Select, MenuItem, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";

export default function CoordinationCraneDetails({ onSave }) {
  const [open, setOpen] = useState(false);
  const [vendorPrimaryContact, setVendorPrimaryContact] = useState("");
  const [vendorPrimaryContactPhone, setVendorPrimaryContactPhone] = useState("");
  const [vendorPrimaryContactEmail, setVendorPrimaryContactEmail] = useState("");
  const [vendorSecondaryContact, setVendorSecondaryContact] = useState("");
  const [vendorSecondaryContactPhone, setVendorSecondaryContactPhone] = useState("");
  const [vendorSecondaryContactEmail, setVendorSecondaryContactEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [riggersNeeded, setRiggersNeeded] = useState(false);
  const [craneArrivalDate, setCraneArrivalDate] = useState(null);
  const [craneOrdered, setCraneOrdered] = useState(false);
  const [craneArrivalconfirmation, setCraneArrivalConfirmation] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [craneDetails, setCraneDetails] = useState();

  useEffect(() => {
    fetchData();
    fetchVendors();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const endpoint = `api/Coordination/craneDetails/${searchParams.get("id")}`;

    axios
      .get(endpoint)
      .then((response) => {
        setCraneDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching crane details.");
        }
      });
  };

  const fetchVendors = () => {
    axios
      .get("api/Coordination/listOfVendors", {
        params: {
          vendorType: "crane",
        },
      })
      .then((response) => {
        setAvailableVendors(response.data);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendors.");
      });
  };

  const handleVendorChange = (event) => {
    const selectedVendorName = event.target.value;
  
    setSelectedVendor(selectedVendorName);
  
    let vendorContactsData = null;
  
    fetchVendorContacts(selectedVendorName)
      .then((response) => {
        vendorContactsData = response.data;
  
        setVendorPrimaryContact(vendorContactsData.vmPrimaryContact);
        setVendorPrimaryContactPhone(vendorContactsData.vmPrimaryContactPhone);
        setVendorPrimaryContactEmail(vendorContactsData.vmPrimaryEmail);
        setVendorSecondaryContact(vendorContactsData.vmSecondaryContact);
        setVendorSecondaryContactPhone(vendorContactsData.vmSecondaryContactPhone);
        setVendorSecondaryContactEmail(vendorContactsData.vmSecondaryEmail);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendor contacts.");
      });
  };
  
  const fetchVendorContacts = (vendorName) => {
    return axios.get("api/Coordination/vendorContacts", {
      params: {
        vendorName,
      },
    });
  };

  const handleRiggersNeeded = (event, rigger) => {
    if (rigger !== null) {
      setRiggersNeeded(rigger);
    }
  };

  const handleCraneArrivalDate = (date) => {
    setCraneArrivalDate(date);
  };

  const handleCraneOrdered = (event, craneOrdered) => {
    if (craneOrdered !== null) {
      setCraneOrdered(craneOrdered);
    }
  };

  const handleCraneArrivalConfirmation = (event, craneArrivalConfirmation) => {
    if (craneArrivalConfirmation !== null) {
      setCraneArrivalConfirmation(craneArrivalConfirmation);
    }
  };

  const saveCraneDetails = () => {
    const newCraneDetails = {
        ProjectId: searchParams.get("id"),
        VmName: selectedVendor,
        RiggersNeeded: riggersNeeded,
        CraneArrivalDate: dayjs(craneArrivalDate),
        CraneOrdered: craneOrdered,
        CraneArrivalConfirmation: craneArrivalconfirmation,
        ConfirmationNumber: confirmationNumber,
      };
      
      if (craneArrivalDate !== null) {
        newCraneDetails.craneArrivalDate = dayjs(craneArrivalDate);
      }

    setLoading(true);

    axios
      .post("/api/Coordination/createcraneDetails", newCraneDetails)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving crane details. Please try again.");
      })
      .finally(() => {
        fetchData();
        setLoading(false);
        onSave();
        handleClose();
      });
  }

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  useEffect(() => {
    if (craneDetails) {
      setSelectedVendor(craneDetails.vmName);
      setVendorPrimaryContact(craneDetails.vmPrimaryContact || "");
      setVendorPrimaryContactPhone(craneDetails.vmPrimaryContactPhone || "");
      setVendorPrimaryContactEmail(craneDetails.vmPrimaryEmail || "");
      setVendorSecondaryContact(craneDetails.vmSecondaryContact || "");
      setVendorSecondaryContactPhone(craneDetails.vmSecondaryContactPhone || "");
      setVendorSecondaryContactEmail(craneDetails.vmSecondaryEmail || "");
      setRiggersNeeded(craneDetails.riggersNeeded || false);
      setCraneArrivalDate(craneDetails.craneArrivalDate ? offsetDate(new Date(craneDetails.craneArrivalDate)) : null);
      setCraneOrdered(craneDetails.craneOrdered || false);
      setCraneArrivalConfirmation(craneDetails.craneArrivalConfirmation || false);
      setConfirmationNumber(craneDetails.confirmationNumber || "");
    }
  }, [craneDetails]);

  return (
    <>
      <Button onClick={handleOpen}>Crane Details</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Crane Details
          </Typography>

          <Select
            labelId="vendor-label"
            id="vendor-select"
            value={selectedVendor || ""}
            onChange={handleVendorChange}
            label="Unit"
            sx={{ padding: "0px", maxHeight: "45px" }}
          >
            {availableVendors.map((vm) => (
              <MenuItem key={vm.vmId} value={vm.vmName}>
                {vm.vmName}
              </MenuItem>
            ))}
          </Select>

          <div style={{ paddingTop: "20px" }}>
            <div>
              <Typography><b>Primary Contact</b>: {vendorPrimaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorPrimaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorPrimaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <div>
              <Typography><b>Secondary Contact</b>: {vendorSecondaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorSecondaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorSecondaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" style={{ minWidth: "50%" }}>
            Riggers Needed
          </Typography>

          <ToggleButtonGroup
            color="primary"
            value={riggersNeeded}
            exclusive
            onChange={handleRiggersNeeded}
            aria-label="Riggers Needed"
            sx={{ paddingRight: "10%" }}
          >
            <ToggleButton color="success" value={true}>
              Yes
            </ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={handleCraneArrivalDate} value={dayjs(craneArrivalDate)} label={"Crane Arrival Date and Time"} />
          </LocalizationProvider>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" style={{ minWidth: "50%" }}>
            Crane Ordered
            </Typography>

          <ToggleButtonGroup
            color="primary"
            value={craneOrdered}
            exclusive
            onChange={handleCraneOrdered}
          >
            <ToggleButton color="success" value={true}>
              Yes
            </ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" style={{ minWidth: "50%" }}>
            Crane Arrival Confirmation
            </Typography>
          <ToggleButtonGroup
            color="primary"
            value={craneArrivalconfirmation}
            exclusive
            onChange={handleCraneArrivalConfirmation}
          >
            <ToggleButton color="success" value={true}>
              Yes
            </ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
          </div>

          <TextField
            id="confirmationNumber"
            label="Confirmation Number"
            fullWidth
            margin="normal"
            variant="outlined"
            value={confirmationNumber}
            onChange={(e) => setConfirmationNumber(e.target.value)}
            style={{paddingBottom: "30px"}}
          />

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveCraneDetails}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Crane details saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import EventContent from './EventContent';
import { projectManageAxios as axios } from "../../utils/AxiosUtility";

export default function Events() {
    const [upcomingEventData, setUpcomingEventData] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        axios.get('api/ProjectManagement/allUpcomingEvents')
            .then((response) => {
                setUpcomingEventData(response.data);
            })
            .catch((error) => {
                console.log(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching upcoming events.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
      }

    const getEventType = (event) => {
        let eventType = null;
        let eventTypeDate = null;

        if (event.siteVisitReq && !event.siteVisitComplete && event.siteVisitDate) {
            eventType = "Site Visit";
            eventTypeDate = event.siteVisitDate;
        }
        if (!event.siteKickoffCallCompleted && event.siteKickoffCallDate) {
            eventType = "Kickoff Call";
            eventTypeDate = event.siteKickoffCallDate;
        }
        if (!event.customerHandoffCallCompleted && event.customerHandoffCallDate) {
            eventType = "FR System Tutorial";
            eventTypeDate = event.customerHandoffCallDate;
        }
        if (!event.preInstallationConfirmation && event.siteContactCallDate) {
            eventType = "Preinstallation Call";
            eventTypeDate = event.siteContactCallDate;
        }
        
        const offsetEventDate = offsetDate(new Date(eventTypeDate));
        const formattedDate = offsetEventDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }) + ' ' + offsetEventDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });

        return { eventType, formattedDate };
    };

    const extractEventsFromResponse = (response) => {
        const obEvents = response.obEvents || [];
        const spEvents = response.spEvents || [];
        const scEvents = response.scEvents || [];
        return [...obEvents, ...spEvents, ...scEvents];
    };

    const filteredEvents = loading ? [] : extractEventsFromResponse(upcomingEventData);

    filteredEvents.sort((a, b) => {
        const dateA = new Date(getEventType(a).formattedDate);
        const dateB = new Date(getEventType(b).formattedDate);
        return dateA.getTime() - dateB.getTime();
    });

    return (
        <div>
            <Typography variant="h5" align="center" gutterBottom>
                Upcoming Events
            </Typography>
            <List style={{ maxHeight: "380px", overflow: "auto" }}>
                {loading ? (
                    <ListItem>
                        <Skeleton animation="wave" variant="rounded" width={500} height={200} />
                    </ListItem>
                ) : (
                    filteredEvents.map((event, index) => {
                        const { eventType, formattedDate } = getEventType(event.event);

                        if (eventType) {
                            return (
                                <ListItem key={index}>
                                    <EventContent
                                        date={formattedDate}
                                        eventType={eventType}
                                        projectId={event.event?.projectId}
                                        projectName={event.event?.csName}
                                        projectManager={event.event?.pmAssigned}
                                        siteContact={event.event?.csPrimaryContact}
                                        contactPhone={event.event?.csPrimaryContactPhone}
                                        address={event.event?.csAddress1}
                                        city={event.event?.csCity}
                                        state={event.event?.csState}
                                        zip={event.event?.csZip}
                                    />
                                </ListItem>
                            );
                        }
                        return <div key={index}></div>;
                    })
                )}
            </List>
        </div>
    );
}

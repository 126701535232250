import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import DeviceInfoMainTable from "../DeviceInfo/DeviceInfoMainTable";
import { ContentBoxWrapper } from "../../styles/theme";
import PropTypes from 'prop-types';
import { ClosedServiceTickets, OpenServiceTickets } from "../../components/ServiceStatusTabs";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export function SiteOverview(props) {
    const [tabValue, setTabValue] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const siteData = queryParams.get('site');

    const site = siteData ? JSON.parse(decodeURIComponent(siteData)) : null;


    const handleTabChange = (event) => {
        if (tabValue === 0) {
            setTabValue(1);
        }
        else {
            setTabValue(0);
        }
        // location.state.tab = newValue;
    };

    const gridItemProps = {
        xs: 4,
        container: true,
        justifyContent: "center",
    };

    return (
        <>
            <AuthenticatedTemplate>
                <Grid container rowSpacing={2} style={{ padding: "30px" }}>
                    <Grid item {...gridItemProps} sx={{ paddingRight: 2 }}>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', minHeight: '150px', width: '100%' }}>
                            <Typography variant="h6" align="center">Site Overview</Typography>
                        </Box>
                    </Grid>
                    <Grid item {...gridItemProps} sx={{ paddingLeft: 2 }}>
                        <ContentBoxWrapper elevation={2} sx={{minHeight: "100%"}}>
                            <Box sx={{ border: '1px solid #ccc'}}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                                    <Tabs value={tabValue} onChange={handleTabChange}>
                                        <Tab label="Open" {...tabProps(0)} />
                                        <Tab label="Closed" {...tabProps(1)} />
                                    </Tabs>
                                </div>
                            </Box>
                            <Box sx={{ border: '1px solid #ccc', marginTop: '3%'}}>
                                <CustomTabPanel value={tabValue} index={0}>
                                    <OpenServiceTickets />
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={1}>
                                    <ClosedServiceTickets />
                                </CustomTabPanel>
                            </Box>
                        </ContentBoxWrapper>  
                    </Grid>
                    <Grid item {...gridItemProps} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', minHeight: '150px', width: '100%' }}>
                            <Typography variant="h6" align="center">Site Activity (Alerts)</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: '-5%', marginBottom: '-12%' }}>
                        <Box sx={{width: "100%"}}>
                            <DeviceInfoMainTable />
                        </Box>
                    </Grid>

                    <Grid item {...gridItemProps} sx={{ paddingRight: 2 }}>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', minHeight: '350px', width: '100%' }}>
                            <Typography variant="h6" align="center">Projects</Typography>
                        </Box>
                    </Grid>
                    <Grid item {...gridItemProps} sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', minHeight: '350px', width: '100%' }}>
                            <Typography variant="h6" align="center">Contacts</Typography>
                        </Box>
                    </Grid>
                    <Grid item {...gridItemProps} sx={{ paddingLeft: 2 }}>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', minHeight: '350px', width: '100%' }}>
                            <Typography variant="h6" align="center">All Documents</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </AuthenticatedTemplate>
            
            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}


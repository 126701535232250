import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Select, TextField, Typography } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { projectManageAxios as axios } from "../../utils/AxiosUtility";
import DeleteManLiftDetails from "./DeleteManLiftDetails";

export default function EditManLiftDetails({ liftType, onUpdateLiftType, isCoordinationPage, openSnackbar }) {
    const [loading, setLoading] = useState(false);
    const [liftTypeName, setLiftTypeName] = useState("Boom Lift");
    const [liftLength, setLiftLength] = useState(0);
    const [ liftQuantity, setLiftQuantity] = useState(0);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const availableLiftTypes = [
        {
            id: 0,
            type: "Boom Lift",
        },
        {
            id: 1,
            type: "Articulating Lift",
        },
    ]

    useEffect(() => {
    if (liftType) {
        setLiftTypeName(liftType.liftTypeName || "Boom Lift");
        setLiftQuantity(liftType.liftQuantity || 0);
        setLiftLength(liftType.liftLength || 0);
    }
    // eslint-disable-next-line
}, [liftType]);


    const handleLiftTypeName = (event) => {
        setLiftTypeName(event.target.value);
    };

    const handleSave = () => {
        setLoading(true);
    
        const updatedLiftType = {
            liftTypeId: liftType.liftTypeId,
            liftTypeName: liftTypeName,
            liftQty: liftQuantity,
            liftLength: liftLength,
        };

        if (isCoordinationPage) {
            updatedLiftType.pmEdited = true;
        }
    
        axios
            .put('api/Manufacturing/updateLiftType', updatedLiftType)
            .then((response) => {
                onUpdateLiftType();
                openSnackbar();
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.data) {
                    alert(error.response.data);
                } else {
                    alert("Error updating lift type. Please try again.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };    

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Button
                    variant="outlined"
                    onClick={onUpdateLiftType}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    X
                </Button>

                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
                    Edit Lift Type
                </Typography>

                <Box>
                <Select
                    labelId="lift-label"
                    id="lift-select"
                    value={liftTypeName}
                    onChange={handleLiftTypeName}
                    label="Lift Type"
                    sx={{ marginBottom: "15px", maxHeight: "45px" }}
                >
                    {availableLiftTypes.map((lift) => (
                        <MenuItem key={lift.id} value={lift.type}>
                            {lift.type}
                        </MenuItem>
                    ))}
                </Select>
                </Box>

                <TextField
                    label="Length"
                    value={liftLength}
                    onChange={(e) => setLiftLength(e.target.value)}
                    style={{ width: "100%", marginBottom: "20px" }}
                />

                <TextField
                    label="Quantity"
                    value={liftQuantity}
                    onChange={(e) => setLiftQuantity(e.target.value)}
                    style={{ width: "100%", marginBottom: "80px" }}
                />

                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    {loading && <CircularProgress />}

                   <DeleteManLiftDetails liftTypeId={liftType.liftTypeId} onLiftTypeDeleted={onUpdateLiftType} />

                    <Button
                        style={{ color: "green" }}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </>
    );
}

import React from "react";
import { Typography, Container } from "@mui/material";

function PatchNotes() {
    const nestedBullets = [
      "Major Updates",
        [ 
            "Revamped WIP Accordion", 
            "Added new tab to WIP for Monthly Activation Lookup", 
            "Added Email notifications for Activation Complete events", 
            "Re-Designed database backend for build tracking allowing for simpler expansion and addition of products and processes in the future"
        ],  
        "Additional Updates",
        [ 
            "Added Central Station to WIP table",
            "Added Enclosure Serial # field to Build Task List",
            "Added Customer Name to Build List Table",
            "Added Site Name to Build List Table",
            "Added Build % Progress Bar to Build List Table",
            "Added 3rd option for Pad Install: “N/A”",
            "Added Account Manager to site info panel",
            "Added Central Station to site info panel",
            "Added Account Manager and Central Station to edit function of site info panel",
            "Added Account Manager and Central Station to Contract Received Pop Up",
            "Added Trucking Scheduled option to Project Site Coordination Tab",
            "Added HVAC to Build List Tasks for BSUs only",
            "Added Product Names (FR#) to Site Info Panel",
            "Added confirmation popup to Ready to Bill step in Project Site Coordination",
            "Added Fire Rover Stock account for building Stock Product"
        ],  
        "Bug Fixes",
        [ 
            "Fixed an issue where “Yes” would get locked when setting Ready to Bill",
            "Fixed an issue where removing Products from a project would delete the wrong build status",
            "Fixed individual Product build % complete calculation"
        ],  
        "Changes",
        [ 
            "Changed AC Wiring to 120 Volt in Build List Tasks",
            "Updated all tables throughout App to match WIP sorting functionality",
            "Changed Control Panel Wired step to Battery Bank in Build List Tasks for BSUs only"
        ],  
        "Known Issues",
        [ 
            "Total build % complete progress on WIP still not calculating properly",
            "Unable to assign stock products to projects.  Needs manual dev team intervention",
            "Unable to request new stock product builds.  Needs manual dev team intervention"
        ],  
    ]
    return (
    <>
    <Typography variant="h3" textAlign={"center"} style={{paddingTop: "50px", paddingBottom: "50px"}}>🔥 Release Notes 6/3/24</Typography>
    <ListComponent items={nestedBullets}/>
    </>
    )
  }
  
  const ListComponent = ({ items }) => (

    <Container maxWidth="md" style={{ paddingTop: "50px", paddingBottom: "50px" }}>

        <Typography component="ul" >
        {
        items.map((item, index) =>
          (typeof item === "string") ?
            <Typography key={index} component="li">{item}</Typography> :
            <ListComponent key={index} items={item} />)
         }
        </Typography>
    </Container>
    )
  
  export default PatchNotes;
  
import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerValue(props) {
    const [value, setValue] = React.useState(dayjs(props.value));
    
    const handleDateChange = (val) => {
        if (val === null) {
            setValue(null);
            props.onChange(null);
        } else {
            setValue(val);
            let saveDate = new Date(val);
            saveDate.setHours(12);
            props.onChange(saveDate);
        }
    };
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.label}
                value={value}
                disabled={props.disabled ?? false}
                onChange={handleDateChange}
                slotProps={{
                    field: { clearable: true },
                }}
                sx={{ padding: '0px', maxHeight: "45px" }}
            />
        </LocalizationProvider>
    );
}

import { useEffect, useState } from "react";
import { Grid, Box, Stack, Typography, Button, FormControl, InputLabel, Snackbar, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import moment from "moment";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { Authenticated, GetAuthUserName } from "../../components/AuthenticatedComponent";
import MuiAlert from '@mui/material/Alert';

import { TimeCell } from "../../components/TimeSheet/TimeCell";
import TimeTable from "../../components/TimeSheet/TimeTable";


function getCurrentWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 1
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 1));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return week number
    return weekNo;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

function createEmployeeData(userid, username, firstname, lastname, email, role, department, managerid, timesheets) {
    return {
        userid,
        username,
        firstname,
        lastname,
        email,
        role,
        department,
        managerid,
        timesheets
    }
}

function createWeeklyData(weeknumber, userid, year, username, firstname, lastname, email, role, department, startdate, standardhours, overtime, pto, holidayhours, vacationhours, sickhours, otherhours, totalhours, status, currenttimelog) {
    return {
        weeknumber,
        userid,
        year,
        username,
        firstname,
        lastname,
        email,
        role,
        department,
        startdate,
        standardhours,
        overtime,
        pto,
        holidayhours,
        vacationhours,
        sickhours,
        otherhours,
        totalhours,
        status,
        currenttimelog
    }
}

function createTimeLogData(timesheetid, userid, date, clockin, clockout, hourtype, hoursworked, day, description, weekof) {
    return {
        timesheetid,
        userid,
        date,
        clockin,
        clockout,
        hourtype,
        hoursworked,
        day,
        description,
        weekof
    }
}

const timeTypes = [
    { type: "Regular Work" },
    { type: "Overtime" },
    { type: "PTO" },
    { type: "Holiday" },
    { type: "Vacation" },
    { type: "Sick" },
    { type: "Other" },
    { type: "Total" }
]

const weekDays = [
    { day: "Sunday" },
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
];

let totalHours = {
    "Regular Work": 0,
    "Overtime": 0,
    "PTO": 0,
    "Holiday": 0,
    "Vacation": 0,
    "Sick": 0,
    "Other": 0,
    "Total": 0
}

export function TimeSheet() {

    const [currentWeek, setCurrentWeek] = useState(getWeekRange(new Date()).from);
    const [currentWeekNumber, setCurrentWeekNumber] = useState(getCurrentWeekNumber(new Date()));
    const [year, setYear] = useState(new Date().getFullYear());
    const [status, setStatus] = useState("Not Submitted");
    const [worker, setWorker] = useState("");
    const [userId, setUserId] = useState("");
    const [inProgTimeLog, setInProgTimeLog] = useState(null);
    const [subordinateIdsList, setSubordinateIdsList] = useState([]);
    const [clockInTime, setClockInTime] = useState(null);
    const [clockOutTime, setClockOutTime] = useState(null);
    const [timeSheetListener, setTimeSheetListener] = useState(0);
    const [approveSubmission, setApproveSubmission] = useState(false);
    const [submitForApproval, setSubmitForApproval] = useState(false);
    const [revokeSubmission, setRevokeSubmission] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");
    const [confirmAction, setConfirmAction] = useState(null);
    const [approved, setApproved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [weeklyList, setWeeklyList] = useState([]);
    const [timeLogsList, setTimeLogsList] = useState([]);
    const [currentTimeLogs, setCurrentTimeLogs] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [weekTotalHours, setWeekTotalHours] = useState(totalHours);
    const [timesArray, setTimesArray] = useState([
        {
            day: "Sunday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Monday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Tuesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Wednesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Thursday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Friday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Saturday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
    ]);

    const managerRole = ["Service_TimeSheet_Manager", "SI_TimeSheet_Manager"];
    const employeeRole = ["Service_TimeSheet_User", "SI_TimeSheet_User"];
    const payrollRole = ["Payroll_Processor"]
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const hourtypes = ["Regular Work", "Overtime", "PTO", "Holiday", "Vacation", "Sick", "Other"]

    const today = new Date();

    useEffect(() => {
        setLoading(true);
        const endpoint = 'api/Employee/employee';
        axios.get(endpoint)
            .then((response) => {
                loadEmployeeList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const fetchWeeklyData = () => {
        setLoading(true);
        const endpoint = 'api/Timesheet/timeSheetWeekly';
        axios.get(endpoint)
            .then((response) => {
                loadWeeklyList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchWeeklyData();
        // eslint-disable-next-line
    }, [submitted]);

    const fetchTimeSheetData = () => {
        setLoading(true);
        const endpoint = 'api/Timesheet/timeSheet';
        axios.get(endpoint)
            .then((response) => {
                loadTimeLogList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchTimeSheetData();
        // eslint-disable-next-line
    }, []);

    const handleTransferData = (log) => {
        if (new Date(log.date) >= currentWeek && new Date(log.date) <= getWeekRange(new Date(currentWeek)).to && log.userid === worker) {
            timeLogArray[weekdays.indexOf(log.day)].hours[hourtypes.indexOf(log.hourtype)].time += log.hoursworked;
            timeLogArray[weekdays.indexOf(log.day)].hours[7].time += log.hoursworked;
            totalHours[log.hourtype] += Number(log.hoursworked.toFixed(2));
        }
    }

    const handleSetCurrentTimeLogs = (allTimeLogs) => {
        let rows = []
        for (let log of allTimeLogs) {
            if (new Date(log.date) >= currentWeek && new Date(log.date) <= getWeekRange(new Date(currentWeek)).to && log.userid === worker) {
                rows.push(log);
            }
        };
        setCurrentTimeLogs(rows);
    }

    const handleUpdateTimeSheetCells = () => {
        totalHours = {
            "Regular Work": 0,
            "Overtime": 0,
            "PTO": 0,
            "Holiday": 0,
            "Vacation": 0,
            "Sick": 0,
            "Other": 0,
            "Total": 0
        }
        timeLogsList.map((log) => (
            handleTransferData(log)
        ));
        totalHours["Total"] = totalHours["Regular Work"] + totalHours["Overtime"] + totalHours["PTO"] + totalHours["Holiday"] + totalHours["Sick"] + totalHours["Other"];
        setWeekTotalHours(totalHours);
        setTimesArray(timeLogArray);
    }

    const handleSetInProgTimeLog = () => {
        timeLogsList.map((log) => (
            log.userid === worker && new Date(log.weekof).getTime() === offsetDate(currentWeek).getTime() && log.clockin !== null && log.clockout === null ? 
            setInProgTimeLog(log.timesheetid) : setInProgTimeLog(null)
        ));
    }

    useEffect(() => {
        timeLogsList.map((log) => (
            log.timesheetid === inProgTimeLog ? setClockInTime(dayjs(log.date.toISOString().slice(0, 11) + log.clockin + ".000Z")) : setClockInTime(null)
        ));
    }, [inProgTimeLog])

    useEffect(() => {
        handleSetCurrentTimeLogs(timeLogsList);
        handleUpdateTimeSheetCells();
        handleSetInProgTimeLog();
        // eslint-disable-next-line
    }, [currentWeek, worker, timeLogsList])

    const loadEmployeeList = (employees) => {

        let newRows = [];
        let id = 0;
        let subordinateList = [];
        if (employees.length > 0) {
            employees.forEach(e => {
                if (GetAuthUserName() === e.userName) {
                    setUserId(e.userId);
                    setWorker(e.userId);
                    id = e.userId;
                }
            })
            employees.forEach(e => {
                if (id === e.userId || id === e.managerId || Authenticated(payrollRole)) {
                    newRows.push(createEmployeeData(
                        e.userId,
                        e.userName,
                        e.firstName,
                        e.lastName,
                        e.email,
                        e.role,
                        e.department,
                        e.managerId,
                        e.timeSheets
                    ))
                }
                if (id === e.managerId || (Authenticated(payrollRole) && id !== e.userId)) {
                    subordinateList.push(e.userId);
                }
            })
        }
        setEmployeeList(newRows);
        setSubordinateIdsList(subordinateList);
    }

    const loadWeeklyList = (timeSheets) => {

        let newRows = [];
        if (timeSheets.length > 0) {
            timeSheets.forEach(ts => {
                const offsetDate = (date) => {
                    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
                }
                newRows.push(createWeeklyData(
                    ts.weekNumber,
                    ts.empUserId,
                    ts.tsYear,
                    ts.userName,
                    ts.firstName,
                    ts.lastName,
                    ts.email,
                    ts.role,
                    ts.department,
                    ts.weekStartDate ? offsetDate(new Date(ts.weekStartDate)) : null,
                    ts.regularHours,
                    ts.overtimeHours,
                    ts.ptoHours,
                    ts.holidayHours,
                    ts.vacationHours,
                    ts.sickHours,
                    ts.otherHours,
                    ts.totalHoursWorked,
                    ts.tsStatus,
                    ts.currentTsId
                ))
            })
        }
        setWeeklyList(newRows);
    }

    const loadTimeLogList = (timeLogs) => {
        let newRows = [];
        if (timeLogs.length > 0) {
            timeLogs.forEach(log => {
                const offsetDate = (date) => {
                    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
                }
                newRows.push(createTimeLogData(
                    log.timesheetId,
                    log.empUserId,
                    log.timesheetDate ? offsetDate(new Date(log.timesheetDate)) : null,
                    log.clockIn,
                    log.clockOut,
                    log.hourType,
                    log.hoursWorked,
                    log.tsDay,
                    log.tsDescription,
                    log.weekOf,
                ))
            })
        }
        setTimeLogsList(newRows);
    }

    const handleWeeklyAttributes = (startDate, weekNumber, year, status) => {
        setCurrentWeek(startDate);
        setCurrentWeekNumber(weekNumber);
        setYear(year);
        setStatus(status);
    }

    const handleTimeSheetSelect = (week, employeeId, year) => { //Set the employee, weeknumber, current week, year, and status
        employeeList.map((e) => (
            e.userid === employeeId ? setWorker(e.userid) : null
        ))
        weeklyList.map((ts) => (
            ts.weeknumber === week & ts.userid === employeeId & ts.year === year ? handleWeeklyAttributes(ts.startdate, ts.weeknumber, ts.year, ts.status) : null
        ))
    }

    const handleSetArrayTimes = (val, weekday, type, description = null) => {
        timeLogArray[weekdays.indexOf(weekday)].hours[hourtypes.indexOf(type)].time = val;
    }

    let timeLogArray = [
        {
            day: "Sunday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Monday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Tuesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Wednesday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Thursday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Friday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
        {
            day: "Saturday",
            hours: [
                { type: "Regular Work", time: 0 },
                { type: "Overtime", time: 0 },
                { type: "PTO", time: 0 },
                { type: "Holiday", time: 0 },
                { type: "Vacation", time: 0 },
                { type: "Sick", time: 0 },
                { type: "Other", time: 0 },
                { type: "Total", time: 0 }
            ]
        },
    ];


    useEffect(() => {
        setClockOutTime(null);
    }, [clockInTime]);

    useEffect(() => {
        if (status === "Pending Approval") {
            setApproveSubmission(false);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            setApproved(false);
            setSubmitted(true);
        }
        if (status === "Not Submitted") {
            setApproveSubmission(false);
            setSubmitForApproval(false);
            setRevokeSubmission(true);
            setSubmitted(false);
            setApproved(false);
        }
        if (status === "Approved") {
            setApproveSubmission(true);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            setApproved(true);
            setSubmitted(true);
        }
    }, [currentWeek, worker, status])

    const handleClockInNow = (event) => {
        const now = new Date();
        setClockInTime(dayjs(now.getTime()));
    }

    const handleClockOutNow = (event) => {
        const now = new Date();
        setClockOutTime(dayjs(now.getTime()));
    }

    const handleWorkerSelect = (event) => {
        setWorker(event.target.value);
    }

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 1
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 1));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return week number
        return weekNo;
    }

    const handleDateChange = (val) => {
        var d = getWeekRange(new Date(val)).from;
        setCurrentWeek(d);
        setCurrentWeekNumber(getWeekNumber(d));
        setYear(currentWeek.getFullYear());
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
    }

    const handleUpdateTimeTable = () => {

        setLoading(true);

        const employee = employeeList.find(o => o.userid === worker);
        if (employee) {
            const newWeeklyTimesheet = {
                weekNumber: getWeekNumber(currentWeek),
                empUserId: worker,
                tsYear: currentWeek.getFullYear(),
                userName: employee.username,
                firstName: employee.firstname,
                lastName: employee.lastname,
                email: employee.email,
                role: employee.role,
                department: employee.department,
                weekStartDate: currentWeek.toISOString().substring(0, 10),
                weekEndDate: offsetDate(getWeekRange(currentWeek).to).toISOString().substring(0, 10),
                regularHours: totalHours["Regular Work"],
                overtimeHours: totalHours["Overtime"],
                ptoHours: totalHours["PTO"],
                holidayHours: totalHours["Holiday"],
                vacationHours: totalHours["Vacation"],
                sickHours: totalHours["Sick"],
                otherHours: totalHours["Other"],
                totalHoursWorked: totalHours["Total"],
                tsStatus: "Not Submitted",
                currentTsId: inProgTimeLog
            }

            axios
                .put("/api/TimeSheet/updateTimeSheetWeekly", newWeeklyTimesheet)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error creating Timesheet. Please try again.");
                })
                .finally(() => {
                    setTimeSheetListener(timeSheetListener + 1);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        handleUpdateTimeTable();
        // eslint-disable-next-line
    }, [weekTotalHours])

    const handleSaveCurrentWeek = () => {
        setLoading(true);

        const handleCleanUp = () => {
            if (clockInTime && clockOutTime) {
                setClockInTime(null);
                setClockOutTime(null);
                setInProgTimeLog(null);
            }
        }

        const handleCalcHours = () => {
            let timeAdjustment = 0;
            if (clockInTime && clockOutTime) {
                if (((clockOutTime - clockInTime) / 3600000) < 0) {
                    timeAdjustment = 24;
                }
                return (((clockOutTime - clockInTime) / 3600000 + timeAdjustment).toFixed(2));
            }
            return (0);
        }

        if (currentWeek.getTime() === getWeekRange(today).from.getTime()) {

            let tempTimeLog = {};

            if (inProgTimeLog === null) {
                tempTimeLog = {
                    empUserId: worker,
                    timesheetDate: new Date().toISOString().substring(0, 10),
                    clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                    clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                    hourType: "Regular Work",
                    hoursWorked: handleCalcHours(),
                    tsDescription: "-",
                    tsDay: weekDays[new Date().getDay()].day,
                    weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                };
            }
            else {
                tempTimeLog = {
                    timesheetId: inProgTimeLog,
                    empUserId: worker,
                    timesheetDate: new Date().toISOString().substring(0, 10),
                    clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                    clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                    hourType: "Regular Work",
                    hoursWorked: handleCalcHours(),
                    tsDescription: "-",
                    tsDay: weekDays[new Date().getDay()].day,
                    weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                };
            }

            const newTimeLog = tempTimeLog

            axios
                .put("/api/TimeSheet/updateTimeLog", newTimeLog)
                .then(() => {
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                })
                .finally(() => {
                    fetchTimeSheetData();
                    handleUpdateTimeTable();
                    setTimeSheetListener(timeSheetListener + 1);
                    handleCleanUp();
                    setLoading(false);
                });
        }
    }

    const handleSaveHours = (event) => {
        setLoading(true);

        const getDateFromDay = (day) => {
            const i = weekdays.indexOf(day);
            var weekStart = new Date(currentWeek);
            return moment(weekStart).add(i, 'days')
        }

        handleSaveCurrentWeek();

        // Save timeLogArray as new time logs or update existing logs
        let flag = false;
        for (let arr of timeLogArray) {
            for (let tempLog of arr.hours) {
                if (tempLog.time > 0 && tempLog.type !== "Regular Work") {
                    for (let log of currentTimeLogs) {
                        if (log.hourtype === tempLog.type && log.day === arr.day) {
                            //update time log
                            const updatedTimeLog = {
                                timesheetId: log.timesheetid,
                                clockIn: log.clockin,
                                clockOut: log.clockout,
                                hoursWorked: tempLog.time,
                                tsDescription: log.description,
                            };

                            axios
                                .post('api/Timesheet/editTimeLog', updatedTimeLog)
                                .then((response) => {
                                    setSnackbarOpen(true);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    if (error.response && error.response.data) {
                                        alert(error.response.data);
                                    } else {
                                        alert("Error updating Time Log. Please try again.");
                                    }
                                })
                                .finally(() => {
                                    fetchTimeSheetData();
                                    setLoading(false);
                                });
                            flag = true;
                            break
                        }
                    }
                    if (!flag) {
                        // create time log
                        const newTimeLog = {
                            empUserId: worker,
                            timesheetDate: getDateFromDay(arr.day).toISOString().substring(0, 10),
                            clockIn: null,
                            clockOut: null,
                            hourType: tempLog.type,
                            hoursWorked: tempLog.time,
                            tsDescription: "-",
                            tsDay: arr.day,
                            weekOf: new Date(currentWeek).toISOString().substring(0, 10)
                        };

                        axios
                            .post("/api/TimeSheet/createTimeLog", newTimeLog)
                            .then(() => {
                                setSnackbarOpen(true);

                            })
                            .catch((error) => {
                                console.error(error);
                                alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                            })
                            .finally(() => {
                                fetchTimeSheetData();
                                setLoading(false);
                            });
                    }
                    flag = false;
                }
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setConfirmAction(null);
    };

    const handleConfirmDialog = () => {
        if (confirmAction === 'readyToSubmit') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
            };

            setSubmitForApproval(true);
            setRevokeSubmission(false);
            axios.post("/api/TimeSheet/submitTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error saving note. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitted(true);
                    fetchWeeklyData();
                });

        } else if (confirmAction === 'revokeSubmission') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
            };

            setApproveSubmission(false);
            setSubmitForApproval(false);
            setRevokeSubmission(true);
            axios.post("/api/TimeSheet/revokeTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error saving note. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setApproved(false);
                    setSubmitted(false);
                    fetchWeeklyData();
                });

        } else if (confirmAction === 'readyToApprove') {

            const updatedStatus = {
                empUserId: worker,
                weekStartDate: new Date(currentWeek).toISOString().split('T')[0],
            };

            setApproveSubmission(true);
            setSubmitForApproval(true);
            setRevokeSubmission(false);
            axios.post("/api/TimeSheet/approveTimeSheet", updatedStatus)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                    alert(error.response?.data || "Error saving note. Please try again.");
                })
                .finally(() => {
                    setLoading(false);
                    setApproved(true);
                    setSubmitted(true);
                    fetchWeeklyData();
                });

        }
        setOpenDialog(false);
        setConfirmAction(null);
    };

    const handleSubmitForApproval = (event) => {
        setDialogTitle("Submit Time Sheet for Approval?");
        setDialogText("Further edits will not be possible unless submission is revoked.");
        setConfirmAction('readyToSubmit');
        setOpenDialog(true);
    };

    const handleRevokeSubmission = (event) => {
        setDialogTitle("Revoke Submission for Approval?");
        setDialogText("Time sheet will be withdrawn from approval process and become editable again.");
        setConfirmAction('revokeSubmission');
        setOpenDialog(true);
    };

    const handleApproval = (event) => {
        setDialogTitle("Approve Timesheet?");
        setDialogText("Further edits will not be possible unless Appoval is revoked.");
        setConfirmAction('readyToApprove');
        setOpenDialog(true);
    };

    return (
        <Grid container style={{ padding: "1%", height: "100%" }}>
            <Grid container direction={"row"}> {/* Weekly Time Sheet */}
                <Grid item sx={{ width: "20%", paddingRight: "0.5%" }}>
                    <Stack direction="row" alignItems="center">
                        <Stack direction="row" width="60%" marginBottom={"10px"}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <SingleInputDateRangeField
                                    label="WEEK OF"
                                    value={[dayjs(currentWeek), dayjs(getWeekRange(currentWeek).to)]}
                                    readOnly
                                    format="MM/DD/YY"
                                />
                            </LocalizationProvider>
                        </Stack>
                        <Stack direction="row" width="0%" marginBottom={"10px"}>
                            <Box width={0}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dayjs(currentWeek)}
                                        onChange={handleDateChange}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                paddingY: 2,
                                                paddingRight: 2.5,
                                                "& .MuiInputBase-input": {
                                                    padding: 0,
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack width="100%" marginBottom={"10px"}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="worker-label">EMPLOYEE</InputLabel>
                            <Select
                                label="EMPLOYEE"
                                labelId="worker-label"
                                id="worker-select"
                                value={worker}
                                onChange={handleWorkerSelect}
                            >
                                {employeeList.map((worker) => (
                                    <MenuItem key={worker.userid} value={worker.userid}>
                                        {`${worker.firstname} ${worker.lastname}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction="row" width={"100%"} marginBottom={"10px"}>
                        <Stack width="60%">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClockInNow}
                                disabled={(currentWeek.getTime() !== getWeekRange(today).from.getTime()) || worker === ""}
                                style={{ height: '55px' }}
                            >
                                Clock In
                            </Button>
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Clock In Time"
                                value={clockInTime}
                                onChange={setClockInTime}
                                disabled={currentWeek.getTime() !== getWeekRange(today).from.getTime()}
                            />
                        </LocalizationProvider>

                    </Stack>
                    <Stack direction="row" width={"100%"} marginBottom={"10px"}>
                        <Stack width={"60%"}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClockOutNow}
                                disabled={!clockInTime}
                                style={{ height: "55px" }}
                            >
                                Clock Out
                            </Button>
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Clock Out Time"
                                value={clockOutTime}
                                onChange={setClockOutTime}
                                disabled={!clockInTime}
                            />
                        </LocalizationProvider>
                    </Stack>

                    <Stack>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={submitForApproval}
                            onClick={(event) => {
                                handleSaveHours(event);
                            }}
                            style={{ marginBottom: "10px" }}
                        >
                            Save Changes
                        </Button>

                        {Authenticated(employeeRole)
                            ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={submitForApproval}
                                        value={submitForApproval}
                                        onClick={(event) => {
                                            handleSubmitForApproval(event);
                                        }}
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Submit for Approval
                                    </Button>

                                    {submitForApproval
                                        ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            value={revokeSubmission}
                                            onClick={(event) => {
                                                handleRevokeSubmission(event);
                                            }}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            Revoke Submission
                                        </Button>
                                        :
                                        null
                                    }
                                </>
                            ) : (
                                null
                            )}

                        {Authenticated(managerRole)
                            ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={approveSubmission}
                                        value={approveSubmission}
                                        onClick={(event) => {
                                            handleApproval(event);
                                        }}
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Approve
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!submitForApproval}
                                        value={revokeSubmission}
                                        onClick={(event) => {
                                            handleRevokeSubmission(event);
                                        }}
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Decline
                                    </Button>
                                </>
                            ) : (
                                null
                            )}
                    </Stack>
                </Grid>

                <Grid container sx={{ width: "80%", paddingLeft: "0.5%" }}>
                    <Grid container flexDirection={"row"}>
                        <Stack flex={1} />
                        <Stack flex={7} direction={"row"} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                            {weekDays.map((item, index) => (
                                <Stack direction="row" flex={1} margin={"15px"} key={index}>
                                    <Typography >{item.day}, {new Date(dayjs(currentWeek).add(weekDays.findIndex(d => d.day === item.day), 'day')).getDate()}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack flex={1} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                            <Typography fontWeight={"bold"} margin={"15px"}>Total</Typography>
                        </Stack>
                    </Grid>

                    <Grid container flexDirection={"row"} >
                        <Stack flex={1} alignItems={"flex-end"}>
                            {timeTypes.map((item, index) => (
                                <Box direction="column" paddingRight={"10%"} key={index}>
                                    {item.type === "Total" ? (
                                        <Typography fontWeight="bold" sx={{ marginY: "16.5px", }}>{item.type}</Typography>
                                    ) : (
                                        <>
                                            <Typography sx={{ marginY: "16.5px", }}>{item.type}</Typography>
                                        </>

                                    )}
                                </Box>
                            ))}
                        </Stack>

                        {timesArray.map((item1, index) => (
                            <Stack flex={1} key={index}>
                                {item1.hours.map((item, index) => (
                                    <Box direction="column" key={index} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                                        {item.type === "Total" ? (
                                            <Typography fontWeight="bold" sx={{ margin: "15px" }}>{Number(item.time.toFixed(2))}</Typography>
                                        ) : (
                                            <TimeCell
                                                day={item1.day}
                                                type={item.type}
                                                time={item.time}
                                                employeeId={worker}
                                                weekOf={currentWeek}
                                                setTime={handleSetArrayTimes}
                                                updateRow={fetchTimeSheetData}
                                                denyChanges={submitForApproval}
                                            />
                                        )}
                                    </Box>
                                ))}
                            </Stack>
                        ))}

                        <Stack flex={1}>
                            {Object.entries(weekTotalHours).map((key, value) => (
                                <Box direction="column" key={value} sx={{ border: 1, borderRadius: '5px', borderColor: 'divider' }}>
                                    <Typography sx={{ margin: "15px", fontWeight: "bold" }} key={value}>{Number(key[1].toFixed(2))}</Typography>
                                </Box>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "1%" }}>
                <TimeTable
                    setSheet={handleTimeSheetSelect}
                    currentWeekNumber={currentWeekNumber}
                    year={year}
                    currentUserId={userId}
                    subordinateIdsList={subordinateIdsList}
                    employeeId={worker}
                    submitted={submitted}
                    approved={approved}
                    listener={timeSheetListener}
                    updateSheet={fetchTimeSheetData}
                    denyChanges={submitForApproval}
                />
            </Grid>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogText}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleConfirmDialog} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Time sheet saved!
                </MuiAlert>
            </Snackbar>
        </Grid>
    );
}
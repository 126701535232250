import React, { useState, useEffect } from "react";
import { Box, Button, Stack, TextField, Typography, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import dayjs from "dayjs";
import MuiAlert from '@mui/material/Alert';

import DeleteTimeSheetLog from "./DeleteTimeSheetLog"

export default function EditTimeSheetLog({ timeLog, onUpdateTimeSheetLog, updateSheet }) {

  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [description, setDescription] = useState("");
  const [hours, setHours] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (timeLog) {
        setClockInTime(formatClockTimes(timeLog.clockIn));
        setClockOutTime(formatClockTimes(timeLog.clockOut));
        setDescription(timeLog.description);
        setHours(timeLog.hours);
    }
    // eslint-disable-next-line
  }, [timeLog]);

  const formatClockTimes = (time) => {
    if (time === "-") {
      return null;
    }
    const i = timeLog.date.split("/");
    const t = time.split(" ")[1];
    const hs = time.split(" ")[0].split(":");
    var hour = Number(hs[0])
    if (t === "PM") {
      hour += 12;
    }
    return dayjs((i[2]+"-"+i[0]+"-"+i[1]+" "+hour.toString()+":"+hs[1]));
  }

  const handleCalcHours = () => {
    let timeAdjustment = 0;
    if (clockInTime && clockOutTime) {
        if (((clockOutTime - clockInTime) / 3600000) < 0) {
            timeAdjustment = 24;
        }
        return (((clockOutTime - clockInTime) / 3600000 + timeAdjustment).toFixed(2));
    }
    return (0);
  }

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSave = () => {
    setLoading(true);

    const updatedTimeLog = {
        timesheetId: timeLog.id,
        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
        clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
        hoursWorked: timeLog.hourType === "Regular Work" ? handleCalcHours() : hours,
        tsDescription: description ? description : "-",
    };

    axios
        .post('api/Timesheet/editTimeLog', updatedTimeLog)
        .then((response) => {
          onUpdateTimeSheetLog();
            setSnackbarOpen(true);
        })
        .catch((error) => {
            console.log(error);
            if (error.response && error.response.data) {
                alert(error.response.data);
            } else {
                alert("Error updating Time Log. Please try again.");
            }
        })
        .finally(() => {
            setLoading(false);
            updateSheet();
            onUpdateTimeSheetLog();
        });
  }; 

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 375,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Button
        variant="outlined"
        onClick={onUpdateTimeSheetLog}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        X
      </Button>

      <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
        Edit Time Entry
      </Typography>

      {timeLog.hourType === "Regular Work" ? (
        <>
          <Stack width={"100%"} marginBottom={"10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Clock In Time"
                value={clockInTime}
                onChange={setClockInTime}
              />
            </LocalizationProvider> 
          </Stack>

          <Stack width={"100%"} marginBottom={"10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Clock Out Time"
                value={clockOutTime}
                onChange={setClockOutTime}
                disabled={!clockInTime}
              />
            </LocalizationProvider>
          </Stack>
        </>
      ) : (
        <Stack width={"100%"} marginBottom={"10px"}>
          <TextField
            value={hours}
            label="Input Hours"
            placeholder={0}
            onChange={(e) => setHours((e.target.value)*1)}
            InputProps={{
              type: "number",
              sx: {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none'
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield'
                    },
                  }
            }}
            inputProps={{maxLength: 5}}
          />
        </Stack>
      )}

      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ width: "100%", marginBottom: "20px" }}
      />

      <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        
      <DeleteTimeSheetLog timeLogId={timeLog.id} onTimeSheetLogDeleted={onUpdateTimeSheetLog} updateSheet={updateSheet}/>

        <Button
          style={{ color: "green", marginTop: "10px" }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Time log saved!
          </MuiAlert>
      </Snackbar>
    </Box>
  );
}
import React, { useState } from "react";
import { Box, Modal, Stack, TextField, Button, Typography, Alert, Snackbar } from "@mui/material";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonsStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
};

export function StockModal({ refresh }) {
    const [open, setOpen] = React.useState(false);
    const [risers, setRisers] = useState(1);
    const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
    const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);

    const handleSnackbarSuccessClose = () => setSnackbarSuccessOpen(false);
    const handleSnackbarErrorClose = () => setSnackbarErrorOpen(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const handleRiser = (event) => {
        if (event.target.value < 1) {
            setRisers(1);
            return;
        }
        if (event.target.value > 3) {
            setRisers(3);
            return;
        }
        setRisers(event.target.value);
    };

    const handleSubmit = () => {
        let unitToSave = {
            productType: "BSU",
            productId: 0,
            projectId: 761,
            productConfigurations: [{
                productConfigurationId: 0,
                productId: 0,
                riserCount: risers,
                panelCount: 0,
                coldWeatherPackage: false,
                mastOption: false,
                conveyorStop: false,
                viewingStation: 0,
                powerType: null,
                riserIndicatorLight: false,
                pointToPoint: false,
                multiPoint: null,
                thermalCameraCount: 0,
                flameDetectorCount: 0,
                smokeDetectorCount: 0,
                ebuttonCount: 0,
                fdConnects: false,
                interconnect: false,
                international: false,
                subPanel: null,
                _4gPrimary: false,
                f500Count: 0
            }],
            zones: []
        };

        console.log(unitToSave);
        axios.post("/api/Manufacturing/addStockProduct", unitToSave)
            .then((response) => {
                setSnackbarSuccessOpen(true);
                refresh();
            })
            .catch((error) => {
                console.log(error);
                setSnackbarSuccessOpen(true);
            });

        setOpen(false);
    }

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Add Unit</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>
                        Please set the Riser count for the stock unit.
                    </Typography>
                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: { min: 1, max: 3 }
                            }}
                            label="Riser Count"
                            value={risers}
                            onChange={handleRiser}
                            sx={{ mt: 2, paddingRight: "1%", width: "33%" }}
                        />
                    </Stack>

                    <div style={buttonsStyle}>
                        <Button style={{ color: "green" }} onClick={handleSubmit}>Add</Button>
                        <Button variant="contained" onClick={handleClose}>Close</Button>
                    </div>

                </Box>
            </Modal>
            <Snackbar open={snackbarSuccessOpen} autoHideDuration={3000} onClose={handleSnackbarSuccessClose}>
                <Alert onClose={handleSnackbarSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Stock product Added!
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarErrorOpen} autoHideDuration={3000} onClose={handleSnackbarErrorClose}>
                <Alert onClose={handleSnackbarErrorClose} severity="error" sx={{ width: '100%' }}>
                    An error occured.
                </Alert>
            </Snackbar>
        </>
    );
}
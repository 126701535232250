import React, { useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, Snackbar, Stack, Tab, Tabs, Typography, ToggleButtonGroup, TextField, Button, ToggleButton } from '@mui/material';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import LockIcon from '@mui/icons-material/Lock';
import MuiAlert from '@mui/material/Alert';
import ProgressCell from "../../components/ProgressCell";

function createData(projid, productid, devicename, bayNo, cmName, csName, devicetype, installdate, shipDateConfirmed, shipdate, buildProgress, zonecount, detectonly, riser, cold, mast, vs, tc, fc, sc, intl, stats) {
  if (projid === 761) {
    projid = "None"
  }
  return {
    projid,
    productid,
    devicename,
    bayNo,
    cmName,
    csName,
    devicetype,
    installdate,
    shipDateConfirmed,
    shipdate,
    buildProgress,
    zonecount,
    detectonly,
    riser,
    cold,
    mast,
    vs,
    tc,
    fc,
    sc,
    intl,
    stats,
  };
}

const buttonsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  margin: "1%",
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '10px', border: 1, borderRadius: '5px', borderColor: 'divider', minHeight: '25vh' }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CollapsibleRow(props) {
  const { row } = props;
  const [stats, setStats] = useState(props.row.stats);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = new useState(row.devicetype === 'BSU' || row.devicetype === 'OW' ? 0 : 1);


  const availableMans = [
    {
      id: 0,
      name: "Jack",
    },
    {
      id: 1,
      name: "Virgil",
    },
    {
      id: 2,
      name: "Carl",
    },
    {
      id: 3,
      name: "Jeremy",
    },
    {
      id: 4,
      name: "Peter",
    },
    {
      id: 5,
      name: "Eric",
    },
  ]


  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValChange = (type) => (event, val) => {
    if (['boxSerialNumber', 'enclosureSerialNumber', 'panelSerialNumber', 'testedBy'].includes(type)) {
      row.stats[type] = event.target.value;
      let newstats = { ...stats };
      newstats[type] = event.target.value;
      setStats(newstats);
    }
    else if (val !== null || val !== undefined) {
      row.stats[type] = val;
      let newstats = { ...stats };
      newstats[type] = val;
      setStats(newstats);
    }
  }

  const handleSubmit = () => {
    axios.post("/api/Manufacturing/updateStatus", stats)
      .then((response) => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log(error);
      
        if (error.response) {
          alert(error.response.data);
        } else {
          alert("Error retreiving data. \n");
        }
      });
  }

  return (
    <>
      {stats ? (
        <Box sx={{ minWidth: '90vw', height: "100%", paddingLeft: "1%" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Unit" disabled={row.devicetype === 'ED' || row.devicetype === 'CF'} {...tabProps(0)} />
              <Tab label="Low Voltage" {...tabProps(1)} />
              <Tab label="Tank" {...tabProps(2)} />
            </Tabs>
          </div>
          <CustomTabPanel value={tabValue} index={0}>
            {row.devicetype === 'OW' ?
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ ml: 1, width: "100%" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Trailer Ready
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.trailerReady}
                      exclusive
                      onChange={handleValChange("trailerReady")}
                      aria-label="TrailerReady"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Mast Installed
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.mastInstalled}
                      exclusive
                      onChange={handleValChange("mastInstalled")}
                      aria-label="MastInstalled"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Battery Box Installed
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.batteryBoxInstalled}
                      exclusive
                      onChange={handleValChange("batteryBoxInstalled")}
                      aria-label="batteryBoxInstalled"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Solar Array Built
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.solarArrayBuilt}
                      exclusive
                      onChange={handleValChange("solarArrayBuilt")}
                      aria-label="SolarArrayBuilt"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ mt: 1, ml: 1, width: "100%" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Junction Box Installed
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.junctionBoxInstalled}
                      exclusive
                      onChange={handleValChange("junctionBoxInstalled")}
                      aria-label="junctionBoxInstalled"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Enclosure Install
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.enclosureInstall}
                      exclusive
                      onChange={handleValChange("enclosureInstall")}
                      aria-label="EnclosureInstall"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Cameras
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.cameras}
                      exclusive
                      onChange={handleValChange("cameras")}
                      aria-label="Cameras"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>
              </>
              :
              row.devicetype === 'BSU' ?
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ ml: 1, width: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "25%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "40%" }}
                      >
                        Box Serial #
                      </Typography>

                      <TextField
                        label="Box Serial #"
                        name="serialNum"
                        value={stats.boxSerialNumber ?? ""}
                        style={{ flexGrow: "1", minWidth: "50%" }}
                        onChange={handleValChange("boxSerialNumber")}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "25%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "150%", marginRight: "5%", minWidth: "40%" }}
                      >
                        Enclosure Serial #
                      </Typography>

                      <TextField
                        label="Enclosure Serial #"
                        name="serialNum2"
                        value={stats.enclosureSerialNumber ?? ""}
                        style={{ flexGrow: "1", marginLeft: "5%", minWidth: "50%" }}
                        onChange={handleValChange("enclosureSerialNumber")}
                      />
                    </Stack>

                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 1, ml: 1, width: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Tank Paint
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.tankPaint}
                        exclusive
                        onChange={handleValChange("tankPaint")}
                        aria-label="TankPaint"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Tank Plumbing
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.tankPlumbing}
                        exclusive
                        onChange={handleValChange("tankPlumbing")}
                        aria-label="TankPlumbing"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Tank Install
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.tankInstall}
                        exclusive
                        onChange={handleValChange("tankInstall")}
                        aria-label="TankInstall"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Vent
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.vent}
                        exclusive
                        onChange={handleValChange("vent")}
                        aria-label="Vent"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Enclosure Install
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.enclosureInstall}
                        exclusive
                        onChange={handleValChange("enclosureInstall")}
                        aria-label="EnclosureInstall"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 1, ml: 1, width: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Breaker Panel
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.breakerPanel}
                        exclusive
                        onChange={handleValChange("breakerPanel")}
                        aria-label="BreakerPanel"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        120 Volt
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats._120Volt}
                        exclusive
                        onChange={handleValChange("_120Volt")}
                        aria-label="_120Volt"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Track Install
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.trackInstall}
                        exclusive
                        onChange={handleValChange("trackInstall")}
                        aria-label="TrackInstalled"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Cameras
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.cameras}
                        exclusive
                        onChange={handleValChange("cameras")}
                        aria-label="Cameras"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Heater
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.heater}
                        exclusive
                        onChange={handleValChange("heater")}
                        aria-label="Heater"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 1, ml: 1, width: "100%" }}
                  >

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        HVAC
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.hvac}
                        exclusive
                        onChange={handleValChange("hvac")}
                        aria-label="Hvac"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        LV Wiring
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.lvWiring}
                        exclusive
                        onChange={handleValChange("lvWiring")}
                        aria-label="LVWiring"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Box Pack
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.boxPack}
                        exclusive
                        onChange={handleValChange("boxPack")}
                        aria-label="Box Pack"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ minWidth: "20%" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ minWidth: "50%", paddingRight: "10%" }}
                      >
                        Final Validation
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={stats.finalValidation}
                        exclusive
                        onChange={handleValChange("finalValidation")}
                        aria-label="finalValidation"
                        style={{ flexGrow: "1", minWidth: "50%" }}
                      >
                        <ToggleButton color="success" value={true}>
                          Yes
                        </ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  </Stack>
                </>
                :
                null
            }
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            {row.devicetype === 'BSU' ?
              <Stack
                direction="row"
                alignItems="center"
                sx={{ ml: 1, width: "100%" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ mb: 1, minWidth: "25%" }}
                >
                  <Typography
                    variant="body1"
                    style={{ minWidth: "40%" }}
                  >
                    Panel Serial #
                  </Typography>

                  <TextField
                    label="Panel Serial #"
                    name="panelNum"
                    value={stats.panelSerialNumber ?? ""}
                    style={{ flexGrow: "1", minWidth: "50%" }}
                    onChange={handleValChange("panelSerialNumber")}
                  />
                </Stack>
              </Stack>
              : null}
            <Stack
              direction="row"
              alignItems="center"
              sx={{ ml: 1, width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: "50%", paddingRight: "10%" }}
                >
                  Control Panel Built
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={stats.controlPanelBuilt}
                  exclusive
                  onChange={handleValChange("controlPanelBuilt")}
                  aria-label="ControlPanelBuilt"
                  style={{ flexGrow: "1", minWidth: "50%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              {row.devicetype === 'BSU' || row.devicetype === 'OW' ?
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Battery Bank Installed
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.batteryBankInstalled}
                      exclusive
                      onChange={handleValChange("batteryBankInstalled")}
                      aria-label="BatteryBankInstalled"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "3%" }}
                    >
                      Control Panel Install
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.controlPanelInstall3Option}
                      exclusive
                      onChange={handleValChange("controlPanelInstall3Option")}
                      aria-label="ControlPanelInstall3Option"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={"yes"}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={"no"}>No</ToggleButton>
                      <ToggleButton color="warning" value={"wip"}>WIP</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </>
                :
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Enclosure Built
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.enclosureBuilt}
                      exclusive
                      onChange={handleValChange("enclosureBuilt")}
                      aria-label="EnclosureBuilt"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Enclosure Shipped
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.enclosureShipped}
                      exclusive
                      onChange={handleValChange("enclosureShipped")}
                      aria-label="EnclosureShipped"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </>}
            </Stack>

            {row.devicetype === 'OW' ?
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ mt: 1, ml: 1, width: "100%" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Control Panel Wired
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.controlPanelWiring}
                      exclusive
                      onChange={handleValChange("controlPanelWiring")}
                      aria-label="ControlPanelWiring"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: "20%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{ minWidth: "50%", paddingRight: "10%" }}
                    >
                      Mast Terminated
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={stats.mastTerminated}
                      exclusive
                      onChange={handleValChange("mastTerminated")}
                      aria-label="MastTerminated"
                      style={{ flexGrow: "1", minWidth: "50%" }}
                    >
                      <ToggleButton color="success" value={true}>
                        Yes
                      </ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>
              </>
              : null}

            <Stack
              direction="row"
              alignItems="center"
              sx={{ mt: 1, ml: 1, width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: "50%", paddingRight: "10%" }}
                >
                  Ready for Programming
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={stats.readyForProgramming}
                  exclusive
                  onChange={handleValChange("readyForProgramming")}
                  aria-label="ReadyForProgramming"
                  style={{ flexGrow: "1", minWidth: "50%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: "50%", paddingRight: "10%" }}
                >
                  Programming Complete
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={stats.programmingComplete}
                  exclusive
                  onChange={handleValChange("programmingComplete")}
                  aria-label="ProgrammingComplete"
                  style={{ flexGrow: "1", minWidth: "50%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: "50%", paddingRight: "10%" }}
                >
                  Testing Complete
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={stats.testingComplete}
                  exclusive
                  onChange={handleValChange("testingComplete")}
                  aria-label="TestingComplete"
                  style={{ flexGrow: "1", minWidth: "50%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: '35%' }}
                >
                  Tested By
                </Typography>

                <FormControl sx={{ width: "40%" }}>
                  <InputLabel id="testedBy-label">Tested By</InputLabel>
                  <Select
                    labelId="testedBy-label"
                    id="testedBy-select"
                    value={stats.testedBy ?? ""}
                    label="Tested By"
                    onChange={handleValChange("testedBy")}
                    sx={{ padding: '0px', maxHeight: "45px" }}
                  >
                    {availableMans.map((person) => (
                      <MenuItem key={person.id} value={person.name}>
                        {person.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ minWidth: "20%" }}
              >
                <Typography
                  variant="body1"
                  style={{ minWidth: "50%", paddingRight: "10%" }}
                >
                  Ready to Ship
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={stats.readyToShip}
                  exclusive
                  onChange={handleValChange("readyToShip")}
                  aria-label="readyToShip"
                  style={{ flexGrow: "1", minWidth: "50%" }}
                >
                  <ToggleButton color="success" value={true}>
                    Yes
                  </ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={2}>
            <Typography>Currently unavailable.</Typography>
          </CustomTabPanel>

          <div style={buttonsStyle}>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Box >
      ) : (
        <Box style={{ justifyContent: "center", height: "100px", minWidth: "90vw" }}>
          <Stack direction="row" alignItems="center">
            <Typography>Missing Stats.</Typography>
          </Stack>
        </Box>
      )
      }
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Build Status saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default function BuildList() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  //eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [availableBays, setAvailableBays] = useState([]);

  const allBays = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    setLoading(true);
    const endpoint = 'api/Manufacturing/products';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        const endpoint = 'api/Manufacturing/getBayNumbers';
        axios.get(endpoint)
          .then((response) => {
            if (response.data) {
              setAvailableBays(response.data.sort());
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const customSort = (a, b) => {
    if (a === "None") {
      return -1;
    }
    else if (b === "None") {
      return 1;
    }
    else {
      return parseInt(a) - parseInt(b);
    }
  };

  const openBays = availableBays
    .map((bay, index) => ({
      id: index,
      bay: bay
    }))
    .sort((a, b) => customSort(a.bay, b.bay));

  const loadRows = (products) => {
    let newRows = [];
    if (products.length > 0) {

      products.forEach(product => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
        }

        newRows.push(createData(
          product.projectId,
          product.productId,
          product.productName,
          product.bayNo,
          product.cmName,
          product.csName,
          product.productType,
          product.installDate ? offsetDate(new Date(product.installDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          product.shipDateConfirmed,
          product.shippedDate ? offsetDate(new Date(product.shippedDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }) : "-",
          <ProgressCell params={product.unitBuildStatus} />,
          product.zones.filter(zone => zone.detectionOnlyZone === false).length,
          product.zones.filter(zone => zone.detectionOnlyZone === true).length,
          product.productConfigurations[0].riserCount,
          product.productConfigurations[0].coldWeatherPackage ? "Yes" : "No",
          product.productConfigurations[0].mastOption ? "Yes" : "No",
          product.productConfigurations[0].viewingStation ? "Yes" : "No",
          product.productConfigurations[0].thermalCameraCount,
          product.productConfigurations[0].flameDetectorCount,
          product.productConfigurations[0].smokeDetectorCount,
          product.productConfigurations[0].international ? "Yes" : "No",
          product.buildStatusSummaries[0],
        ))
      });
    }
    setRows(newRows);
  }

  const handleRedirect = (id) => {
    navigate(`/projectManagement/project?id=${id}`);
  }

  const handleBayNo = (index) => (event) => {
    if (event.target.value === 'None' && rows[index].bayNo !== 'None') {
      if (rows[index].bayNo !== null) {
        let array = [...availableBays, rows[index].bayNo];
        array.sort();
        setAvailableBays(array);
      }
      rows[index].bayNo = event.target.value;

      let newBayNo = {
        productID: rows[index].productid,
        bayNo: null,
      }

      axios.post("/api/Manufacturing/assignBayNumber", newBayNo)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
          alert("Error assigning bay number.");
        });
    }
    else {
      if (rows[index].bayNo !== event.target.value) {
        let array = [];
        if (rows[index].bayNo !== 'None' && rows[index].bayNo !== null) {
          array = [...availableBays, rows[index].bayNo];
          setAvailableBays(array);
        }
        else {
          array = [...availableBays];
        }
        let ind = array.indexOf(event.target.value)
        if (ind !== -1) {
          array.splice(ind, 1);
          array.sort();
          setAvailableBays(array);
        }
        rows[index].bayNo = event.target.value;

        let newBayNo = {
          productID: rows[index].productid,
          bayNo: event.target.value,
        }

        axios.post("/api/Manufacturing/assignBayNumber", newBayNo)
          .then((response) => {
          })
          .catch((error) => {
            console.log(error);
            alert("Error assigning bay number.");
          });
      }
    }
  }

  const columns =
    [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Button
            variant="contained"
            disabled={row.original.projid === "None"}
            onClick={() => handleRedirect(row.original.projid)}
            sx={{ padding: "0px" }}>
            {row.original.projid}
          </Button>
        ),
      },
      {
        accessorKey: 'devicename',
        header: 'Device Name',
        size: 150,
      },
      {
        accessorKey: 'bayNo',
        header: 'Bay No.',
        size: 110,
        Cell: ({ row }) => (
          <FormControl sx={{ width: "100%" }}>
            {row.original.devicetype === "BSU" ? (<Select
              labelId="bayNo-label"
              id="bayNo-select"
              value={row.original.bayNo in availableBays ? row.original.bayNo : ''}
              displayEmpty
              renderValue={() => { return allBays.includes(row.original.bayNo) ? row.original.bayNo : 'None' }}
              onChange={handleBayNo(row.index)}
              sx={{ padding: '0px', maxHeight: "45px" }}
            >
              {openBays.map((bay) => (
                <MenuItem key={bay.id} value={bay.bay}>
                  {bay.bay}
                </MenuItem>
              ))}
            </Select>
            ) : ""}
          </FormControl>
        ),
      },
      {
        accessorKey: 'cmName',
        header: 'Customer',
        size: 150,
      },
      {
        accessorKey: 'csName',
        header: 'Site',
        size: 150,
      },
      {
        accessorKey: 'devicetype',
        header: 'Device Type',
        size: 150,
      },
      {
        accessorKey: 'installdate',
        header: 'Install Date',
        size: 150,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'shipdateconfirm',
        header: '',
        size: 50,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
        Cell: ({ row }) => (
          <>
            {row.original.shipDateConfirmed && <LockIcon style={{ color: 'red', marginRight: '4px' }} />}
          </>
        ),
      },
      {
        accessorKey: 'shipdate',
        header: 'Ship Date',
        size: 200,
        sortingFn: (a, b, columnId) => {
          const distantPast = new Date(-8640000000000000);
          let dateA = a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
          let dateB = b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

          if (dateB.getTime() > dateA.getTime())
            return -1;
          else if (dateB.getTime() < dateA.getTime())
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'buildProgress',
        header: 'Build Progress',
        size: 175,
      },
      {
        accessorKey: 'zonecount',
        header: 'Zone Count',
        size: 150,
      },
      {
        accessorKey: 'detectonly',
        header: 'Detect Only Zones',
        size: 150,
      },
      {
        accessorKey: 'riser',
        header: 'Risers',
        size: 150,
      },
      {
        accessorKey: 'cold',
        header: 'Cold Weather Package',
        size: 150,
      },
      {
        accessorKey: 'mast',
        header: 'Mast Option',
        size: 150,
      },
      {
        accessorKey: 'vs',
        header: 'Viewing Station',
        size: 150,
      },
      {
        accessorKey: 'tc',
        header: 'Thermals',
        size: 150,
      },
      {
        accessorKey: 'fc',
        header: 'Flame Detectors',
        size: 150,
      },
      {
        accessorKey: 'sc',
        header: 'Smoke Analytics',
        size: 150,
      },
      {
        accessorKey: 'intl',
        header: 'International',
        size: 150,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableExpanding: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    state: {
      showSkeletons: loading,
    },
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'installdate',
          desc: true,
        },
      ],
      columnPinning: { left: ['mrt-row-expand', 'id'] },
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.projid} row={row.original} />
  });

  return <MaterialReactTable table={table} />;
}
import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';

export default function DeleteInvoice({ invoiceDetailId, onInvoiceDeleted }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const endpoint = `/api/Coordination/deleteInvoice/${invoiceDetailId}`;

    axios
      .delete(endpoint)
      .then(() => {
        onInvoiceDeleted();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data) {
            alert(error.response.data);
            handleClose();
        } else {
            alert("Error deleting invoice. Please try again.");
            handleClose();
        }
    })
  };

  return (
    <div>
      <Button variant="outlined" color="error" onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Invoice</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this invoice?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

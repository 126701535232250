import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Typography, Select, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditNetworkInfo({netInfo, netType, onSave}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [ipAddress, setIpAddress] = useState("");
  const [subnetMask, setSubnetMask] = useState("");
  const [gateway, setGateway] = useState("");
  const [dns1, setDns1] = useState("");
  const [dns2, setDns2] = useState("");
  const [sim1, setSim1] = useState("");
  const [sim2, setSim2] = useState("");
  const [ddns, setDdns] = useState("");
  const [imei, setImei] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [networkInfo, setNetworkInfo] = useState();


  const saveNetworkInfo = () => {
    const newNetworkInfo = {
        networkId: netInfo.networkId,
        productConfigId: netInfo.productConfigId,
        ipAddress: ipAddress,
        subnetMask: subnetMask,
        gateway: gateway,
        dns1: dns1,
        dns2: dns2,
        sim1: sim1,
        sim2: sim2,
        ddns: ddns,
        imei: imei,
        networkType: networkType
      };

    setLoading(true);

    axios
      .put("api/NetworkInfo/updateNetworkInfo", newNetworkInfo)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving network info. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        onSave();
        handleClose();
      });
  }

  useEffect(() => {
    setNetworkInfo(netInfo);
  }, [netInfo]);

  useEffect(() => {
    if (networkInfo) {
      setIpAddress(networkInfo.ipAddress || "");
      setSubnetMask(networkInfo.subnetMask || "");
      setGateway(networkInfo.gateway || "");
      setDns1(networkInfo.dns1 || "");
      setDns2(networkInfo.dns2 || "");
      setSim1(networkInfo.sim1 || "");
      setSim2(networkInfo.sim2 || "");
      setDdns(networkInfo.ddns || "");
      setImei(networkInfo.imei || "");
      setNetworkType(networkInfo.networkType || "");
    }
  }, [networkInfo]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{display: 'table-header-group', marginLeft: '10%', marginTop: '15%', marginBottom: '15%'}} onClick={handleOpen}>Edit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Edit Network Info
          </Typography>

          <Grid
              container
              rowSpacing={0}
              style={{ padding: "30px" }}
            >

            <Grid item {...gridItemProps}>
            <TextField
              label="IP Address"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>
            
            <Grid item {...gridItemProps}>
            <TextField
              label="Subnet Mask"
              value={subnetMask}
              onChange={(e) => setSubnetMask(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Gateway"
              value={gateway}
              onChange={(e) => setGateway(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Dns 1"
              value={dns1}
              onChange={(e) => setDns1(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Sim 1"
              value={sim1}
              onChange={(e) => setSim1(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Dns 2"
              value={dns2}
              onChange={(e) => setDns2(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Sim 2"
              value={sim2}
              onChange={(e) => setSim2(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Ddns"
              value={ddns}
              onChange={(e) => setDdns(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            </Grid>

            <Grid item {...gridItemProps}>
            <TextField
              label="Imei"
              value={imei}
              onChange={(e) => setImei(e.target.value)}
              style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
            />
            </Grid>


            <Grid item {...gridItemProps}>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel id="network-type-label">Network Type</InputLabel>
                <Select
                  labelId="network-type-label"
                  value={networkType}
                  onChange={(e) => setNetworkType(e.target.value)}
                  label="Network Type"
                >
                  <MenuItem value="WAN">WAN</MenuItem>
                  <MenuItem value="Cellular">Cellular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveNetworkInfo}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Network Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

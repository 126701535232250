import React, { useState, useEffect } from "react";
import { Typography, useTheme } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSearchParams } from "react-router-dom";
import AddDocument from "./AddDocument";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function DocumentList(props) {
  const [data, setData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const theme = useTheme(); // Access the theme
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const documentAdded = () => {
    fetchData();
    props.prelimAdded();
  }

  const fetchData = () => {
    axios
      .get(`/api/Onboarding/documentList/${searchParams.get("id")}`)
      .then((response) => {
        const documentsData = Array.isArray(response.data)
          ? response.data
          : [];
        setData(documentsData);
      })
      .catch((error) => {
        console.log(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching document list.");
        }
      });
  };

  if (props.obSubmit) {
    fetchData();
    props.onObTaskSubmit(false);
  }

  const downloadDocument = (documentLink) => {
    window.open(documentLink);
  };

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  const hoverColor = theme.palette.mode === 'dark' ? '#292929' : '#f0f0f0';

  return (
    <div style={{ minHeight: '100%' }}>
      <Typography variant="h5" gutterBottom style={{ display: "flex", alignItems: "center" }}>
        <div style={{ paddingLeft: "40%" }}>
          Document List
        </div>
        <div style={{ flex: 1 }}></div>
        <AddDocument onDocumentAdded={documentAdded} />
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell align="center" style={{ width: '10%' }}>
                Final Design
              </TableCell>
              <TableCell align="center" style={{ width: '20%' }}>
                Date/Time
              </TableCell>
              <TableCell align="center" style={{ width: '20%' }}>
                User
              </TableCell>
              <TableCell align="center" style={{ width: '20%' }}>
                File Type
              </TableCell>
              <TableCell align="center" style={{ width: '20%' }}>
                Description
              </TableCell>
              <TableCell align="center" style={{ width: '20%' }}>
                Version No.
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <div style={{ overflowY: 'scroll', maxHeight: '500px' }}>
        <TableContainer>
          <Table>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onDoubleClick={() => downloadDocument(row.documentLink)}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                  style={{ backgroundColor: index === hoveredRow ? hoverColor : "transparent" }}
                >
                  <TableCell align="center" style={{ width: '10%' }}>
                    {row.finalDesignDoc
                    ? <CheckCircleIcon style={{ color: "green" }} />
                    :
                    null
                  }
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    {offsetDate(new Date(row.dateTime)).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    {row.userName}
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    {row.fileType}
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    {row.dscrption}
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    {row.versionNo}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

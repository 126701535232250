import React, {useState, useEffect} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function SiteInfoTable({id}) {
  const borderStyle = { border: '1px solid black' };
  const [siteInfoData, setSiteInfoData] = useState({});
  const theme = useTheme();

  const fetchData = () => {
    const endpoint = `api/SiteInfo/getSiteInfo/${id}`;

    axios.get(endpoint)
        .then((response) => {
            setSiteInfoData(response.data);
        })
        .catch((error) => {
            console.error(error);
            if (error.message) {
                alert(error.message);
            } else {
                alert("Error fetching Site info.");
            }
        });
  }

  useEffect(() => {
    fetchData();
  }, []);
    
  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse' }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem', 
            backgroundColor: theme.palette.mode === 'light' ? '#D0D0D0' : 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            padding: '16px 8px',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'inherit',
          },
          '& .MuiTableRow-root': {
            height: '33px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap', 
            textAlign: 'center', 
          },
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              Site Information
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>Site Address:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.siteContactPhone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IT Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.itContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IT Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.itContactPhone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IT Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{siteInfoData.itContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};



import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditNetworkInfo from './EditNetworkInfo';

export default function NetworkInfoTable({ id }) {
  const [rowId, setRowId] = useState(id);
  const [networkInfo, setNetworkInfo] = useState({});
  const [networkType, setNetworkType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const borderStyle = { border: '1px solid black' };

  const fetchData = async () => {
    try {
      const networkInfoEndpoint = `api/NetworkInfo/networkInfoFromDeviceId/${rowId}`;
      const response = await axios.get(networkInfoEndpoint);
      setNetworkInfo(response.data);
      setNetworkType(response.data.networkType);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rowId]);

  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse' }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem', 
            backgroundColor: theme.palette.mode === 'light' ? '#D0D0D0' : 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'inherit',
          },
          '& .MuiTableRow-root': {
            height: '24px',
          },
          '& th, & td': {
            whiteSpace: 'nowrap', 
            textAlign: 'center', 
          },
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                <Typography variant='h6' gutterBottom>
                  Network Information
                </Typography>
                {!isLoading && networkInfo && (
                  <Box ml={2}>
                    <EditNetworkInfo netInfo={networkInfo} netType={networkType} onSave={fetchData} />
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}></TableCell>
            <TableCell align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>WAN</TableCell>
            <TableCell align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>Cellular</TableCell>
          </TableRow>
        </TableBody>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>IP:</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? networkInfo.ipAddress : null}</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? null : networkInfo.ipAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Subnet Mask:</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? networkInfo.subnetMask : null}</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? null : networkInfo.subnetMask}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Gateway:</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? networkInfo.gateway : null}</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? null : networkInfo.gateway}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>DNS 1:</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? networkInfo.dns1 : null}</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? null : networkInfo.dns1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>DNS 2:</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? networkInfo.dns2 : null}</TableCell>
            <TableCell colSpan={1} align="center" style={borderStyle}>{networkType === "WAN" ? null : networkInfo.dns2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>SIM 1:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{networkInfo.sim1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>SIM 2:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{networkInfo.sim2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>DDNS:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{networkInfo.ddns}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>IMEI:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{networkInfo.imei}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};



import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Avatar, Tooltip, Skeleton, Modal, Fade, Snackbar } from '@mui/material';
import frdarklogo from "../../res/FRLogo.png";
import AddPmNote from "./AddPmNote";
import EditPmNote from "./EditPmNote";
import { projectManageAxios as axios } from "../../utils/AxiosUtility";
import MuiAlert from '@mui/material/Alert';

export default function ActivityFeed(props) {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const fetchData = () => {
        const endpoint = props.projectId ? `api/ActivityFeed/siteLevelActivityFeed/${props.projectId}` : 'api/ActivityFeed/activityFeed';
    
        axios.get(endpoint)
            .then((response) => {
                const sortedData = response.data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
                setActivityData(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching activity feed.");
                }
            });
    }

    const handleRowDoubleClick = (note) => {
        setNoteToEdit(note);
        setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        fetchData();
    }

    const openSnackbar = () => {
      setSnackbarOpen(true);
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
      }

    return (
        <div>
            <Typography variant="h5" gutterBottom style={{ display: "flex", alignItems: "center" }}>
                <div style={{ paddingLeft: "160px" }}>
                    Activity Feed
                </div>
                <div style={{ flex: 1 }}></div>
                {props.projectId && <AddPmNote onNoteAdded={fetchData} />}
            </Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width: "10%"}}>User</TableCell>
                            {!props.projectId ? <TableCell align="left">Project Id</TableCell> : null}
                            <TableCell align="center">Note</TableCell>
                            <TableCell align="center">Date/Time</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            <div style={{ overflowY: 'scroll', maxHeight: '320px' }}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {loading ? (
                                Array.from({ length: 4 }).map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            <Skeleton animation="wave" variant="circular" width={24} height={24} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Skeleton animation="wave" variant="text" width={150} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Skeleton animation="wave" variant="text" width={100} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                activityData.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        onDoubleClick={() => handleRowDoubleClick(row)}
                                        sx={{ '&:hover': { cursor: 'pointer' } }}
                                    >
                                        <Tooltip title={row.userName} placement="left">
                                            <TableCell align="center">
                                                <Avatar alt="Remy Sharp" src={frdarklogo} sx={{ width: 24, height: 24 }} />
                                            </TableCell>
                                        </Tooltip>
                                        {!props.projectId ? <TableCell align="center">{row.projectId}</TableCell> : null}
                                        <TableCell align="center">{row.notes}</TableCell>
                                        <TableCell align="center">
                                            {offsetDate(new Date(row.dateTime)).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                closeAfterTransition
            >
                <Fade in={isEditModalOpen}>
                    <div>
                      <EditPmNote note={noteToEdit} onUpdateNote={handleCloseEditModal} openSnackbar={openSnackbar}  />
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Note saved!
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, TextField, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function AddDevice({productConfigId, nozIds, tabInd, nozzleProgList, allDevice, onDeviceAdded, onNozzleAdded}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [allDevices, setAllDevices] = useState(allDevice);
    const [nozzleIds, setNozzleIds] = useState([]);
    const [newDeviceRowData, setNewDeviceRowData] = useState({productConfigurationId: productConfigId});
    const [newNozzleRowData, setNewNozzleRowData] = useState({nozzleId: nozIds[0]});
    const [tabIndex, setTabIndex] = useState(tabInd);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);


    useEffect(() => {
        setTabIndex(tabInd);
        setNewDeviceRowData({productConfigurationId: productConfigId});
        setNozzleIds(nozIds);
    }, [tabInd, nozIds]);

    useEffect(() => {
        setNewNozzleRowData({nozzleId: nozzleIds[tabIndex - 1]});
    }, [tabIndex, nozzleIds]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (tabIndex === 0) {
            setNewDeviceRowData((prevData) => ({ ...prevData, [name]: value }));
        } else {
            setNewNozzleRowData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleFormSubmit = async () => {
        try {
            if (tabIndex === 0) {
                const endpoint = 'api/DeviceInfo/createDevice';
                await axios.post(endpoint, newDeviceRowData);
                setAllDevices((prevData) => [...prevData, newDeviceRowData]);
                onDeviceAdded();
                setSnackbarMessage('New Device Added!');
            } else {
                const endpoint = 'api/NozzleProgramming/createNozzle';
                await axios.post(endpoint, newNozzleRowData);
                onNozzleAdded();
                setSnackbarMessage('New Nozzle Added!');
            }
            setSnackbarOpen(true);
            handleModalClose();
        } catch (error) {
            console.error("Error adding new entry: ", error);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Add New {tabIndex === 0 ? 'Device' : 'Nozzle'}
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</Button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add New {tabIndex === 0 ? 'Device' : 'Nozzle'}
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={6}>
                            <TextField label="Name" name="deviceName" fullWidth value={tabIndex === 0 ? newDeviceRowData.deviceName : newNozzleRowData.deviceName} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Type" name="deviceType" fullWidth value={tabIndex === 0 ? newDeviceRowData.deviceType : newNozzleRowData.deviceType} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="MAC Address" name="macAddress" fullWidth value={tabIndex === 0 ? newDeviceRowData.macAddress : newNozzleRowData.macAddress} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Internal IP Address" name="internalIpAddress" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalIpAddress : newNozzleRowData.internalIpAddress} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="HTTP Port (TCP)" name="portTcp" fullWidth value={tabIndex === 0 ? newDeviceRowData.portTcp : newNozzleRowData.portTcp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="HTTP Port (UDP)" name="portUdp" fullWidth value={tabIndex === 0 ? newDeviceRowData.portUdp : newNozzleRowData.portUdp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Port RTSP" name="internalRtsp" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalRtsp : newNozzleRowData.internalRtsp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External HTTP" name="externalHttp" fullWidth value={tabIndex === 0 ? newDeviceRowData.externalHttp : newNozzleRowData.externalHttp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External RTSP" name="externalRtsp" fullWidth value={tabIndex === 0 ? newDeviceRowData.externalRtsp : newNozzleRowData.externalRtsp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Outbound Port" name="outboundPort" fullWidth value={tabIndex === 0 ? newDeviceRowData.outboundPort : newNozzleRowData.outboundPort} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}



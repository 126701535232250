import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Stack, Skeleton } from '@mui/material';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function WIPStats({ filterIds }) {
    // eslint-disable-next-line
    const [WIPStats, setWIPStats] = useState({});
    const [WIPSummary, setWIPSummary] = useState({});
    const [progressVariance, setProgressVariance] = useState(0);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    useEffect(() => {
        const endpoint = 'api/WIP/AllWip';
        axios.get(endpoint)
            .then((response) => {
                setWIPStats(response.data);
                setWIPSummary(response.data);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const endpoint = 'api/WIP/getVariance';
        axios.get(endpoint)
            .then((response) => {
                setProgressVariance(response.data);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (filterIds.length < WIPSummary.length) {
            let newStats = WIPSummary.filter(({ projectId }) => filterIds.includes(projectId));
            setWIPStats(newStats);
        }
        else {
            setWIPStats(WIPSummary);
        }
        //eslint-disable-next-line
    }, [filterIds]);

    const WIPStatsData = [
        { header: "BSU Count", key: "bsuCount" },
        { header: "OnWatch", key: "onWatchCount" },
        { header: "Early Detection", key: "edCount" },
        { header: "Continuous Flow", key: "cfCount" },
        { header: "Riser Count", key: "totalRiserCount" },
        { header: "Thermal Count", key: "totalThermalCount" },
        { header: "3IR Count", key: "totalIrCount" },
        { header: "Total RMR", key: "rmrValue" },
        { header: "Total Install", key: "installPrice" },
    ];

    const getCountFromEvents = (events, key) => {
        if (!events || !Array.isArray(events)) {
            return 0;
        }

        let count = 0;
        if (["bsuCount", "onWatchCount", "edCount", "cfCount", "totalRiserCount", "totalThermalCount", "totalIrCount"].includes(key)) {
            events.filter(data => data[key]).forEach(function (item) {
                count += item[key];
            });
            return count;
        }

        if (key === "rmrValue") {
            count = 0;
            events.filter(data => data[key]).forEach(function(item){
                count += item.rmrValue;
            });
            return formatter.format(count);
        }

        if (key === "installPrice") {
            count = 0;
            events.filter(data => data[key]).forEach(function(item){
                count += item.installPrice;
            });
            return formatter.format(count);
        }

        return events.filter(data => data[key]).length;
    };

    return (
      <div>
        <Typography variant="h5" align="center" gutterBottom>
          Statistics
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          {WIPStatsData.map((item, index) => (
            <Grid item xs={1.5} key={index}>
              <Stack>
                <Typography
                  variant="h7"
                  align="center"
                  style={{ padding: "1%", fontWeight: "bold" }}
                >
                  {item.header}
                </Typography>

                {loading ? (
                  <Skeleton variant="text" animation="wave" width="100%" height={50}/>
                ) : (
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ paddingTop: "10px" }}
                  >
                    {getCountFromEvents(WIPStats, item.key)}
                  </Typography>
                )}
              </Stack>
            </Grid>
          ))}
          <Grid item xs={1.5}>
            <Stack>
              <Typography
                variant="h7"
                align="center"
                style={{ padding: "1%", fontWeight: "bold" }}
              >
                Progress Variance
              </Typography>
              <Typography
                variant="h6"
                align="center"
                style={{ paddingTop: "10px" }}
              >
                {progressVariance}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </div>
    );
}

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Tabs, Tab, Skeleton, Button, Snackbar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from '@mui/icons-material/Lock';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
};

const distantPast = new Date(-8640000000000000);

const sortingFn = (a, b, columnId) => {
    let dateA = a.original[columnId] && a.original[columnId] !== "-" ? new Date(a.original[columnId]) : distantPast;
    let dateB = b.original[columnId] && b.original[columnId] !== "-" ? new Date(b.original[columnId]) : distantPast;

    if (dateA.getTime() < dateB.getTime()) return -1;
    if (dateA.getTime() > dateB.getTime()) return 1;

    if (a.original.shipDateConfirmed && !b.original.shipDateConfirmed) return -1;
    if (!a.original.shipDateConfirmed && b.original.shipDateConfirmed) return 1;

    return 0;
};

const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
}

function renderDate(checkTimeAndDate) {
    if (!checkTimeAndDate) {
        return '';
    }
    return offsetDate(new Date(checkTimeAndDate)).toLocaleDateString(
        'en-US',
        dateFormattingOptions
    );
}

function ProgressCell(params) {
    const progress = params.row.original.progress || 0;
    const barWidth = 180;
    const barHeight = 20;
    const filledWidth = (progress / 100) * barWidth;
    const emptyWidth = barWidth - filledWidth;

    return (
        <div style={{ width: barWidth, height: barHeight, border: "1px solid #e0e0e0", position: "relative" }}>
            <div style={{ width: filledWidth, height: barHeight, backgroundColor: "#35CB82", position: "absolute", top: -1, left: -1 }} />
            <div style={{ width: emptyWidth, height: barHeight, position: "absolute", top: 0, right: 0 }} />
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontWeight: "bold" }}>
                {`${progress}%`}
            </div>
        </div>
    );
}

function CheckCell(params) {
    return (
        <Box sx={{ justifyContent: "center" }}>
            {params.cell.getValue() ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
        </Box>
    );
}

export default function PMTable(props) {
    const [selectedTab, setSelectedTab] = useState("Open");
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [rowSelection, setSelectedRows] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const navigate = useNavigate();

    const headerColumns = [
        {
            accessorKey: "projectId", header: "Project ID", size: 100,
            Cell: ({ row }) => (
                <Button
                    variant="contained"
                    onClick={() => navigate(`/projectManagement/project?id=${[row.original.projectId]}`, { state: { tab: props.tab } })}
                    sx={{ padding: "0px" }}>
                    {row.original.projectId}
                </Button>
            ),
            enableColumnOrdering: false
        },
        { accessorKey: "projectName", header: "Master Customer", size: 250, enableColumnOrdering: false },
        { accessorKey: "site", header: "Site", size: 250 },
        { accessorKey: "state", header: "State", size: 60 },
        { accessorKey: "age", header: "Days Open", size: 100 },
    ];

    const milestoneHeaders = {
        Onboarding: [
            { accessorKey: "progress", header: "Progress", size: 200, Cell: ProgressCell },
            { accessorKey: "acknowledgement", header: "Acknowledgement", size: 130, Cell: CheckCell },
            { accessorKey: "siteVisit", header: "Site Visit", size: 100, Cell: CheckCell },
            { accessorKey: "prelimDesign", header: "Prelim Design", size: 130, Cell: CheckCell },
            { accessorKey: "prelimApproval", header: "Prelim Eng Approval", size: 150, Cell: CheckCell },
            { accessorKey: "finalDesign", header: "Final Design", size: 130, Cell: CheckCell },
            { accessorKey: "approval", header: "Final Eng Approval", size: 150, Cell: CheckCell },
            { accessorKey: "finalApproval", header: "Corporate Approval", size: 150, Cell: CheckCell },
        ],
        SitePrep: [
            { accessorKey: "progress", header: "Progress", size: 200, Cell: ProgressCell },
            {
                accessorKey: 'shipDateConfirmed',
                header: '',
                size: 15,
                enableResizing: false,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {row.original.shipDateConfirmed && <LockIcon style={{ color: 'red', marginRight: '4px' }} />}
                    </>
                ),
            },
            {
                accessorKey: 'shipDate',
                header: 'Ship Date',
                size: 200,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {renderDate(row.original.shipDate)}
                    </>
                ),
            },
            {
                accessorKey: 'installDate',
                header: 'Install Date',
                size: 200,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {renderDate(row.original.installDate)}
                    </>
                ),
            },
            { accessorKey: "kickoff", header: "Kickoff", size: 100, Cell: CheckCell },
            { accessorKey: "pad", header: "Pad", size: 100, Cell: CheckCell },
            { accessorKey: "power", header: "Power", size: 100, Cell: CheckCell },
            { accessorKey: "internet", header: "Internet", size: 100, Cell: CheckCell },
            { accessorKey: "deposit", header: "Deposit", size: 100, Cell: CheckCell },
        ],
        Coordination: [
            {
                accessorKey: 'shipDateConfirmed',
                header: '',
                size: 15,
                enableResizing: false,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {row.original.shipDateConfirmed && <LockIcon style={{ color: 'red', marginRight: '4px' }} />}
                    </>
                ),
            },
            {
                accessorKey: 'shipDate',
                header: 'Ship Date',
                size: 200,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {renderDate(row.original.shipDate)}
                    </>
                ),
            },
            {
                accessorKey: 'installDate',
                header: 'Install Date',
                size: 200,
                sortingFn: sortingFn,
                Cell: ({ row }) => (
                    <>
                        {renderDate(row.original.installDate)}
                    </>
                ),
            },
            { accessorKey: "progress", header: "Progress", size: 200, Cell: ProgressCell },
            { accessorKey: "shipping", header: "Shipping", size: 100, Cell: CheckCell },
            { accessorKey: "crane", header: "Crane", size: 100, Cell: CheckCell },
            { accessorKey: "lift", header: "Lift", size: 100, Cell: CheckCell },
            { accessorKey: "nitro", header: "Nitro", size: 100, Cell: CheckCell },
            { accessorKey: "handOffCall", header: "FR System Tutorial", size: 100, Cell: CheckCell },
            { accessorKey: "readytobill", header: "Ready To Bill", size: 100, Cell: CheckCell },
        ],
    };

    let one_day = 1000 * 60 * 60 * 24;

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const updateProjects = () => {
        const endpoint = 'api/Projects/' + props.step;
        axios.get(endpoint)
            .then((response) => {
                setProjectData(response.data);
            })
            .catch((error) => {
                console.log(error)
                alert("Error retreiving data.");
            });
    }

    const handleArchive = () => {
        let archives = Object.keys(rowSelection).map(Number);
        if (archives.length <= 0) {
            alert("Please select a task to archive.");
        }
        else {
            axios.put("/api/Projects/archiveTasks", archives)
                .then((response) => {
                    setSelectedRows({});
                    updateProjects();
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    console.log(error);
                    alert("Error archiving task.");
                })
        }
    }

    const handleUnarchive = () => {
        if (selectedTab === "Archived") {
            let archives = Object.keys(rowSelection).map(Number);
            if (archives.length <= 0) {
                alert("Please select a task to unarchive.");
            }
            else {
                axios.put("/api/Projects/unarchiveTasks", archives)
                    .then((response) => {
                        setSelectedRows({});
                        updateProjects();
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        alert("Error archiving task.");
                    })
            }
        }
        else {
            alert("Only archived tasks can be unarchived.");
        }
    }

    useEffect(() => {
        const endpoint = 'api/Projects/' + props.step;
        axios.get(endpoint)
            .then((response) => {
                setProjectData(response.data);
            })
            .catch((error) => {
                console.log(error)
                alert("Error retreiving project data.");
            });
        // eslint-disable-next-line
    }, []);

    const setProjectData = (projectData) => {
        setLoading(true);

        if (projectData.length > 0) {
            let rows = [];

            projectData.forEach((project) => {
                if (project.projectId === 761) {    //Master Stock Customer ID
                    return;
                }
                let daysOpen, progress, status, taskid, today;

                switch (props.step) {
                    case "Onboarding":
                        let opened = 0;
                        taskid = "projectTaskObId";
                        if (project["aknowledgementDate"]) {
                            today = new Date();
                            opened = new Date(project["aknowledgementDate"]);
                            daysOpen = Math.abs(Math.round((today.getTime() - opened.getTime()) / one_day));
                        }
                        else {
                            daysOpen = null;
                        }
                        progress = (
                            (project["aknowledgementDate"] ? 15 : 0) +
                            (project["prelimDesign"] ? 15 : 0) +
                            (project["finalDesign"] ? 15 : 0) +
                            (project["siteVisitCompleteDate"] ? 10 : 0) +
                            (project["pEngineerApprovedDate"] ? 15 : 0) +
                            (project["fEngineerApprovedDate"] ? 15 : 0) +
                            (project["corpApprovalDate"] ? 15 : 0)
                        );

                        status = project["statusOb"];
                        break;
                    case "SitePrep":
                        taskid = "projectTaskSpId";
                        if (project["spStartDate"]) {
                            today = new Date();
                            let opened = new Date(project["spStartDate"]);
                            daysOpen = Math.abs(Math.round((today.getTime() - opened.getTime()) / one_day));
                        }
                        else {
                            daysOpen = null;
                        }
                        progress = (
                            (project["siteKickoffCallCompleted"] ? 20 : 0) +
                            (project["padInstall"] && project["padVerifiedDate"] ? 20 : 0) +
                            (project["powerInstalledDate"] && project["powerVerifiedDate"] ? 20 : 0) +
                            (project["internetInstalledDate"] && project["internetVerifiedDate"] ? 20 : 0) +
                            (project["depositReceivedDate"] ? 20 : 0)
                        );

                        status = project["statusSp"];
                        break;
                    case "Coordination":
                        taskid = "projectTaskScId";
                        if (project["scStartDate"]) {
                            today = new Date();
                            let opened = new Date(project["scStartDate"]);
                            daysOpen = Math.abs(Math.round((today.getTime() - opened.getTime()) / one_day));
                        }
                        else {
                            daysOpen = null;
                        }
                        progress = (
                            (project["craneArrivalConfirmation"] ? 16.66 : 0) +
                            (project["dateOutConfirmation"] ? 16.66 : 0) +
                            (project["confirmationNumber"] ? 16.66 : 0) +
                            (project["productsShipped"] ? 16.66 : 0) +
                            (project["customerHandoffCallCompletedDate"] ? 16.66 : 0) +
                            (project["readyToBillCompleted"] ? 16.66 : 0)
                        ).toFixed(1);

                        status = project["statusSc"];
                        break;
                    default:
                        break;
                }

                rows.push(
                    {
                        id: project[taskid] ?? 0,
                        projectId: project["projectId"] ?? 0,
                        projectName: project["cmName"] ?? "Default",
                        site: project["csName"] ?? "Default",
                        state: project["csState"] ?? "US",
                        age: daysOpen,
                        prelimDesign: project["prelimDesign"] ? true : false,
                        siteVisit: project["siteVisitCompleteDate"] ? true : false,
                        finalDesign: project["finalDesign"] ? true : false,
                        prelimApproval: project["pEngineerApprovedDate"] ? true : false,
                        approval: project["fEngineerApprovedDate"] ? true : false,
                        finalApproval: project["corpApprovalDate"] ? true : false,
                        acknowledgement: project["aknowledgementDate"] ? true : false,
                        status: status,
                        kickoff: project["siteKickoffCallCompleted"] ? true : false,
                        pad: project["padInstall"] && project["padVerifiedDate"] ? true : false,
                        power: project["powerInstalledDate"] && project["powerVerifiedDate"] ? true : false,
                        internet: project["internetInstalledDate"] && project["internetVerifiedDate"] ? true : false,
                        deposit: project["depositReceivedDate"] ? true : false,
                        crane: project["craneArrivalConfirmation"] ? true : false,
                        nitro: project["confirmationNumber"] ? true : false,
                        lift: project["dateOutConfirmation"] ? true : false,
                        handOffCall: project["customerHandoffCallCompletedDate"] ? true : false,
                        shipping: project["productsShipped"] ? true : false,
                        readytobill: project["readyToBillCompleted"] ? true : false,
                        installDate: project["installDate"] ? new Date(project["installDate"]) : "",
                        shipDateConfirmed: project["shipDateConfirmed"] ? project["shipDateConfirmed"] : "",
                        shipDate: project["shippedDate"] ? new Date(project["shippedDate"]) : "",
                        progress: progress,
                    })
            })

            setTableData(rows);
            setLoading(false);
        }
    };

    useEffect(() => {
        const filteredRows = tableData.filter((row) => row.status === selectedTab);
        setProjects(filteredRows)
    }, [selectedTab, tableData]);

    const milestoneColumns = milestoneHeaders[props.step] || [];

    const columns = [...headerColumns, ...milestoneColumns];

    const table = useMaterialReactTable({
        columns,
        data: projects,
        enableColumnOrdering: true,
        enableStickyHeader: true,
        enableColumnResizing: true,
        paginationDisplayMode: 'pages',
        enableRowSelection: true,
        getRowId: (originalRow) => originalRow.id,
        onRowSelectionChange: setSelectedRows,
        state: { rowSelection },
        layoutMode: 'grid',
        initialState: {
            density: 'compact',
            columnPinning: { left: ['mrt-row-select', 'projectId', 'projectName'] },
            pagination: { pageSize: 100, pageIndex: 0 },
            sorting: [
                {
                    id: 'age',
                    desc: true,
                },
            ]
        },
        muiPaginationProps: {
            shape: 'rounded',
            rowsPerPageOptions: [50, 100, 200],
            showFirstButton: true,
            showLastButton: true,
        },
        muiTableContainerProps: { sx: { maxHeight: '70vh', minHeight: '70vh' } },
        muiTableHeadCellProps: {
            sx: {
                '& .Mui-TableHeadCell-Content-Actions': {
                    display: "none"
                },
                '&:hover .Mui-TableHeadCell-Content-Actions': {
                    display: "block"
                },
                '& .Mui-TableHeadCell-Content-Labels span': {
                    display: "none"
                },
                '&:hover .Mui-TableHeadCell-Content-Labels span': {
                    display: "block"
                },
            },
        },
    });

    return (
        <Box sx={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="Open" value="Open" />
                    {props.step === "Coordination" ? (<Tab label="Pending Invoice" value="Pending Invoice" />) : null}
                    {props.step === "Onboarding" ? (<Tab label="Pending Contract" value="Pending" />) : null}
                    {props.step === "Onboarding" ? (<Tab label="Contracted" value="Completed" style={{ color: "green" }} />) : (<Tab label="Completed" value="Completed" style={{ color: "green" }} />)}
                    {props.step === "Onboarding" ? (<Tab label="Archive" value="Archived" />) : null}
                </Tabs>
                {props.step === "Onboarding" && (selectedTab === "Pending" || selectedTab === "Open") ? <Button variant="outlined" onClick={handleArchive} style={{ marginRight: "20px" }}>Archive</Button> : <></>}
                {props.step === "Onboarding" && selectedTab === "Archived" ? <Button variant="outlined" onClick={handleUnarchive} style={{ marginRight: "20px" }}>Unarchive</Button> : <></>}
            </div>
            {loading ? (
                <div>
                    <Skeleton variant="rectangular" animation="wave" width="100%" height={50} style={{ marginBottom: '10px' }} />
                    {[1, 2, 3, 4, 5].map((index) => (
                        <Skeleton key={index} variant="rectangular" animation="wave" width="100%" height={50} style={{ marginBottom: '10px' }} />
                    ))}
                </div>
            ) : (
                <MaterialReactTable table={table} />
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Task(s) archived successfully.
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}
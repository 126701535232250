import React from "react";
import PropTypes from 'prop-types';
import { NumericFormat } from "react-number-format";

const NumericInput = React.forwardRef(function NumericInput(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumericInput;

import React, {useState} from "react";
import { Box, Typography, TextField, Button, Modal, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Skeleton } from "@mui/material";

function ServiceForm({ ticketID, assignedTo, status, createdDate }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Assigned</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">
                                <Button onClick={handleOpen}>
                                    {ticketID}
                                </Button>
                            </TableCell>
                            <TableCell align="left">{assignedTo}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">{createdDate}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={open} onClose={handleClose}>
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Service Ticket {ticketID}
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Info Goes Here
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
}

export function OpenServiceTickets() {
    return (
        <Box sx={{ padding: 2, width: '100%' }}>
            <Typography variant="h6" align="center">Open Service Tickets</Typography>
            <ServiceForm 
                ticketID="947"
                assignedTo="John Doe"
                status="Open"
                createdDate="2024-06-06"
            />
        </Box>
    );
}

export function ClosedServiceTickets() {
    return (
        <Box sx={{ padding: 2, width: '100%' }}>
            <Typography variant="h6" align="center">Closed Service Tickets</Typography>
            <ServiceForm 
                ticketID="223"
                assignedTo="John Doe"
                status="Closed"
                createdDate="2024-02-16"
            />
        </Box>
    );
}

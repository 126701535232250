import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

const BreadcrumbTrail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbtextStyle = {
    color: "text.primary",
    underline: "hover",
  };

  const getPathParts = () => {
    const { pathname } = location;
    return pathname.split("/").filter((part) => part !== "");
  };

  const handleClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const pathParts = getPathParts();

  return (
    <Breadcrumbs>
      {pathParts.length > 0 && (
        <Link component={RouterLink} to="/" color="inherit" underline="hover">
          Home
        </Link>
      )}
      {pathParts.map((part, index) => {
        const path = `/${pathParts.slice(0, index + 1).join("/")}`;
        const title = part.charAt(0).toUpperCase() + part.slice(1);
        const isLast = index === pathParts.length - 1;

        return isLast ? (
          <Link key={index} {...breadCrumbtextStyle}>
            {title}
          </Link>
        ) : (
          <Link
            key={index}
            component={RouterLink}
            to={path}
            onClick={(event) => handleClick(event, path)}
            {...breadCrumbtextStyle}
          >
            {title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;

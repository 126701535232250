import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, ToggleButtonGroup, ToggleButton, Select, MenuItem } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";

export default function CoordinationShippingDetails() {
  const [open, setOpen] = useState(false);
  const [vendorPrimaryContact, setVendorPrimaryContact] = useState("");
  const [vendorPrimaryContactPhone, setVendorPrimaryContactPhone] = useState("");
  const [vendorPrimaryContactEmail, setVendorPrimaryContactEmail] = useState("");
  const [vendorSecondaryContact, setVendorSecondaryContact] = useState("");
  const [vendorSecondaryContactPhone, setVendorSecondaryContactPhone] = useState("");
  const [vendorSecondaryContactEmail, setVendorSecondaryContactEmail] = useState("");
  const [stepDeck, setStepDeck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [shippingDetails, setShippingDetails] = useState();
  const [billOfLading, setBillOfLading] = useState(false);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");

  useEffect(() => {
    fetchData();
    fetchVendors();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const endpoint = `api/Coordination/shippingDetails/${searchParams.get("id")}`;

    axios
      .get(endpoint)
      .then((response) => {
        setShippingDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching shipping details.");
        }
      });
  };

  const fetchVendors = () => {
    axios
      .get("api/Coordination/listOfVendors", {
        params: {
          vendorType: "shipping",
        },
      })
      .then((response) => {
        setAvailableVendors(response.data);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendors.");
      });
  };

  const handleVendorChange = (event) => {
    const selectedVendorName = event.target.value;
  
    setSelectedVendor(selectedVendorName);
  
    let vendorContactsData = null;
  
    fetchVendorContacts(selectedVendorName)
      .then((response) => {
        vendorContactsData = response.data;
  
        setVendorPrimaryContact(vendorContactsData.vmPrimaryContact);
        setVendorPrimaryContactPhone(vendorContactsData.vmPrimaryContactPhone);
        setVendorPrimaryContactEmail(vendorContactsData.vmPrimaryEmail);
        setVendorSecondaryContact(vendorContactsData.vmSecondaryContact);
        setVendorSecondaryContactPhone(vendorContactsData.vmSecondaryContactPhone);
        setVendorSecondaryContactEmail(vendorContactsData.vmSecondaryEmail);
      })
      .catch((error) => {
        console.error(error);
        alert("Error fetching vendor contacts.");
      });
  };
  
  const fetchVendorContacts = (vendorName) => {
    return axios.get("api/Coordination/vendorContacts", {
      params: {
        vendorName,
      },
    });
  };
  

  const handleStepDeckChange = (event, stepDeck) => {
    if (stepDeck !== null) {
      setStepDeck(stepDeck);
    }
  };

  const handleDeliveryDate = (date) => {
    setDeliveryDate(date);
  };

  const handlePickupDate = (date) => {
    setPickupDate(date);
  };

  const handleBillOfLading = (event, billOfLading) => {
    if (billOfLading !== null) {
      setBillOfLading(billOfLading);
    }
  };

  const saveShippingDetails = () => {
    const newShippingDetails = {
        ProjectId: searchParams.get("id"),
        VmName: selectedVendor,
        StepDeck: stepDeck,
        BillOfLading: billOfLading,
      };
      
      if (deliveryDate !== null) {
        newShippingDetails.DeliveryDate = dayjs(deliveryDate);
      }
      
      if (pickupDate !== null) {
        newShippingDetails.PickUpDate = dayjs(pickupDate);
      }

    setLoading(true);

    axios
      .post("/api/Coordination/createShippingDetails", newShippingDetails)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving shipping details. Please try again.");
      })
      .finally(() => {
        fetchData();
        setLoading(false);
        handleClose();
      });
  }

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  useEffect(() => {
    if (shippingDetails) {
      setSelectedVendor(shippingDetails.vmName);
      setVendorPrimaryContact(shippingDetails.vmPrimaryContact || "");
      setVendorPrimaryContactPhone(shippingDetails.vmPrimaryContactPhone || "");
      setVendorPrimaryContactEmail(shippingDetails.vmPrimaryEmail || "");
      setVendorSecondaryContact(shippingDetails.vmSecondaryContact || "");
      setVendorSecondaryContactPhone(shippingDetails.vmSecondaryContactPhone || "");
      setVendorSecondaryContactEmail(shippingDetails.vmSecondaryEmail || "");
      setStepDeck(shippingDetails.stepDeck || false);
      setDeliveryDate(shippingDetails.deliveryDate ? offsetDate(new Date(shippingDetails.deliveryDate)) : null);
      setPickupDate(shippingDetails.pickUpDate ? offsetDate(new Date(shippingDetails.pickUpDate)) : null);
      setBillOfLading(shippingDetails.billOfLading || false);
    }
  }, [shippingDetails]);

  return (
    <>
      <Button onClick={handleOpen}>Shipping Details</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Shipping Details
          </Typography>

          <Select
            labelId="vendor-label"
            id="vendor-select"
            value={selectedVendor || ""}
            onChange={handleVendorChange}
            label="Unit"
            sx={{ padding: '0px', maxHeight: "45px" }}
          >
            {availableVendors.map((vm) => (
              <MenuItem key={vm.vmId} value={vm.vmName}>
                {vm.vmName}
              </MenuItem>
            ))}
          </Select>

          <div style={{ paddingTop: "20px" }}>
            <div>
              <Typography><b>Primary Contact</b>: {vendorPrimaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorPrimaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorPrimaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <div>
              <Typography><b>Secondary Contact</b>: {vendorSecondaryContact}</Typography>
              <Typography><b>Phone</b>: {vendorSecondaryContactPhone}</Typography>
              <Typography><b>Email</b>: {vendorSecondaryContactEmail}</Typography>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ minWidth: "35%" }}>
              Step Deck
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={stepDeck}
              exclusive
              onChange={handleStepDeckChange}
              aria-label="Step Deck"
              sx={{ paddingLeft: "10px" }}
            >
              <ToggleButton color="success" value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={handlePickupDate} value={dayjs(pickupDate)} label={"Pickup Date and Time"} />
            </LocalizationProvider>
          </div>

          <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={handleDeliveryDate} value={dayjs(deliveryDate)} label={"Delivery Date and Time"} />
            </LocalizationProvider>
          </div>

          <div style={{ display: "flex", alignItems: "center", paddingBottom: "40px" }}>
            <Typography variant="h6" style={{ minWidth: "35%" }}>
              Bill of Lading
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={billOfLading}
              exclusive
              onChange={handleBillOfLading}
              aria-label="Bill of Lading"
              sx={{ paddingLeft: "10px" }}
            >
              <ToggleButton color="success" value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </div>

          {/* <Typography>Josh confirmation checkmark</Typography> */}

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveShippingDetails}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Shipping details saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function PtoAddModal({ onNoteAdded }) {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const activeAccount = instance.getActiveAccount();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const saveNote = () => {
    const userName = name;

    const newNote = {
      UserName: userName,
      Notes: note,
      DateTime: new Date(new Date().getTime()),
      ProjectId: searchParams.get("id")
    };

    setLoading(true);

    axios.post("/api/ActivityFeed/saveNote", newNote)
      .then(() => {
        setNote("");
        setSnackbarOpen(true);
        onNoteAdded();
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving note. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleModalClose();
      });
  };

  return (
    <>
      <Button onClick={handleModalOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add Note
          </Typography>
          <TextField
            multiline
            label="enter message"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            style={{ width: "100%", paddingBottom: "30px" }}
          />
          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}
            
            <Button style={{ color: "green" }} onClick={saveNote} disabled={loading}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Note saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

import { Box, Button, Grid, Typography } from "@mui/material";
import frdarklogo from "../res/dog.png";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <Grid container justifyContent="center" style={{ padding: "10px" }}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "70vh",
          }}
        >
          <Typography variant="h1" sx={{ paddingTop: "30px" }}>
            404
          </Typography>
          <Typography variant="h6" sx={{ paddingBottom: "20px" }}>
            The page you're looking for doesn't exist.
          </Typography>
          <Button variant="contained" onClick={handleButtonClick}>
            Back Home
          </Button>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <img
            src={frdarklogo}
            alt="Fire Rover Logo"
            style={{ width: "100%", borderRadius: "10%" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

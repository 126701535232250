import React, { useState, useEffect } from "react";
import { Grid, Fade, Modal, Button} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import dayjs from "dayjs";
import EditTimeSheetLog from "./EditTimeSheetLog";

export default function TimeSheetDetails(logs) {

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [timeLog, setTimeLog] = useState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleOpenEditModal = (log) => {
    if (logs.denyChanges === false) {
      setTimeLog(log);
      setIsEditModalOpen(true);
    }
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    fetchData();
  }

  const fetchData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/timeSheet';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [logs.listener]);   

  const loadRows = (timeSheet) => {

    let newRows = [];
    if (timeSheet.length > 0) {
      timeSheet.forEach(tl => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        const tConvert = (time) => {
          const i = tl.timesheetDate.split("/");
          const t = new Date(i[0]+'T' + time + 'Z');
          return t;
        }
        if (tl.empUserId === logs.employeeId && dayjs(logs.weekStart) <= dayjs(tl.timesheetDate) && dayjs(logs.weekEnd) >= dayjs(tl.timesheetDate)) {
          newRows.push({
            id: tl.timesheetId,
            date: tl.timesheetDate? offsetDate(new Date(tl.timesheetDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }) : "-",
            day: tl.tsDay ? tl.tsDay : "-",
            userID: tl.empUserId,
            clockIn: tl.clockIn ? tConvert(tl.clockIn).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            }) : "-",
            clockOut: tl.clockOut ? tConvert(tl.clockOut).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            }) : "-",
            hourType: tl.hourType,
            hours: tl.hoursWorked,
            description: tl.tsDescription? tl.tsDescription : "-",
          })
        }
      })
    }
    setRows(newRows);
  }

  const renderEditButton = (params) => {
      return (
          <strong>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={logs.denyChanges}
                  onClick={() => {
                      handleOpenEditModal(params.row)
                  }}
              >
                  Edit
              </Button>
          </strong>
      )
  }

  const columns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "day", headerName: "Day", width: 150 },
    { field: "clockIn", headerName: "Clock In", width: 150 },
    { field: "clockOut", headerName: "Clock Out", width: 150 },
    { field: "hourType", headerName: "Hour Type", width: 150 },
    { field: "hours", headerName: "Hours", width: 150 },
    { field: "description", headerName: "Description", width: 150 },
    {
      width: 100,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,
    }
  ];

  return (
    <Grid>
      <DataGrid onRowDoubleClick={(params) => handleOpenEditModal(params.row)} rows={rows} columns={columns} 
        initialState={{ pagination: { paginationModel: { pageSize: 25 } }, columns: {columnVisibilityModel: {}} } }
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
          },
          "& .MuiDataGrid-row:hover": {
              cursor: "pointer"
          }
        }}
        state={{
          showSkeletons: loading,
        }}
      />
      <Modal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          closeAfterTransition
        >
          <Fade in={isEditModalOpen}>
            <div>
              <EditTimeSheetLog timeLog={timeLog} onUpdateTimeSheetLog={handleCloseEditModal} updateSheet={logs.updateSheet}/>
            </div>
          </Fade>
        </Modal>
    </Grid>
  );
};
import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, InputAdornment, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { projectManageAxios as axios } from "../../../utils/AxiosUtility";
import DeleteInvoice from "./DeleteInvoice";
import NumericInput from "../../../components/NumericInput";

export default function EditInvoice({ invoice, onUpdateInvoice, openSnackbar }) {
    const [loading, setLoading] = useState(false);
    const [invoiceType, setInvoiceType] = useState("");
    const [invoiceCompleted, setInvoiceCompleted] = useState(false);
    const [invoiceClass, setInvoiceClass] = useState("");
    const [markup, setMarkup] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [billableAmount, setBillableAmount] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const availableExpenses = [
        {
            id: 0,
            type: "Billable Expense",
        },
        {
            id: 1,
            type: "Non Billable Expense",
        },
    ]

    const availableInvoiceTypes = [
        {
            id: 0,
            type: "Lift",
        },
        {
            id: 1,
            type: "Crane",
        },
        {
            id: 2,
            type: "Shipping",
        },
        {
            id: 3,
            type: "Nitro",
        },
        {
            id: 4,
            type: "Permit Charges",
        },
        {
            id: 5,
            type: "Special Install",
        },
        {
            id: 6,
            type: "Remobilization",
        },
        {
            id: 7,
            type: "Other",
        },
    ]

    useEffect(() => {
    if (invoice) {
        setInvoiceCompleted(invoice.invoiceCompleted || false);
        setInvoiceType(invoice.invoiceType || "");
        setInvoiceClass(invoice.invoiceClass || "");
        setMarkup(invoice.markup || "");
        setDescription(invoice.description || "");
        setAmount(invoice.amount || "");
        setReferenceNumber(invoice.invoiceReference || "");
        setBillableAmount(invoice.billableAmount || "");
    }
    // eslint-disable-next-line
}, [invoice]);

    const handleInvoiceType = (event) => {
        if (event.target.value === "Non Billable Expense") {
            setMarkup("");
        }
        setInvoiceType(event.target.value);
    };

    const handleInvoiceClass = (event) => {
        setInvoiceClass(event.target.value);
    };

    const handleMarkup = (event) => {
        const newMarkup = event.target.value;
        setMarkup(newMarkup);
    
        if (invoiceType === "Billable Expense" && newMarkup !== "") {
            const calculatedMarkup = parseFloat(amount) + (parseFloat(newMarkup) / 100) * parseFloat(amount);
            setBillableAmount(calculatedMarkup.toFixed(2));
        } else {
            setBillableAmount(amount);
        }
    };
    
    const handleAmount = (event) => {
        const newAmount = event.target.value;
        setAmount(newAmount);
    
        if (invoiceType === "Billable Expense" && markup !== "") {
            const calculatedMarkup = parseFloat(newAmount) + (parseFloat(markup) / 100) * parseFloat(newAmount);
            setBillableAmount(calculatedMarkup.toFixed(2));
        } else {
            setBillableAmount(newAmount);
        }
    };

    const handleInvoiceCompleted = (event, complete) => {
        setInvoiceCompleted(complete);
    }

    const handleSave = () => {
        setLoading(true);
    
        const updatedInvoice = {
            invoiceDetailId: invoice.invoiceDetailId,
            projectId: searchParams.get("id"),
            invoiceCompleted: invoiceCompleted,
            invoiceType: invoiceType,
            class: invoiceClass,
            invoiceDescription: description,
            amount: amount,
            invoiceReference: referenceNumber,
        };
    
        if (markup !== null && markup !== "") {
            updatedInvoice.markup = markup;
        }
    
        if (billableAmount !== null && billableAmount !== "") {
            updatedInvoice.billableAmount = billableAmount;
        }
    
        axios
            .put('api/Coordination/updateInvoice', updatedInvoice)
            .then((response) => {
                onUpdateInvoice();
                openSnackbar();
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.data) {
                    alert(error.response.data);
                } else {
                    alert("Error updating invoice. Please try again.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };    

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Button
                    variant="outlined"
                    onClick={onUpdateInvoice}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    X
                </Button>

                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
                    Edit Invoice
                </Typography>

                <Box>
                <Select
                    labelId="billable-label"
                    id="billable-select"
                    value={invoiceType}
                    onChange={handleInvoiceType}
                    label="Billable Type"
                    sx={{ marginBottom: "15px", maxHeight: "45px" }}
                >
                    {availableExpenses.map((expense) => (
                        <MenuItem key={expense.id} value={expense.type}>
                            {expense.type}
                        </MenuItem>
                    ))}
                </Select>
                </Box>

                <Box>
                {invoiceType === "Billable Expense" ?
                    <TextField
                        label="Markup"
                        style={{ marginBottom: "15px" }}
                        value={markup}
                        onChange={handleMarkup}
                    /> : null}
                    </Box>

                <Box>
                <Select
                    labelId="class-label"
                    id="class-select"
                    value={invoiceClass}
                    onChange={handleInvoiceClass}
                    label="Invoice Class"
                    sx={{ marginBottom: "15px", maxHeight: "45px" }}
                >
                    {availableInvoiceTypes.map((invoice) => (
                        <MenuItem key={invoice.id} value={invoice.type}>
                            {invoice.type}
                        </MenuItem>
                    ))}
                </Select>
                </Box>

                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ width: "100%", marginBottom: "20px" }}
                />

                <TextField
                    label="Amount"
                    value={amount}
                    onChange={handleAmount}
                    name="amount"
                    id="amount"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                        ),
                        inputComponent: NumericInput,
                    }}
                    variant="outlined"
                    sx={{ marginBottom: "20px" }}
                />

                <TextField
                    type="tel"
                    label="Invoice Reference Number"
                    value={referenceNumber}
                    onChange={(e) => setReferenceNumber(e.target.value)}
                    style={{ width: "100%", marginBottom: "20px" }}
                />

                <Stack direction="row" alignItems="center" style={{ width: "100%", marginBottom: "80px" }}>
                    <Typography
                        variant="h6"
                        style={{ width: "35%" }}
                    >
                        Item Completed
                    </Typography>

                    <ToggleButtonGroup
                        color="primary"
                        value={invoiceCompleted}
                        exclusive
                        onChange={handleInvoiceCompleted}
                        aria-label="Completed"
                        style={{ flexGrow: '1', minWidth: '60%' }}
                    >
                        <ToggleButton color="success" value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    {loading && <CircularProgress />}

                   <DeleteInvoice invoiceDetailId={invoice.invoiceDetailId} onInvoiceDeleted={onUpdateInvoice} />

                    <Button
                        style={{ color: "green", marginTop: "10px" }}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </>
    );
}

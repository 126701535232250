import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Alert, Box, Grid, Snackbar, ToggleButton, ToggleButtonGroup, Stack, Button, MenuItem, FormControl, InputLabel, Select, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export function SitePrepTask() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState(null);
  const [taskID, setTaskID] = useState("");
  const [pm, setPM] = useState("Allen Yokom");
  const [kickoffDate, setKickoffDate] = useState();
  const [kickoffComplete, setKickoffComplete] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [padInstall, setPadInstall] = useState("no");
  const [power, setPower] = useState(false);
  const [internet, setInternet] = useState(false);
  const [padVer, setPadVer] = useState(false);
  const [powerVer, setPowerVer] = useState(false);
  const [internetVer, setInternetVer] = useState(false);
  const [install, setInstall] = useState(null);
  const [installReq, setInstallReq] = useState(null);
  const [buildStatus, setBuildStatus] = useState(null);
  const [permitReq, setPermitReq] = useState(false);
  const [permitComplete, setPermitComplete] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogBuildOpen, setDialogBuildOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePmSelect = (event) => {
    setPM(event.target.value);
  };

  const handleKickoffCom = (event, val) => {
    if (val !== null) {
      setKickoffComplete(val);
    }
  };

  const handleDeposit = (event, val) => {
    if (val !== null) {
      setDeposit(val);
    }
  };

  const handlePadChange = (event, val) => {
    if (val !== null) {
      setPadInstall(val);
    }
  };

  const handlePowerChange = (event, val) => {
    if (val !== null) {
      setPower(val);
    }
  };

  const handleInternetChange = (event, val) => {
    if (val !== null) {
      setInternet(val);
    }
  };

  const handlePermitComplete = (event, val) => {
    if (val !== null) {
      setPermitComplete(val);
    }
  };

  const handleInstallReq = () => {
    setInstallReq(new Date());

    const endpoint = 'api/Projects/requestInstall/' + searchParams.get("id");
    axios.get(endpoint)
      .then((response) => {
        handleDialogClose();
      })
      .catch((error) => {
        console.log(error);
        alert("Error updating task.");
      });
  };

  const handleBuildReq = () => {
    setBuildStatus(true);

    const endpoint = 'api/Projects/requestBuild/' + searchParams.get("id");
    axios.get(endpoint)
      .then((response) => {
        handleDialogBuildClose();
      })
      .catch((error) => {
        console.log(error);
        alert("Error updating task.");
      });
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogBuildOpen = () => {
    setDialogBuildOpen(true);
  };

  const handleDialogBuildClose = () => {
    setDialogBuildOpen(false);
  };

  const availablePMs = [
    {
      id: 0,
      name: "Allen Yokom",
    },
    {
      id: 1,
      name: "Kiley Galesewicz",
    },
    {
      id: 2,
      name: "Maurice Johnson",
    },
    {
      id: 3,
      name: "Roman Dowhaniuk",
    },
  ]

  const handleSubmit = () => {
    let updatedTask = {
      "projectTaskSpId": taskID,
      "projectId": searchParams.get("id"),
      "pmAssigned": pm ?? "Allen Yokom",
      "siteKickoffCallDate": dayjs(kickoffDate),
      "siteKickoffCallCompleted": kickoffComplete,
      "padInstall": padInstall,
      "powerInstalledDate": power ? new Date(new Date().getTime()) : null,
      "internetInstalledDate": internet ? new Date(new Date().getTime()) : null,
      "depositReceivedDate": deposit ? new Date(new Date().getTime()) : null,
      "permitCompletedDate": permitComplete ? new Date(new Date().getTime()) : null
    }
    updatedTask = { ...project, ...updatedTask }

    axios.post("/api/Projects/updateSpTask", updatedTask)
      .then((response) => {
        setShowSnackbar(true);
      })
      .catch((error) => {
        console.log(error);
        alert("Error updating task.");
      });
  };

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  const loadParams = (project) => {
    setTaskID(project["projectTaskSpId"]);
    setDeposit(project["depositReceivedDate"] ? true : false);
    setKickoffComplete(project["siteKickoffCallCompleted"]);
    setKickoffDate(project["siteKickoffCallDate"] ? offsetDate(new Date(project["siteKickoffCallDate"])) : null);
    setPadInstall(project["padInstall"] ?? "no");
    setPower(project["powerInstalledDate"] ? true : false);
    setInternet(project["internetInstalledDate"] ? true : false);
    setPadVer(project["padVerifiedDate"] ? true : false);
    setPowerVer(project["powerVerifiedDate"] ? true : false);
    setInternetVer(project["internetVerifiedDate"] ? true : false);
    setInstall(project["installDate"] ? offsetDate(new Date(project["installDate"])) : null);
    setInstallReq(project["installRequest"] ? offsetDate(new Date(project["installRequest"])) : null);
    setBuildStatus(project["buildStatus"]);
    setPermitReq(project["permitRequired"] ? project["permitRequired"] : false);
    setPermitComplete(project["permitCompletedDate"] ? true : false);
    setPM(project["pmAssigned"]);
    setProject(project);
    setLoading(false);
  };

  const fetchData = () => {
    const endpoint = 'api/Projects/sptask/' + searchParams.get("id");
    setLoading(true);
    axios.get(endpoint)
      .then((response) => {
        loadParams(response.data);
      })
      .catch((error) => {
        console.log(error)
        alert("Error retreiving data.");
      });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ?
        <Box sx={{ minHeight: "1137px" }} justifyContent="center" alignContent="center">
          <CircularProgress variant="indeterminate" />
        </Box>
        :
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Tasks
          </Typography>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Lead Project Manager
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <FormControl sx={{ width: "60%" }}>
                  <InputLabel id="pm-label">PM</InputLabel>
                  <Select
                    labelId="pm-label"
                    id="pm-select"
                    value={pm}
                    label="Unit"
                    onChange={handlePmSelect}
                    sx={{ padding: '0px' }}
                  >
                    {availablePMs.map((pm) => (
                      <MenuItem key={pm.id} value={pm.name}>
                        {pm.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Kickoff Call
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                {!kickoffComplete
                  ?
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"Kickoff Call Date"}
                    onChange={setKickoffDate}
                    value={dayjs(kickoffDate)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
                  :
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"Kickoff Call Date"}
                    onChange={setKickoffDate}
                    value={dayjs(kickoffDate)}
                    disabled
                  />
                </LocalizationProvider>
                }
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Kickoff Complete
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <ToggleButtonGroup
                  color="primary"
                  value={kickoffComplete}
                  exclusive
                  onChange={handleKickoffCom}
                  aria-label="Kickoff Call Completed"
                  sx={{ paddingRight: "10%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Deposit
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={deposit}
                exclusive
                onChange={handleDeposit}
                aria-label="Deposit"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Pad
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={padInstall}
                exclusive
                onChange={handlePadChange}
                aria-label="Pad Install"
                style={{ flexGrow: '1' }}
              >
                <ToggleButton color="success" value={"yes"}>Yes</ToggleButton>
                <ToggleButton value={"no"}>No</ToggleButton>
                <ToggleButton color="warning" value={"n/a"}>N/A</ToggleButton>
              </ToggleButtonGroup>

              <Box style={{ minWidth: '60%', paddingLeft: "10%" }}>
                {padVer
                  ?
                  <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                  :
                  <Typography color="red">Unverified <ClearIcon style={{ verticalAlign: "middle" }} /></Typography>
                }
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Power
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={power}
                exclusive
                onChange={handlePowerChange}
                aria-label="Power Install"
                style={{ flexGrow: '1' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>

              <Box style={{ minWidth: '60%', paddingLeft: "10%" }}>
                {powerVer
                  ?
                  <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                  :
                  <Typography color="red">Unverified <ClearIcon style={{ verticalAlign: "middle" }} /></Typography>
                }
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Internet
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={internet}
                exclusive
                onChange={handleInternetChange}
                aria-label="Internet Install"
                style={{ flexGrow: '1' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>

              <Box style={{ minWidth: '60%', paddingLeft: "10%" }}>
                {internetVer
                  ?
                  <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                  :
                  <Typography color="red">Unverified <ClearIcon style={{ verticalAlign: "middle" }} /></Typography>
                }
              </Box>
            </Stack>
          </Grid>

          {permitReq
            ?
            <Grid item xs={12} style={{ paddingBottom: '5%' }}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h6"
                  style={{ minWidth: '35%' }}
                >
                  Permit Complete
                </Typography>

                <ToggleButtonGroup
                  color="primary"
                  value={permitComplete}
                  exclusive
                  onChange={handlePermitComplete}
                  aria-label="Permit Compelete"
                  style={{ flexGrow: '1' }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>
            :
            <></>
          }

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Build Request
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                {buildStatus === null
                  ?
                  <Button variant="contained" onClick={handleDialogBuildOpen}>Request</Button>
                  :
                  <Button variant="contained" disabled>Request Sent</Button>
                }
                <Dialog open={dialogBuildOpen} onClose={handleDialogBuildClose}>
                  <DialogTitle>Request Build</DialogTitle>
                  <DialogContent>
                    Are you sure you want to request build?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogBuildClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleBuildReq} color="success">
                      Request
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Install Request
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                {installReq === null
                  ?
                  <Button variant="contained" onClick={handleDialogOpen}>Request</Button>
                  :
                  <Button variant="contained" disabled>Request Sent on {installReq.toLocaleDateString()}</Button>
                }
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                  <DialogTitle>Request Install</DialogTitle>
                  <DialogContent>
                    Are you sure you want to request install?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleInstallReq} color="success">
                      Request
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Stack>
          </Grid>

          {installReq
            ?
            <Grid item xs={12} style={{ paddingBottom: '5%' }}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h6"
                  style={{ minWidth: '35%' }}
                >
                  Install Date
                </Typography>

                {install ?
                  <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={"Install Date"}
                        value={dayjs(install)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Box>
                  :
                  <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                    <TextField value={"Not yet set"} disabled />
                  </Box>
                }
              </Stack>
            </Grid>
            :
            <></>
          }

          <Grid item xs={12} style={{ paddingBottom: "1%" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSubmit}>Save</Button>
            </Box>
          </Grid>

          <Snackbar
            open={showSnackbar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Saved Successfully!
            </Alert>
          </Snackbar>
        </>
      }
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, TextField, Typography, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { projectManageAxios as axios } from "../../utils/AxiosUtility";
import { useMsal } from "@azure/msal-react";
import DeleteContact from "./DeleteContact";

export default function EditContact({ contact, onUpdateContact, onContactDeleted, onClose }) {
    const [editedContact, setEditedContact] = useState(contact);
    const { instance } = useMsal();
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [name, setName] = useState(null);
    const activeAccount = instance.getActiveAccount();

    const contactTypes = [
        {
          id: 0,
          name: "Non IT",
        },
        {
          id: 1,
          name: "IT",
        }
      ]

    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name.split(' ')[0]);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    const handleSave = () => {
        setLoading(true);

        axios
            .put('/api/Onboarding/updateContact', editedContact)
            .then(() => {
                onUpdateContact();
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.data) {
                    alert(error.response.data);
                } else {
                    alert("Error updating contact. Please try again.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: 320,
                    width: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Button
                    variant="outlined"
                    onClick={onClose}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    X
                </Button>

                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
                    Edit Contact
                </Typography>

                <TextField
                    label="Name"
                    value={editedContact.csContactName}
                    onChange={(e) => setEditedContact({ ...editedContact, csContactName: e.target.value })}
                />

                <TextField
                    label="Title"
                    value={editedContact.csContactRole}
                    onChange={(e) => setEditedContact({ ...editedContact, csContactRole: e.target.value })}
                />

                <TextField
                    label="Phone"
                    value={editedContact.csContactPhone}
                    onChange={(e) => setEditedContact({ ...editedContact, csContactPhone: e.target.value })}
                />

                <TextField
                    label="Email"
                    value={editedContact.csContactEmail}
                    onChange={(e) => setEditedContact({ ...editedContact, csContactEmail: e.target.value })}
                />

                <FormControl sx={{ width: "40%" }}>
                    <InputLabel id="contact-type-label">Contact Type</InputLabel>
                    <Select
                    labelId="contact-type-label"
                    id="contact-type-select"
                    value={editedContact.csContactType}
                    label="Contact Type"
                    onChange={(e) => setEditedContact({ ...editedContact, csContactType: e.target.value })}
                    sx={{ padding: '0px', maxHeight: "45px" }}
                    >
                    {contactTypes.map((ct) => (
                        <MenuItem key={ct.id} value={ct.name}>
                        {ct.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    {loading && <CircularProgress />}

                    <DeleteContact
                        contactId={contact.csContactId}
                        onContactDeleted={onContactDeleted}
                    />

                    <Button
                        style={{ color: "green" }}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </>
    );
}

import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Stack, Skeleton } from '@mui/material';
import { projectManageAxios as axios } from '../utils/AxiosUtility';

export default function Statistics() {
    const [statistics, setStatistics] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        axios.get('api/ProjectManagement/allUpcomingEvents')
            .then((response) => {
                setStatistics(response.data);
            })
            .catch((error) => {
                console.log(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching statistics.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const statisticsData = [
        { header: "Onboarding", key: "obEvents", statusKey: "statusOb" },
        { header: "Site Prep", key: "spEvents", statusKey: "statusSp" },
        { header: "Coordination", key: "scEvents", statusKey: "statusSc" },
        { header: "Site Visit", key: "siteVisitComplete", statusKey: "siteVisitComplete" },
        { header: "Kick Off Call", key: "siteKickoffCallCompleted", statusKey: "siteKickoffCallCompleted" },
        { header: "Preinstall Call", key: "preInstallationConfirmation", statusKey: "preInstallationConfirmation" },
        { header: "FR Tutorial", key: "customerHandoffCallCompleted", statusKey: "customerHandoffCallCompleted" },
    ];

    const getCountFromEvents = (events, key, statusKey) => {
        if (key === "siteVisitComplete") {
            return events["obEvents"].filter(data => {
                if (data.event[statusKey]) {
                    return data.event[statusKey] === "Completed" || data.event[statusKey] === true;
                } else {
                    return false;
                }
            }).length;
        };

        if (key === "siteKickoffCallCompleted") {
            return events["spEvents"].filter(data => {
                if (data.event[statusKey]) {
                    return data.event[statusKey] === "Completed" || data.event[statusKey] === true;
                } else {
                    return false;
                }
            }).length;
        };

        if (key === "preInstallationConfirmation") {
            return events["scEvents"].filter(data => {
                if (data.event[statusKey]) {
                    return data.event[statusKey] === "Completed" || data.event[statusKey] === true;
                } else {
                    return false;
                }
            }).length;
        };

        if (key === "customerHandoffCallCompleted") {
            return events["scEvents"].filter(data => {
                if (data.event[statusKey]) {
                    return data.event[statusKey] === "Completed" || data.event[statusKey] === true;
                } else {
                    return false;
                }
            }).length;
        };

        if (!events || !Array.isArray(events[key])) {
            return 0;
        };
        return events[key].filter(data => {
            if (statusKey && data.event[statusKey]) {
                return data.event[statusKey] === "Completed" || data.event[statusKey] === true;
            } else {
                return false;
            }
        }).length;
    };

    return (
        <div>
            <Typography variant="h5" align="center" gutterBottom>
                Overall Statistics
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {statisticsData.map((item, index) => (
                    <Grid item xs={4} key={index}>
                        <Stack>
                            <Typography
                                variant="h7"
                                align="center"
                                style={{ padding: "20px", fontWeight: "bold" }}
                            >
                                {item.header}
                            </Typography>

                            {loading ? (
                                <Skeleton variant="text" animation="wave" width="100%" height={50} />
                            ) : (
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ padding: "10px" }}
                                >
                                    {getCountFromEvents(statistics, item.key, item.statusKey)}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

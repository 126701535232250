import React, { useState, useEffect } from "react";
import { Fade, Modal, Skeleton, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import AddContactModal from "./AddContactModal";
import EditContact from "./EditContact";
import { Snackbar, Alert } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function ContactsList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchContacts = () => {
    setLoading(true);
    axios
      .get(`/api/Onboarding/siteLevelContacts/${searchParams.get("id")}`)
      .then((response) => {
        const contactsData = Array.isArray(response.data) ? response.data : [];
        setData(contactsData);
      })
      .catch((error) => {
        console.log(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching contact list.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line
  }, []);

  const handleContactAdded = () => {
    fetchContacts();
  };

  const handleRowDoubleClick = (contact) => {
    setEditingContact(contact);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    fetchContacts();
  };

  const handleActionCompleted = (message) => {
    setSnackbarMessage(message);
    setIsEditModalOpen(false);
    setSnackbarOpen(true);
    fetchContacts();
  };

  return (
    <div>
      <Typography
        variant="h5"
        gutterBottom
        style={{ display: "flex", alignItems: "center" }}
      >
        <div style={{ paddingLeft: "33.5%" }}>Contact List</div>
        <div style={{ flex: 1 }}></div>
        <AddContactModal onContactAdded={handleContactAdded} />
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Contact Type</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <div style={{ overflowY: "scroll", maxHeight: "300px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              {loading ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Skeleton animation="wave" variant="circular" width={24} height={24} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton animation="wave" variant="text" width={150} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton animation="wave" variant="text" width={100} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    onDoubleClick={() => handleRowDoubleClick(row)}
                  >
                    <TableCell align="center">{row.csContactName}</TableCell>
                    <TableCell align="center">{row.csContactRole}</TableCell>
                    <TableCell align="center">{row.csContactPhone}</TableCell>
                    <TableCell align="center">{row.csContactEmail}</TableCell>
                    <TableCell align="center">{row.csContactType}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Modal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        closeAfterTransition
      >
        <Fade in={isEditModalOpen}>
          <div>
            <EditContact
              contact={editingContact}
              onUpdateContact={() => handleActionCompleted("Contact Updated!")}
              onContactDeleted={() => handleActionCompleted("Contact Deleted!")}
              onClose={() => handleCloseEditModal()}
            />
          </div>
        </Fade>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

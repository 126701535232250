import React from "react";

export default function ProgressCell({ params }) {
    const barWidth = 130;
    const barHeight = 20;
    const filledWidth = (params / 100) * barWidth;
    const emptyWidth = barWidth - filledWidth;
  
    return (
      <div style={{ width: barWidth, height: barHeight, border: "1px solid #e0e0e0", position: "relative" }}>
        <div style={{ width: filledWidth, height: barHeight, backgroundColor: "#35CB82", position: "absolute", top: -1, left: -1 }} />
        <div style={{ width: emptyWidth, height: barHeight, position: "absolute", top: 0, right: 0 }} />
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontWeight: "bold" }}>
          {`${params}%`}
        </div>
      </div>
    );
  }
import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, MenuItem, Modal, Snackbar, CircularProgress, Typography, Select, Stack, TextField, InputLabel } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from "../utils/AxiosUtility";

export default function EditSiteInfoModal({ siteInfoData, reload }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [siteInfo, setSiteInfo] = useState(siteInfoData);
    const [acctManager, setAcctManager] = useState(siteInfo.csAccountManager !== null ? siteInfo.csAccountManager : "");
    const [centralStation, setCentralStation] = useState(siteInfo.centralStation !== null ? siteInfo.centralStation : "");


    useEffect(() => {
        setAcctManager(siteInfo.csAccountManager !== null ? siteInfo.csAccountManager : "");
        setCentralStation(siteInfo.centralStation !== null ? siteInfo.centralStation : "");
    }, [siteInfo]);

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => {
        let newSiteInfo = { ...siteInfoData };
        setSiteInfo(newSiteInfo);
        setOpen(false);
    }
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const saveSiteInfo = () => {
        console.log(siteInfo);
        setLoading(true);
        axios.post("/api/Projects/updateSite", siteInfo)
            .then((response) => {
                setSnackbarOpen(true);
                reload();
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Error updating task.");
            });
    };

    const handleValChange = (type) => (event, val) => {
        if (val !== null) {
            let newSiteInfo = { ...siteInfo };
            newSiteInfo[type] = event.target.value;
            setSiteInfo(newSiteInfo);
        }
    };

    const handleAcctManagerSelect = (event) => {
        setAcctManager(event.target.value);
        let newSiteInfo = {...siteInfo};
        newSiteInfo['csAccountManager'] = event.target.value;
        setSiteInfo(newSiteInfo);
    };

    const handleCentralStationSelect = (event) => {
        setCentralStation(event.target.value);
        let newSiteInfo = {...siteInfo};
        newSiteInfo['centralStation'] = event.target.value;
        setSiteInfo(newSiteInfo);
    };

    const acctManagerOptions = [
        {
            id: 0,
            name: "Sasha Hartje",
        },
        {
            id: 1,
            name: "Joe Chudy",
        },
        {
            id: 2,
            name: "Jeff Bramlage",
        },
    ];

    const centralStationOptions = [
        {
            id: 0,
            name: "COPS",
        },
        {
            id: 1,
            name: "Acadian",
        },
    ];

    return (
        <>
            <Button onClick={handleModalOpen}>Edit</Button>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "35vw",
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

                    <Stack sx={{ paddingBottom: "10px", paddingTop: "5%" }} direction={"row"}>
                        <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "22%" }}>
                            <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                Address 1:&nbsp;
                            </Typography>
                        </div>
                        <div style={{ paddingBottom: "10px", minWidth: "80%" }}>
                            <TextField
                                fullWidth
                                label="Address1"
                                name="address1"
                                value={siteInfo["csAddress1"]}
                                onChange={handleValChange("csAddress1")}
                            />
                        </div>
                    </Stack>

                    <Stack sx={{ paddingBottom: "10px" }} direction={"row"}>
                        <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "22%" }}>
                            <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                Address 2:&nbsp;
                            </Typography>
                        </div>
                        <div style={{ paddingBottom: "10px", minWidth: "80%" }}>
                            <TextField
                                fullWidth
                                label="Address2"
                                name="address2"
                                value={siteInfo["csAddress2"]}
                                onChange={handleValChange("csAddress2")}
                            />
                        </div>
                    </Stack>

                    <Stack sx={{ paddingBottom: "10px" }} direction={"row"}>
                        <Stack sx={{ minWidth: "40%", paddingRight: "5%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "40%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    State:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "60%" }}>
                                <TextField
                                    label="State"
                                    name="state"
                                    value={siteInfo["csState"]}
                                    onChange={handleValChange("csState")}
                                />
                            </div>
                        </Stack>

                        <Stack sx={{ minWidth: "60%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "30%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    City:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "70%" }}>
                                <TextField
                                    label="City"
                                    name="city"
                                    value={siteInfo["csCity"]}
                                    onChange={handleValChange("csCity")}
                                />
                            </div>
                        </Stack>
                    </Stack>

                    <Stack sx={{ paddingBottom: "10px" }} direction={"row"}>
                        <Stack sx={{ minWidth: "40%", paddingRight: "5%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "40%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    Zip:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "60%" }}>
                                <TextField
                                    label="Zip"
                                    name="zip"
                                    value={siteInfo["csZip"]}
                                    onChange={handleValChange("csZip")}
                                />
                            </div>
                        </Stack>

                        <Stack sx={{ minWidth: "60%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "30%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    Country:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "70%" }}>
                                <TextField
                                    label="Country"
                                    name="country"
                                    value={siteInfo["csCountry"]}
                                    onChange={handleValChange("csCountry")}
                                />
                            </div>
                        </Stack>
                    </Stack>
                    
                    <Stack sx={{ paddingBottom: "10px" }} direction={"row"}>
                        <Stack sx={{ minWidth: "85%", paddingRight: "5%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "50%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    Account Manager:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "60%" }}>
                                <Box style={{flexGrow: "1", minWidth: "60%"}}>
                                    <FormControl sx={{width: "79%"}}>
                                        <InputLabel id="am-label">Acct. Manager</InputLabel>
                                        <Select
                                            labelId="am-label"
                                            id="am-select"
                                            value={acctManager}
                                            label="Acct. Manager"
                                            onChange={handleAcctManagerSelect}
                                            sx={{padding: "0px"}}
                                        >
                                            {acctManagerOptions.map((am) => (
                                                <MenuItem key={am.id} value={am.name}>
                                                    {am.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </Stack>
                    </Stack>

                    <Stack sx={{paddingBottom: "10px"}} direction={"row"}>
                        <Stack sx={{ minWidth: "80%" }} direction={"row"}>
                            <div style={{ paddingBottom: "10px", paddingTop: "15px", minWidth: "50%" }}>
                                <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                                    Central Station:&nbsp;
                                </Typography>
                            </div>
                            <div style={{ paddingBottom: "10px", minWidth: "70%" }}>
                                <Box style={{flexGrow: "1", minWidth: "60%"}}>
                                    <FormControl sx={{width: "68%"}}>
                                        <InputLabel id="cs-label">Central Station</InputLabel>
                                        <Select
                                            labelId="cs-label"
                                            id="cs-select"
                                            value={centralStation}
                                            label="Central Station" 
                                            onChange={handleCentralStationSelect}
                                            sx={{padding: "0px"}}
                                        >
                                            {centralStationOptions.map((cs) => (
                                                <MenuItem key={cs.id} value={cs.name}>
                                                    {cs.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </Stack>
                    </Stack>

                    <div style={{ position: 'absolute', bottom: '10px', right: '10px', mt: 3 }}>
                        {loading && <CircularProgress />}

                        <Button style={{ color: "green" }} onClick={saveSiteInfo} disabled={loading}>
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Site Info saved!
                </MuiAlert>
            </Snackbar>
        </>
    );
}

import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography, MenuItem, FormControl, InputLabel, Select, Snackbar, CircularProgress } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { useSearchParams } from "react-router-dom";


export default function AddContactModal({ onContactAdded }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [contactType, setContactType] = useState("Non IT");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const saveContact = () => {
    const newContact = {
      csContactId: 0,
      csId: "",
      projectId: searchParams.get("id"),
      csContactName: name,
      csContactRole: title,
      csContactEmail: email,
      csContactPhone: phone,
      csContactPhoneType: "",
      csContactType: contactType,
    };

    setLoading(true);

    axios
      .post("/api/Onboarding/createContact", newContact)
      .then(() => {
        setName("");
        setTitle("");
        setPhone("");
        setEmail("");
        setSnackbarOpen(true);
        onContactAdded();
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error saving contact. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleContactType = (event) => {
    setContactType(event.target.value);
  };

  const contactTypes = [
    {
      id: 0,
      name: "Non IT",
    },
    {
      id: 1,
      name: "IT",
    }
  ]

  return (
    <>
      <Button onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add Contact
          </Typography>
          
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <TextField
            type="tel"
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <FormControl sx={{ width: "40%" }}>
            <InputLabel id="contact-type-label">Contact Type</InputLabel>
            <Select
              labelId="contact-type-label"
              id="contact-type-select"
              value={contactType}
              label="Contact Type"
              onChange={handleContactType}
              sx={{ padding: '0px', maxHeight: "45px" }}
            >
              {contactTypes.map((ct) => (
                <MenuItem key={ct.id} value={ct.name}>
                  {ct.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}
            
            <Button style={{ color: "green" }} onClick={saveContact} disabled={loading}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Contact saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

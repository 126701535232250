import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import { useMsal } from "@azure/msal-react";

export default function EditServiceLogNote({ note, onUpdateNote, openSnackbar }) {
  const [editedNote, setEditedNote] = useState(note?.note || "");
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleSave = () => {
    setLoading(true);

    axios
      .put('api/ServiceLog/updateServiceLogNote', {
        logId: note.logId,
        username: name,
        note: editedNote,
        logDate: new Date(new Date().getTime()),
      })
      .then((response) => {
        onUpdateNote();
        openSnackbar();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data) {
          alert(error.response.data);
        } else {
          alert("Error updating service log note. Please try again.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={onUpdateNote}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          X
        </Button>

        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
          Edit Service Log Note
        </Typography>

        <TextField
          multiline
          label="Enter message"
          value={editedNote}
          onChange={(e) => setEditedNote(e.target.value)}
        />

        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          {loading && <CircularProgress />}

          <Button
            style={{ color: "green" }}
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Box>
    </>
  );
}

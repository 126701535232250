import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { Grid, Tabs, Tab, Box, Card } from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';
import TempMainTable from "../../components/Manufacturing/ManMainTable";
import BuildList from "../../components/Manufacturing/BuildList";
import StockUnits from "../../components/Manufacturing/StockUnits";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function ManMainPage() {
    const [tabValue, setTabValue] = new useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const gridItemProps = {
        xs: 4,
        container: true,
        justifyContent: "center",
    };

    return (
        <>
            <AuthenticatedTemplate>
                <Grid
                    container
                    rowSpacing={4}
                    columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                    style={{ padding: "5px" }}
                >
                    <Grid item {...gridItemProps}></Grid>
                    <Grid item {...gridItemProps}></Grid>
                    <Grid item {...gridItemProps}>

                    </Grid>


                    <Grid item xs={12} style={{ padding: "25px" }}>
                        <Card>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: '5px' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="Install Coordination" {...tabProps(0)} />
                                        <Tab label="Build List" {...tabProps(1)} />
                                        <Tab label="Fire Rover Stock" {...tabProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={tabValue} index={0}>
                                    <TempMainTable />
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={1}>
                                    <BuildList />
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={2}>
                                    <StockUnits />
                                </CustomTabPanel>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}

import React, { useState } from "react";
import { Box, Modal, Stack, TextField, Button, Typography, Alert, Snackbar, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { useEffect } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonsStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
};

export function StockAssign({ refresh, stockName }) {
    const [open, setOpen] = React.useState(false);
    const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
    const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState('');

    useEffect(() => {
        const endpoint = 'api/Manufacturing/requestedProducts';
        axios.get(endpoint)
            .then((response) => {
                setProducts(response.data);
            })
            .catch((error) => {
                console.log(error);
                setSnackbarErrorOpen(true);
            });
        // eslint-disable-next-line
    }, []);

    const handleSnackbarSuccessClose = () => setSnackbarSuccessOpen(false);
    const handleSnackbarErrorClose = () => setSnackbarErrorOpen(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setProduct('');
        setOpen(false);
    };

    const handleSubmit = () => {
        if (product !== '') {
            const endpoint = `/api/Manufacturing/assignStock/${stockName}&${product}`;
            axios.post(endpoint)
                .then(() => {
                    setSnackbarSuccessOpen(true);
                    setProduct('');
                    setOpen(false);
                    refresh();
                })
                .catch((error) => {
                    console.error(error);
                    setSnackbarErrorOpen(true);
                    setProduct('');
                    setOpen(false);
                });
        }
        else {
            alert("Select a product first.")
        };
    }

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Assign</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>
                        Please select the product you would like to assign to this Stock unit.
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                        <TextField
                            label="Stock Unit"
                            value={stockName}
                            disabled
                            sx={{ mt: 2, paddingRight: "1%", width: "33%" }}
                        />

                        <FormControl sx={{ width: "40%" }}>
                            <InputLabel id="project-label" sx={{ mt: 2 }}>Products</InputLabel>
                            <Select
                                labelId="products-label"
                                id="products-select"
                                value={product}
                                label="Products"
                                onChange={(e) => { setProduct(e.target.value) }}
                                sx={{ mt: 2, padding: '0px' }}
                            >
                                {products.map((product) => (
                                    <MenuItem key={product} value={product}>
                                        {product}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>

                    <div style={buttonsStyle}>
                        <Button style={{ color: "green" }} onClick={handleSubmit}>Assign</Button>
                        <Button variant="contained" onClick={handleClose}>Close</Button>
                    </div>

                </Box>
            </Modal>
            <Snackbar open={snackbarSuccessOpen} autoHideDuration={3000} onClose={handleSnackbarSuccessClose}>
                <Alert onClose={handleSnackbarSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Stock product assigned!
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarErrorOpen} autoHideDuration={3000} onClose={handleSnackbarErrorClose}>
                <Alert onClose={handleSnackbarErrorClose} severity="error" sx={{ width: '100%' }}>
                    An error occured.
                </Alert>
            </Snackbar>
        </>
    );
}
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink, useLocation } from "react-router-dom";
import frdarklogo from "../../res/FRLogo_Black.png";
import DarkModeToggle from "./DarkModeToggle";
import { Box, Typography, Button } from "@mui/material";
import BreadcrumbTrail from "./BreadcrumbTrail";
import { useNavigate } from "react-router-dom";
import NavMenu from "./NavMenu";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBayAssignment = location.pathname.includes("BayAssignment");

  const defaultStyle = {
    height: "83.59px"
  };

  return (
    <AppBar
      position="sticky"
      color="secondary"
      sx={{ top: 0, zIndex: 100 }}
      style={isBayAssignment ? { ...defaultStyle, height: "50px", backgroundColor: "grey" } : defaultStyle}
    >
      <Toolbar 
        sx={{ justifyContent: "space-between" }}
        style={isBayAssignment ? {paddingBottom: "10px"} : {paddingBottom: "0px"} }
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavMenu />

          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            variant="h6"
            sx={{ paddingTop: "3%" }}
          >
            {!isBayAssignment ? <img src={frdarklogo} alt="Fire Rover Logo" height={"60px"} /> : ''}
          </Link>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", paddingLeft: "30px" }}
          >
            <BreadcrumbTrail />
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isBayAssignment ?
            <>
              <WelcomeName />
              <SignInSignOutButton />
            </>
            : ''
          }
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate(`/WIP`)}
              sx={{ ml: 1, height: "20px" }}
            >
              WIP
            </Button>
          </Box>
          {!isBayAssignment ? <DarkModeToggle /> : ''}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
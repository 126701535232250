import axios from "axios";

const projectManageAxios = axios.create({
    baseURL: "https://projectmanageprod.azurewebsites.net/",
    // baseURL: 'http://localhost:5035/',
    // baseURL: "https://localhost:7230/",
});

const humanResourcesAxios = axios.create({
    baseURL: "https://humanresourcesbackendprod.azurewebsites.net/",
    // baseURL: 'http://localhost:5035/',
    // baseURL: "https://localhost:7230/",
});

const systemsIntegrationAxios = axios.create({
    baseURL: "https://systemsintegrationbackendprod.azurewebsites.net/",
    // baseURL: 'http://localhost:5055/',
    // baseURL: "https://localhost:7230/",
});

export {
  projectManageAxios,
  humanResourcesAxios,
  systemsIntegrationAxios
};
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...{main: '#D22730'},
      ...(mode === 'dark' && {
        main: '#D22730',
      }),
    },
    secondary: {
      ...{main: '#D22730'},
      ...(mode === 'dark' && {
        main: '#2d2926',
      }),
    },
    tabBG: {
      main: mode === 'dark' ? '#8E8E8E' : '#D0D0D0',
    },
    null: {
      main: 'rgba(0, 0, 0, 0)',
    },
    ...(mode === 'dark' && {
      background: {
        default: '#121212',
        paper: '#262626',
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
});

export const ContentBoxWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 'calc(95% - 16px)',
  height: 'calc(95% - 16px)',
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#4C4A45' : '#f2f2f2',
  },
}));

export const DocumentBoxWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 'calc(95% - 16px)',
  height: 'calc(95% - 16px)',
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: 'background-color 0.3s ease',
}));

export const TableWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 'calc(95% - 16px)',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  transition: 'background-color 0.3s ease',
}));

const getSavedMode = () => {
  const savedMode = localStorage.getItem('colorMode');
  return savedMode || 'light';
};

export const theme = () => createMuiTheme(getDesignTokens(getSavedMode()));

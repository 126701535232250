import React, { useState, useEffect } from "react";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MuiAlert from '@mui/material/Alert';

export default function AddDocument({ onDocumentAdded }) {
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState("Preliminary Design");
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const activeAccount = instance.getActiveAccount();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const fileOptions = [
    {
      value: "Preliminary Design",
      label: "Preliminary Design",
    },
    {
      value: "Minor Change",
      label: "Minor Change",
    },
    {
      value: "Major Change",
      label: "Major Change",
    },
    {
      value: "Contract",
      label: "Contract",
    },
    {
      value: "Power Validation",
      label: "Power Validation",
    },
    {
      value: "Pad Validation",
      label: "Pad Validation",
    },
    {
      value: "Internet Validation",
      label: "Internet Validation",
    },
    {
      value: "Customer Quote",
      label: "Customer Quote",
    },
    {
      value: "Vendor Bill",
      label: "Vendor Bill",
    },
  ];

  const handleSave = () => {
    const userName = name;
    const isoFormattedDate = new Date().toISOString();

    const formData = new FormData();
    formData.append("Dscrption", description);
    formData.append("CsId", "");
    formData.append("ProjectId", searchParams.get("id"));
    formData.append("DocumentLink", "");
    formData.append("UserName", userName);
    formData.append("FileType", fileType);
    formData.append("VersionNo", "");
    formData.append("DateTime", isoFormattedDate);
    formData.append("DocumentFile", selectedFile);

    setLoading(true);

    axios.post("/api/Onboarding/uploadDocument", formData, {
    })
      .then(() => {
        setSnackbarOpen(true);
        onDocumentAdded();
      })
      .catch((error) => {
        console.error(error);
        alert(error.response?.data || "Error uploading document. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Button onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add Document
          </Typography>

          <TextField
            select
            label="File Type"
            value={fileType}
            onChange={handleFileTypeChange}
            style={{ width: "100%", paddingBottom: "20px" }}
          >
            {fileOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <input type="file" onChange={handleFileChange} style={{ width: "100%", paddingBottom: "20px" }} />

          <TextField
            multiline
            label="Enter Description"
            value={description}
            onChange={handleDescriptionChange}
            style={{ width: "100%", paddingBottom: "20px" }}
          />

          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}

            <Button style={{ color: "green" }} onClick={handleSave} disabled={loading}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Document saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

export function Authenticated(roles) {
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getAllAccounts()[0];
  const props = account ? account.idTokenClaims : null;

  if (props) {
    if ("roles" in props) {
      return props.roles.some(item => roles.includes(item));
    }
  }
  return false;
}

export function GetAuthUserName() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const account = msalInstance.getAllAccounts()[0];
  return (account.username);
}
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useThemeContext } from "../../styles/ThemeContextProvider";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const DarkModeToggle = () => {
  const { toggleColorMode } = useThemeContext();
  const [icon, setIcon] = useState(null);
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("colorMode");
    const defaultMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    return savedMode || defaultMode;
  });

  const handleToggle = () => {
    const updatedMode = mode === "dark" ? "light" : "dark";
    toggleColorMode();
    localStorage.setItem("colorMode", updatedMode);
    setMode(updatedMode);
  };

  useEffect(() => {
    setIcon(mode === "dark" ? <Brightness4Icon /> : <Brightness7Icon />);
  }, [mode]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <IconButton sx={{ ml: 1 }} onClick={handleToggle} color="inherit">
        {icon}
      </IconButton>
    </Box>
  );
};

export default DarkModeToggle;

import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { useThemeContext } from "../../styles/ThemeContextProvider";

export default function BayAssignment() {
    const [bayAssignment, setBayAssignment] = useState([]);
    const { toggleColorMode } = useThemeContext();
    //eslint-disable-next-line
    const [mode, setMode] = useState(() => {
        const savedMode = localStorage.getItem("colorMode");
        const defaultMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        return savedMode || defaultMode;
      });

    useEffect(() => {
        toggleColorMode();
        localStorage.setItem("colorMode", "light");
        setMode("light");
        fetchData();
        //eslint-disable-next-line
    }, []);

    const fetchData = () => {
        const endpoint = 'api/Manufacturing/bayAssignment';
    
        axios.get(endpoint)
            .then((response) => {
                setBayAssignment(response.data);
            })
            .catch((error) => {
                console.error(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching bay assignment feed.");
                }
            });
    }

    const itemMappings = {
        "tankPaint": "Tank Paint",
        "tankPlumbing": "Tank Plumbing",
        "breakerPanel": "Breaker Panel",
        "_120Volt": "120 Volt",
        "hvac": "Hvac",
        "heater": "Heater",
        "enclosureInstall": "Enclosure Install",
        "cameras": "Cameras",
        "trackInstall": "Track Install",
        "tankInstall": "Tank Install",
        "lvWiring": "LV Wiring",
        "vent": "Vent",
        "batteryBankInstalled": "Battery Bank",
        "controlPanelBuilt": "CP Built",
        "controlPanelInstall3Option": "CP Install",
        "readyForProgramming": "Prog Ready",
        "programmingComplete": "Prog Complete",
        "testingComplete": "Testing Complete",
        "finalValidation": "Final Validation",
        "boxPack": "Box Pack",
        "readyToShip": "Ready to Ship",
    };
    
    return (
        <>
            <AuthenticatedTemplate>
                <Grid container style={{ paddingRight: "1%", paddingLeft: "1%", height: "100%" }}>
                    {[...Array(13).keys()].reverse().map((index) => {
                        const bayData = bayAssignment.find(item => item.bayNo === (index + 1).toString());
                        const isOdd = (index + 1) % 2 !== 0;
                        return (
                            <Grid item xs={0.92} key={index} style={{ height: "100%" }}>
                                <Box border={1} borderColor="divider">
                                    <Stack direction="column" alignItems="center" justifyContent="center" sx={{paddingTop: "10px"}}>
                                        <div style={{backgroundColor: isOdd ? '#D3D3D3' : 'transparent', width: '100%', textAlign: 'center'}}>
                                            <Typography>
                                                <Typography component="span" sx={{ fontSize: "20px", fontWeight: 'bold' }}>Bay:</Typography>{" "}
                                                <Typography component="span" sx={{fontSize: "20px", color: 'red' }}>{index + 1}</Typography>
                                            </Typography>
                                            {bayData ? (
                                                <>
                                                <Typography sx={{paddingBottom: "10px"}}>
                                                    <Typography component="span" sx={{fontSize: "16px", fontWeight: 'bold' }}>BSU:</Typography>{" "}
                                                    <Typography component="span" sx={{fontSize: "16px", color: 'red' }}>{bayData.productName ? bayData.productName : ''}</Typography>
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "12px", paddingBottom: "3px", minHeight: "40px" }}
                                                    title={bayData.projectName.length > 39 ? bayData.projectName : undefined}
                                                    >
                                                    <b>
                                                        {bayData.projectName.length > 39
                                                        ? bayData.projectName.slice(0, 39) + "..."
                                                        : bayData.projectName}
                                                    </b>
                                                </Typography>
                                                <Typography sx={{fontSize: "12px", paddingBottom: "3px" }}><b>Ship Date:</b> {bayData.shippedDate ? new Date(bayData.shippedDate).toLocaleDateString() : ''}</Typography>
                                                <Typography sx={{fontSize: "12px", paddingBottom: "10px" }}><b>Install:</b> {bayData.installDate ? new Date(bayData.installDate).toLocaleDateString() : ''}</Typography>
                                                <Typography sx={{fontSize: "12px", paddingBottom: "3px" }}><b>Riser Count:</b> {bayData.riserCount}</Typography>
                                                <div style={{ height: '16px' }}>
                                                    <Typography sx={{fontSize: "12px", paddingBottom: "3px" }}><b>{bayData.coldWeatherPackage ? "Cold Weather" : ""}</b></Typography>
                                                </div>
                                                <div style={{ height: '16px' }}>
                                                    <Typography sx={{fontSize: "12px", paddingBottom: "3px" }}><b>{bayData.mastOption ? "Mast" : ""}</b></Typography>
                                                </div>
                                                <div style={{ height: '16px' }}>
                                                    <Typography sx={{fontSize: "12px", paddingBottom: "3px" }}><b>{bayData.fdConnects ? "FD" : ""}</b></Typography>
                                                </div>
                                                <div style={{ height: '25px' }}>
                                                    <Typography sx={{fontSize: "12px", paddingBottom: "12px" }}><b>{bayData.international ? "International" : ""}</b></Typography>
                                                </div>
                                            </>
                                            
                                            ) : (
                                                <>
                                                    <Typography sx={{paddingBottom: "10px"}}>
                                                        <Typography component="span" sx={{fontSize: "16px", fontWeight: 'bold' }}>BSU:</Typography>{" "}
                                                        <Typography component="span" sx={{fontSize: "16px", color: 'red' }}></Typography>
                                                    </Typography>
                                                    <Typography sx={{fontSize: "20px", paddingBottom: "3px", color: "#35CB82", minHeight: "34px", textShadow: "1px 1px 1px black" }}><b>Open</b></Typography>
                                                    <Typography sx={{fontSize: "14px", paddingBottom: "3px" }}><b></b></Typography>
                                                    <Typography sx={{fontSize: "14px", paddingBottom: "146px" }}><b></b></Typography>
                                                </>
                                            )}
                                        </div>
                                        {Object.keys(itemMappings).map((key, rowIndex) => (
                                            <Typography key={rowIndex} 
                                                sx={{
                                                    fontSize: "14px",
                                                    paddingTop: "5.4px",
                                                    paddingBottom: "5.4px",
                                                    borderTop: "1px solid black",
                                                    display: 'block',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    color: bayData && bayData[key] ? "black" : "#d9d9d9",
                                                    backgroundColor: bayData && bayData[key] ? '#35CB82' : 'transparent'
                                                }}
                                            >
                                                {itemMappings[key]}
                                            </Typography>
                                        ))}
                                    </Stack>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </AuthenticatedTemplate>
    
            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, ToggleButton, Modal, ToggleButtonGroup, Stack, TextField, MenuItem, FormControl, InputLabel, Select, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { projectManageAxios as axios } from '../utils/AxiosUtility';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '90%',
    minWidth: '40%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonsStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
};

export function UnitConfiguration({ units, projectId, unitChange }) {
    const [open, setOpen] = React.useState(false);
    const [currentUnit, setCurrentUnit] = React.useState(0);
    const [unitState, setUnitState] = React.useState(units[currentUnit]);
    const [unitType, setUnitType] = React.useState("-");
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setUnitType("-");
        if (units[currentUnit]) {
            setUnitState(units[currentUnit]);
            const endpoint = 'api/Projects/zones/' + units[currentUnit].id;
            let zones = [];
            let onlyZones = [];

            switch (units[currentUnit].type) {
                case "BSU":
                    setUnitType("Base Suppression Unit");
                    break;
                case "OW":
                    setUnitType("OnWatch Unit");
                    handle4G(true, true);
                    break;
                case "ED":
                    setUnitType("Early Detection Unit");
                    break;
                case "CF":
                    setUnitType("Continuous Flow Unit");
                    break;
                default:
                    break;
            }

            axios.get(endpoint)
                .then((response) => {
                    if (response.data) {
                        zones = response.data.filter(i => i.detectionOnlyZone === false);
                        onlyZones = response.data.filter(i => i.detectionOnlyZone === true);
                        if (zones.length > 0) {
                            units[currentUnit].detectZone = zones.length;
                            for (let i = 0; i < zones.length; i++) {
                                zones[i].id = i + 1
                            }
                            units[currentUnit].currentType = zones[0].id;
                            units[currentUnit].detectType = zones;
                        }
                        if (onlyZones.length > 0) {
                            units[currentUnit].detectOZone = onlyZones.length;
                            for (let i = 0; i < onlyZones.length; i++) {
                                onlyZones[i].id = i + 1
                            }
                            units[currentUnit].currentOType = onlyZones[0].id;
                            units[currentUnit].detectOType = onlyZones;
                        }
                        setUnitState({ ...unitState, ...units[currentUnit] });
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Error retreiving zones.");
                });
        }// eslint-disable-next-line
    }, [units, currentUnit]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setDialogOpen(false);
        setOpen(false);
    };

    const handleUnitSelect = (event) => {
        setCurrentUnit(event.target.value);
        setUnitState({ ...units[event.target.value] })
    };

    const handleNextUnit = () => {
        if (currentUnit + 1 < units.length) {
            setCurrentUnit(currentUnit + 1)
            setUnitState({ ...units[currentUnit + 1] })
        }
        else {
            setCurrentUnit(0)
            setUnitState({ ...units[0] })
        }
    }

    const handlePrevUnit = () => {
        if (currentUnit - 1 >= 0) {
            setCurrentUnit(currentUnit - 1)
            setUnitState({ ...units[currentUnit - 1] })
        }
        else {
            setCurrentUnit(units.length - 1)
            setUnitState({ ...units[units.length - 1] })
        }
    }

    const handleRemoveUnit = () => {
        units.splice(currentUnit, 1);
        setUnitState(units[0]);
        setCurrentUnit(0);
        unitChange(units);
        setDialogOpen(false);
    };

    const handleTypeSelect = (event) => {
        units[currentUnit].currentType = event.target.value;
        setUnitState({ ...unitState, currentType: event.target.value });
    };

    const handleOTypeSelect = (event) => {
        units[currentUnit].currentOType = event.target.value;
        setUnitState({ ...unitState, currentOType: event.target.value });
    };

    const handleColdPack = (event, coldp) => {
        if (coldp !== null) {
            units[currentUnit].coldPack = coldp;
            setUnitState({ ...unitState, coldPack: coldp });
        }
    };

    const handleMast = (event, mast) => {
        if (mast !== null) {
            units[currentUnit].mast = mast;
            setUnitState({ ...unitState, mast: mast });
        }
    };

    const handleConveyor = (event, con) => {
        if (con !== null) {
            units[currentUnit].conveyor = con;
            setUnitState({ ...unitState, conveyor: con });
        }
    };

    const handlePTP = (event, ptp) => {
        if (ptp !== null) {
            units[currentUnit].ptp = ptp;
            setUnitState({ ...unitState, ptp: ptp });
        }
    };

    const handleFD = (event, fd) => {
        if (fd !== null) {
            units[currentUnit].fd = fd;
            setUnitState({ ...unitState, fd: fd });
        }
    };

    const handleInterconnect = (event, inter) => {
        if (inter !== null) {
            units[currentUnit].interconnect = inter;
            setUnitState({ ...unitState, interconnect: inter });
        }
    };

    const handleInternational = (event, int) => {
        if (int !== null) {
            units[currentUnit].international = int;
            setUnitState({ ...unitState, international: int });
        }
    };

    const handle4G = (event, prim) => {
        if (prim !== null) {
            units[currentUnit].primary4G = prim;
            setUnitState({ ...unitState, primary4G: prim });
        }
    };

    const handleRiser = (event) => {
        units[currentUnit].riser = event.target.value;
        setUnitState({ ...unitState, riser: event.target.value });
    };

    const handlePanel = (event) => {
        units[currentUnit].panel = event.target.value;
        setUnitState({ ...unitState, panel: event.target.value });
    };

    const handlePower = (event) => {
        units[currentUnit].power = event.target.value;
        setUnitState({ ...unitState, power: event.target.value });
    };

    const handleRiserIL = (event, riserIL) => {
        if (riserIL !== null) {
            units[currentUnit].riserIL = riserIL;
            setUnitState({ ...unitState, riserIL: riserIL });
        }
    };

    const handleViewing = (event) => {
        units[currentUnit].viewStation = event.target.value;
        setUnitState({ ...unitState, viewStation: event.target.value });
    };

    const handleThermal = (event) => {
        units[currentUnit].thermal = event.target.value;
        setUnitState({ ...unitState, thermal: event.target.value });
    };

    const handleFlame = (event) => {
        units[currentUnit].flame = event.target.value;
        setUnitState({ ...unitState, flame: event.target.value });
    };

    const handleSmoke = (event) => {
        units[currentUnit].smoke = event.target.value;
        setUnitState({ ...unitState, smoke: event.target.value });
    };

    const handleEButton = (event) => {
        units[currentUnit].eButton = event.target.value;
        setUnitState({ ...unitState, eButton: event.target.value });
    };

    const handleF500 = (event) => {
        units[currentUnit].f500 = event.target.value;
        setUnitState({ ...unitState, f500: event.target.value });
    };

    const handleZoneChange = (event) => {
        let zones = [];
        for (let i = 0; i < event.target.value; i++) {
            zones.push({
                zoneId: i + 1,
                id: i + 1,
                detectionZoneType: "Inside",
                detectionOnlyZone: false,
                productId: units[currentUnit].id
            });
        }
        units[currentUnit].detectZone = event.target.value;
        units[currentUnit].detectType = zones;
        units[currentUnit].currentType = zones.length > 0 ? zones[0].id : 0;
        setUnitState({ ...unitState, ...units[currentUnit] });
    }

    const handleOnlyZoneChange = (event) => {
        let zones = [];
        for (let i = 0; i < event.target.value; i++) {
            zones.push({
                zoneId: i + 1,
                id: i + 1,
                detectionZoneType: "Inside",
                detectionOnlyZone: true,
                productId: units[currentUnit].id
            });
        }
        units[currentUnit].detectOZone = event.target.value;
        units[currentUnit].detectOType = zones;
        units[currentUnit].currentOType = zones.length > 0 ? zones[0].id : 0;
        setUnitState({ ...unitState, ...units[currentUnit] });
    }

    const handleTypeChange = (event) => {
        if (unitState.currentType > 0) {
            units[currentUnit].detectType[unitState.currentType - 1].detectionZoneType = event.target.value;
            let zones = units[currentUnit].detectType;
            setUnitState({ ...unitState, detectType: zones });
        }
    }

    const handleOTypeChange = (event) => {
        if (unitState.currentOType > 0) {
            units[currentUnit].detectOType[unitState.currentOType - 1].detectionZoneType = event.target.value;
            let zones = units[currentUnit].detectOType;
            setUnitState({ ...unitState, detectOType: zones });
        }
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleSubmit = () => {
        let unitsToSave = [];

        units.forEach(unit => {
            let zones = []
            unit.detectType.forEach(zone => {
                zones.push(zone)
            });
            unit.detectOType.forEach(zone => {
                zones.push(zone)
            });
            unitsToSave.push({
                productType: unit.type,
                productId: unit.id,
                projectId: unit.projectId,
                productConfigurations: [{
                    productConfigurationId: 0,
                    productId: unit.id,
                    riserCount: unit.riser ?? 0,
                    panelCount: unit.panel ?? 0,
                    coldWeatherPackage: unit.coldPack ? unit.coldPack : false,
                    mastOption: unit.mast ? unit.mast : false,
                    conveyorStop: unit.conveyor ? unit.conveyor : false,
                    viewingStation: unit.viewStation ?? 0,
                    powerType: unit.power ?? null,
                    riserIndicatorLight: unit.riserIL ?? false,
                    pointToPoint: unit.ptp ? unit.ptp : false,
                    multiPoint: null,
                    thermalCameraCount: unit.thermal ?? 0,
                    flameDetectorCount: unit.flame ?? 0,
                    smokeDetectorCount: unit.smoke ?? 0,
                    ebuttonCount: unit.eButton ?? 0,
                    fdConnects: unit.fd ? unit.fd : false,
                    interconnect: unit.interconnect ? unit.interconnect : false,
                    international: unit.international ? unit.international : false,
                    subPanel: null,
                    _4gPrimary: unit.primary4G ? unit.primary4G : false,
                    f500Count: unit.f500 ?? 0
                }],
                zones: zones
            })
        });

        console.log(unitsToSave);
        axios.post("/api/Projects/updateProducts", unitsToSave)
            .then((response) => {
                alert("Saved!");
            })
            .catch((error) => {
                console.log(error);
                alert("Error updating task.");
            });
    }

    const handleEmptySubmit = () => {
        const endpoint = `api/Projects/deleteProducts/${projectId}`;
        axios.delete(endpoint)
            .then((response) => {
                alert("Saved!");
            })
            .catch((error) => {
                console.log(error);
                alert("Error updating task.");
            });
    }

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Units Details</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {units.length > 0 && unitState
                        ?
                        <div>
                            <Stack direction="row" alignItems="center">
                                <FormControl sx={{ width: "32%" }}>
                                    <InputLabel id="unit-details-label">Unit</InputLabel>
                                    <Select
                                        labelId="unit-details-label"
                                        id="unit-details-select"
                                        value={currentUnit}
                                        label="Unit"
                                        onChange={handleUnitSelect}
                                    >
                                        {units.map((unit, index) => (
                                            <MenuItem key={unit.name} value={index}>
                                                {unit.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <IconButton onClick={handlePrevUnit} aria-label="Previous Unit">
                                    <ArrowLeftIcon />
                                </IconButton>

                                <IconButton onClick={handleNextUnit} aria-label="Next Unit">
                                    <ArrowRightIcon />
                                </IconButton>

                                <Paper sx={{ marginLeft: "auto", width: "30%", padding: "10px" }}>
                                    <Typography sx={{ width: "100%" }}>
                                        {unitType}
                                    </Typography>
                                </Paper>

                                <Button sx={{ marginLeft: "auto" }} variant="contained" onClick={handleDialogOpen} aria-label="Remove Unit">
                                    Remove Unit
                                </Button>

                                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                                    <DialogTitle>Delete Unit</DialogTitle>
                                    <DialogContent>
                                        Are you sure you want to delete this unit?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleDialogClose} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleRemoveUnit} color="error">
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Stack>

                            {unitState.type !== "OW" ?
                                <>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                        {unitState.type === "BSU" | unitState.type === "CF" ?
                                            <TextField label="Riser Count" value={unitState.riser} onChange={handleRiser} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />
                                            :
                                            <TextField label="Panel Count" value={unitState.panel} onChange={handlePanel} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />
                                        }

                                        <TextField label="Viewing Station" value={unitState.viewStation} onChange={handleViewing} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />

                                    </Stack>

                                    {unitState.type !== "ED" ?
                                        <>
                                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                                <TextField
                                                    type="number"
                                                    label="Detect Zones"
                                                    name="zoneNum"
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    value={unitState.detectZone}
                                                    onChange={handleZoneChange}
                                                    sx={{ mt: 2, paddingRight: "1%", width: "33%" }}
                                                />

                                                {unitState.detectZone > 0
                                                    ?
                                                    <FormControl sx={{ mt: 2, minWidth: "33.5%", paddingRight: "1%" }}>
                                                        <InputLabel id="zones-label">Zone</InputLabel>
                                                        <Select
                                                            labelId="zones-label"
                                                            id="zones-select"
                                                            value={unitState.currentType}
                                                            label="zone"
                                                            onChange={handleTypeSelect}
                                                            sx={{ paddingRight: "1%", minWidth: "33%" }}
                                                        >
                                                            {unitState.detectType.map((zone) => (
                                                                <MenuItem key={zone.zoneId} value={zone.id}>
                                                                    {zone.id}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    : <></>}

                                                {unitState.currentType > 0
                                                    ?
                                                    <FormControl sx={{ mt: 2, minWidth: "33.5%", paddingRight: "1%" }}>
                                                        <InputLabel id="zone-type-label">Type</InputLabel>
                                                        <Select
                                                            labelId="zone-type-label"
                                                            id="zone-type-select"
                                                            value={unitState.detectType.filter(item => item.id === unitState.currentType)[0].detectionZoneType}
                                                            label="type"
                                                            onChange={handleTypeChange}
                                                            sx={{ paddingRight: "1%", minWidth: "33%" }}
                                                        >
                                                            <MenuItem key={1} value="Inside">
                                                                Inside
                                                            </MenuItem>
                                                            <MenuItem key={2} value="Outside">
                                                                Outside
                                                            </MenuItem>
                                                            <MenuItem key={3} value="Conveyor">
                                                                Conveyor
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    : <></>}
                                            </Stack>

                                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                                <TextField
                                                    type="number"
                                                    label="Detect Only Zones"
                                                    name="oZoneNum"
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    value={unitState.detectOZone}
                                                    onChange={handleOnlyZoneChange}
                                                    sx={{ mt: 2, paddingRight: "1%", width: "33%" }}
                                                />

                                                {unitState.detectOZone > 0
                                                    ?
                                                    <FormControl sx={{ mt: 2, minWidth: "33.5%", paddingRight: "1%" }}>
                                                        <InputLabel id="ozones-label">Zone</InputLabel>
                                                        <Select
                                                            labelId="ozones-label"
                                                            id="ozones-select"
                                                            value={unitState.currentOType}
                                                            label="zone"
                                                            onChange={handleOTypeSelect}
                                                            sx={{ paddingRight: "1%", minWidth: "33%" }}
                                                        >
                                                            {unitState.detectOType.map((zone) => (
                                                                <MenuItem key={zone.zoneId} value={zone.id}>
                                                                    {zone.id}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    : <></>}

                                                {unitState.currentOType > 0
                                                    ?
                                                    <FormControl sx={{ mt: 2, minWidth: "33.5%", paddingRight: "1%" }}>
                                                        <InputLabel id="ozone-type-label">Type</InputLabel>
                                                        <Select
                                                            labelId="ozone-type-label"
                                                            id="ozone-type-select"
                                                            value={unitState.detectOType.filter(item => item.id === unitState.currentOType)[0].detectionZoneType}
                                                            label="type"
                                                            onChange={handleOTypeChange}
                                                            sx={{ paddingRight: "1%", minWidth: "33%" }}
                                                        >
                                                            <MenuItem key={1} value="Inside">
                                                                Inside
                                                            </MenuItem>
                                                            <MenuItem key={2} value="Outside">
                                                                Outside
                                                            </MenuItem>
                                                            <MenuItem key={3} value="Conveyor">
                                                                Conveyor
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    : <></>}
                                            </Stack>

                                            {unitState.type !== "CF" ?
                                                <>
                                                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{ minWidth: '50%', paddingRight: "10%" }}
                                                            >
                                                                Cold Package
                                                            </Typography>
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={unitState.coldPack}
                                                                exclusive
                                                                onChange={handleColdPack}
                                                                aria-label="ColdPack"
                                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                                            >
                                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                                <ToggleButton value={false}>No</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Stack>

                                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{ minWidth: '50%', paddingRight: "10%" }}
                                                            >
                                                                Mast Option
                                                            </Typography>
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={unitState.mast}
                                                                exclusive
                                                                onChange={handleMast}
                                                                aria-label="MastOption"
                                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                                            >
                                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                                <ToggleButton value={false}>No</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Stack>
                                                    </Stack>
                                                </>
                                                : null
                                            }
                                        </>
                                        : null
                                    }

                                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                            <Typography
                                                variant="body1"
                                                style={{ minWidth: '50%', paddingRight: "10%" }}
                                            >
                                                Conveyor Stop
                                            </Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={unitState.conveyor}
                                                exclusive
                                                onChange={handleConveyor}
                                                aria-label="Conveyor"
                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                            >
                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                <ToggleButton value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                            <Typography
                                                variant="body1"
                                                style={{ minWidth: '50%', paddingRight: "10%" }}
                                            >
                                                Point to Point
                                            </Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={unitState.ptp}
                                                exclusive
                                                onChange={handlePTP}
                                                aria-label="PointToPoint"
                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                            >
                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                <ToggleButton value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>
                                    </Stack>
                                </>
                                : null
                            }
                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                <TextField label="Thermal Camera" value={unitState.thermal} onChange={handleThermal} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />

                                <TextField label="Flame Detectors" value={unitState.flame} onChange={handleFlame} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />

                                <TextField label="Smoke Analytics" value={unitState.smoke} onChange={handleSmoke} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />
                            </Stack>

                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                <TextField label="E-Buttons" value={unitState.eButton} onChange={handleEButton} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />

                                {unitState.type === "BSU" ?
                                    <TextField label="F500" value={unitState.f500} onChange={handleF500} sx={{ mt: 2, paddingRight: "1%", width: "33%" }} />
                                    : null
                                }
                            </Stack>

                            {unitState.type !== "OW" ?
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                    {unitState.type === "BSU" ?
                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                            <Typography
                                                variant="body1"
                                                style={{ minWidth: '50%', paddingRight: "5%" }}
                                            >
                                                FD Connects
                                            </Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={unitState.fd}
                                                exclusive
                                                onChange={handleFD}
                                                aria-label="FD Connect"
                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                            >
                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                <ToggleButton value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>
                                        : null
                                    }

                                    <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                        <Typography
                                            variant="body1"
                                            style={{ minWidth: '50%', paddingRight: "5%" }}
                                        >
                                            FACP Interconnect
                                        </Typography>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={unitState.interconnect}
                                            exclusive
                                            onChange={handleInterconnect}
                                            aria-label="Interconnect"
                                            style={{ flexGrow: '1', minWidth: '60%' }}
                                        >
                                            <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                            <ToggleButton value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>

                                    {unitState.type === "ED" ?
                                        <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                            <Typography
                                                variant="body1"
                                                style={{ minWidth: '50%', paddingRight: "5%" }}
                                            >
                                                Riser Indicator Light
                                            </Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={unitState.riserIL}
                                                exclusive
                                                onChange={handleRiserIL}
                                                aria-label="Riser Indicator"
                                                style={{ flexGrow: '1', minWidth: '60%' }}
                                            >
                                                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                                <ToggleButton value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>
                                        : null
                                    }
                                </Stack>
                                :
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                        <Typography
                                            variant="body1"
                                            style={{ minWidth: '50%', paddingRight: "5%" }}
                                        >
                                            Power Type
                                        </Typography>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={unitState.power}
                                            exclusive
                                            onChange={handlePower}
                                            aria-label="Power Type"
                                            style={{ flexGrow: '1', minWidth: '60%' }}
                                        >
                                            <ToggleButton color="success" value={"Solar"}>Solar</ToggleButton>
                                            <ToggleButton color="success" value={"AC"}>AC</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>

                                    <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                        <Typography
                                            variant="body1"
                                            style={{ minWidth: '50%', paddingRight: "5%" }}
                                        >
                                            Riser Indicator Light
                                        </Typography>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={unitState.riserIL}
                                            exclusive
                                            onChange={handleRiserIL}
                                            aria-label="Riser Indicator"
                                            style={{ flexGrow: '1', minWidth: '60%' }}
                                        >
                                            <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                            <ToggleButton value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>
                                </Stack>
                            }

                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                    <Typography
                                        variant="body1"
                                        style={{ minWidth: '50%', paddingRight: "5%" }}
                                    >
                                        International
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={unitState.international}
                                        exclusive
                                        onChange={handleInternational}
                                        aria-label="International"
                                        style={{ flexGrow: '1', minWidth: '60%' }}
                                    >
                                        <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                        <ToggleButton value={false}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>

                                <Stack direction="row" alignItems="center" sx={{ mt: 2, minWidth: "50%" }}>
                                    <Typography
                                        variant="body1"
                                        style={{ minWidth: '50%', paddingRight: "5%" }}
                                    >
                                        4G Primary
                                    </Typography>
                                    {unitState.type === "OW" ?
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={unitState.primary4G}
                                            exclusive
                                            disabled
                                            onChange={handle4G}
                                            aria-label="4G Primary"
                                            style={{ flexGrow: '1', minWidth: '60%' }}
                                        >
                                            <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                            <ToggleButton value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                        :
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={unitState.primary4G}
                                            exclusive
                                            onChange={handle4G}
                                            aria-label="4G Primary"
                                            style={{ flexGrow: '1', minWidth: '60%' }}
                                        >
                                            <ToggleButton color="success" value={true}>Yes</ToggleButton>
                                            <ToggleButton value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>}

                                </Stack>
                            </Stack>

                            <div style={buttonsStyle}>
                                <Button style={{ color: "green" }} onClick={handleSubmit}>Save</Button>
                                <Button variant="contained" onClick={handleClose}>Close</Button>
                            </div>
                        </div>
                        :
                        <div>
                            <Typography>Add a unit to customize configurations.</Typography>
                            <div style={buttonsStyle}>
                                <Button onClick={handleEmptySubmit}>Save empty config</Button>
                                <Button variant="contained" onClick={handleClose}>Close</Button>
                            </div>
                        </div>
                    }

                </Box>
            </Modal>
        </>
    );
}
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Stack } from "@mui/material";
import InvMainTable from "../../components/Inventory/invMainTable";
import { ContentBoxWrapper } from "../../styles/theme";
import WorkBundle from "../../components/Inventory/WorkBundle";
  
  export function InvMainPage() {
    
    const gridItemProps = {
      xs: 4,
      container: true,
      justifyContent: "center",
    };
  
    return (
      <>
        <AuthenticatedTemplate>
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ padding: "30px" }}
          >
            <Grid item {...gridItemProps}>
            <ContentBoxWrapper elevation={2}>
                  <WorkBundle />
              </ContentBoxWrapper>
            </Grid>
  
            <Grid item {...gridItemProps}>
            <ContentBoxWrapper elevation={2}>
            <Typography variant="h5" align="center" gutterBottom>
                Status
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Stack>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "40px" }}
                    >
                      PO's
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "10px" }}
                    >
                      120
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "40px" }}
                    >
                      Open
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "10px" }}
                    >
                      5
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "40px" }}
                    >
                      PA
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ padding: "10px" }}
                    >
                      15
                    </Typography>
                  </Stack>
                  </Grid>
                  </Grid>
                  </ContentBoxWrapper>
            </Grid>

            <Grid item {...gridItemProps}>
              <ContentBoxWrapper elevation={2}>
                <div>
                  <Typography variant="h5" align="center" gutterBottom>
                    Inventory Operations
                  </Typography>
                  <Typography variant="h6" align="center" style={{ padding: '40px' }}>
                    <Button>Purchase Order</Button>
                    <Button>Receive Parts</Button>
                    <Button>Create Order</Button>
                  </Typography>
                </div>
              </ContentBoxWrapper>
            </Grid>

            <Grid item xs={12}>
                <Stack spacing={4}>
                    <InvMainTable />
                </Stack>
            </Grid>
          </Grid>
        </AuthenticatedTemplate>
  
        <UnauthenticatedTemplate>
          <Typography variant="h6">
            <center>Please sign-in to see your profile information.</center>
          </Typography>
        </UnauthenticatedTemplate>
      </>
    );
  }
  
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import SiteInfoMainTable from "./SiteInfoMainTable";


export function SiteMainPage() { 
    // placeholder state variables

    // placeholder functionality

    return (
        <>
            <AuthenticatedTemplate>
                <Grid container style={{padding: "0.5%"}}>
                    <Box sx={{width: "100%"}}>
                        <SiteInfoMainTable />
                    </Box>
                </Grid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}